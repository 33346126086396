import { Space } from 'antd'
import React from 'react'
import moment from 'moment'
import classnames from 'classnames'
import { ReactComponent as UserIcon } from 'assets/icons/user.svg'
import { CardDefault } from 'components/cards/CardDefault'
import { useRouter } from 'hooks/useRouter'
import { RoutePaths } from 'hooks/useNavigation'

// enum ALLOW_SHOW_ENTRY_ID {
//   ServerRoom = 'Server Room',
// }

export const EventCompletePage = (props: any) => {
  const router = useRouter()

  if (!router.state) {
    router.push(RoutePaths.INDEX)
  }

  return (
    <div className="complete-page container d-flex align-items-center flex-column">
      <div
        className={classnames(
          'user-check d-flex align-items-center justify-content-center',
          'check-in'
        )}
        style={
          router.state?.isDangerous
            ? {
                backgroundColor: '#ff4d4f',
              }
            : {}
        }
      >
        <span>1</span>
        <UserIcon className="user-icon" />
      </div>
      <CardDefault classNames={classnames('result-card')} contentCentered>
        <div
          className={classnames(
            'result-card__top d-flex justify-content-center align-items-end',
            'check-in'
          )}
          style={
            router.state?.isDangerous
              ? {
                  backgroundColor: '#ff4d4f',
                }
              : {}
          }
        >
          <span>Check-in</span>
        </div>
        <Space
          className="check-time"
          direction="vertical"
          align="center"
          size={10}
        >
          <b> {moment().format('ll')} </b>
          <b> {moment().format('LT')} </b>
        </Space>
        <Space
          className="building-info"
          direction="vertical"
          align="center"
          size={1}
        >
          <p className="text-center">{router.state?.eventName}</p>
          <span className="d-inline-block text-center">
            {/* {event?.entranceName} */}
          </span>
        </Space>
      </CardDefault>
      <div className="complete-page__bottom">
        {router.state?.isDangerous ? (
          <p style={{ fontSize: '26px', color: '#ff4d4f', fontWeight: 'bold' }}>
            Please seek assistance from our medical staff
          </p>
        ) : (
          <p>Thank you for keeping Singapore safe!</p>
        )}
        <p>
          Screenshot this pass for record and don’t forget to check out when you
          leave.
        </p>
        {/* {Object.values(ALLOW_SHOW_ENTRY_ID).find(
          (e) =>
            e.toLowerCase().trim() ===
            router.state?.template?.toLowerCase().trim()
        ) && (
          <p style={{ fontSize: '18px' }}>
            Visitor Reference No.: {router.state.entryId}
          </p>
        )} */}
      </div>
    </div>
  )
}
