import React from 'react'
import { Routes } from './routes'
import { ThemeProvider, CssBaseline } from '@mui/material';
import { lightTheme } from 'hummingbird-ui';


function App() {
  return (
    <div className="App">
      <ThemeProvider theme={ lightTheme }>
        <CssBaseline/>
        <Routes />
        </ThemeProvider>
    </div>
  )
}

export default App
