export enum DEVICES {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  UNKNOWN = 'UNKNOWN',
}

/**
 * Determine the device operating system.
 * This function returns one of DEVICES.
 */
export const getDeviceType = (): DEVICES => {
  var userAgent = navigator.userAgent || navigator.vendor

  if (/android/i.test(userAgent)) {
    return DEVICES.ANDROID
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (
    /iPad|iPhone|iPod/.test(userAgent)
    // && !window.MSStream
  ) {
    return DEVICES.IOS
  }

  return DEVICES.UNKNOWN
}
