import React, { useState, useEffect } from 'react'
import { Modal, Form, Input, message, Button } from 'antd'

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

export type EditAnswerModalProps = {
  visible: boolean
  onOk: (id: number, payload: any) => void
  onCancel: () => void
  answer: any
}

export const EditAnswerModal = ({
  visible,
  onOk: updateAnswer,
  onCancel,
  answer,
}: EditAnswerModalProps) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (answer !== null) {
      form.setFieldsValue({...answer})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer])

  const handleSubmit = async () => {
    try {
      setLoading(true)
      await form.validateFields()
      const payload = form.getFieldsValue()
      updateAnswer(answer.id, {
        ...payload,
        answer: payload.answer.trim(),
      })
      onCancel()
    } catch (error) {
      if (error.message) {
        message.error(error.message)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      title="Edit Answer"
      centered
      visible={visible}
      onOk={handleSubmit}
      onCancel={onCancel}
      confirmLoading={loading}
      maskClosable={false}
      cancelText={'Close'}
      destroyOnClose
      footer={[
        <Button key="back" className="btn-default" onClick={onCancel}>
          Close
        </Button>,
        <Button
          loading={loading}
          key="submit"
          className="btn-edit"
          onClick={handleSubmit}
        >
          Ok
        </Button>,
      ]}
    >
      <Form
        {...layout}
        form={form}
        onFinish={handleSubmit}
        initialValues={{
          ...answer
        }}
      >
        <Form.Item
          name="answer"
          label="Answer"
          rules={[{ required: true, message: 'Answer is required' }]}
        >
          <Input className="border-round" />
        </Form.Item>
      </Form>
    </Modal>
  )
}
