const apiURL = process.env.REACT_APP_API_URL
const url = `${apiURL}/api/v1/assignments`

export type TablePaginationType = {
  current: number
  pageSize: number
  total: number
}

export type Assignment = {
  identity: string
  assigned?: boolean
}

export const AssignmentAPI = {
  async listGeUsersAssigned(
    token: string,
    area_type: string,
    area_id: number
  ): Promise<{ data: string[]; total: number }> {
    const res = await fetch(
      `${url}?area_type=${area_type}&area_id=${area_id}`,
      {
        credentials: 'include',
        headers: {
          Authorization: token,
        },
      }
    )
    const { status, data, total = 0, message } = await res.json()
    if (status === false || !data?.length)
      throw Error(message || 'Data not found')
    return { data, total }
  },

  async postGeUserAssign(
    token: string,
    payload: any
  ): Promise<{ status: boolean; message: string }> {
    const res = await fetch(`${url}`, {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
      method: 'POST',
      body: JSON.stringify(payload),
    })
    const { status, message } = await res.json()
    return { status, message }
  },
}
