import React, { useEffect, useState } from 'react'

import {
  Table,
  Row,
  Card,
  Col,
  Typography,
  Button,
  Modal,
  message,
  Tooltip,
  Spin,
  Dropdown,
  Menu,
  Input,
} from 'antd'
import { ColumnType } from 'antd/es/table'
import { LoadingOutlined, DownOutlined } from '@ant-design/icons'

import { Entrance, EntranceAPI } from '../../../api/Entrance'
import { Location, LocationAPI } from 'api/Location'
import { PendingApprovalAPI } from 'api/PendingApproval'
import { Template, TemplateAPI } from 'api/Template'

import { getRoomsByEntranceAPI } from '../../../api/RoomsByEntrance'
import { UserModel, getUsersAPI } from '../../../api/GetUsers'

import useAuthUser from 'hooks/useAuthUser'
import useAuthRole from 'hooks/useAuthRole'
import { CreateLocationModal } from './LocationModal/CreateLocationModal'
import { EditLocationModal } from './LocationModal/EditLocationModal'
import { CreateEntranceModal } from './EntranceModal/CreateEntranceModal'
import { EditEntranceModal } from './EntranceModal/EditEntranceModal'
import { PendingModal } from './PendingModal/PendingModal'
import { AssignModal } from './AssignModal/AssignModal'
import { ManageRoomsModal } from './ManageRooms/ManageRoomsModal'
import './style.less'

const { Title } = Typography
const { Search } = Input

enum MODAL_TAG {
  CREATE_LOCATION = 'CREATE_LOCATION',
  EDIT_LOCATION = 'EDIT_LOCATION',
  DELETE_LOCATION = 'DELETE_LOCATION',
  CREATE_ENTRANCE = 'CREATE_ENTRANCE',
  EDIT_ENTRANCE = 'EDIT_ENTRANCE',
  DELETE_ENTRANCE = 'DELETE_ENTRANCE',
  PENDING_MODAL = 'PENDING_MODAL',
  ASSIGN_MODAL = 'ASSIGN_MODAL',
  DOWNLOAD_POSTER = 'DOWNLOAD_POSTER',
  MANAGE_ROOMS = 'MANAGE_ROOMS',
}

type TablePaginationType = {
  current: number
  pageSize: number
  total: number
}

const initPagination: TablePaginationType = {
  pageSize: 10,
  current: 1,
  total: 0,
}

const DownloadEntranceURL = ({
  entrance,
  token,
}: {
  entrance: Entrance
  token: string
}) => {
  const entranceName = entrance.name.toUpperCase().split(' ').join('_')
  const locationName = entrance.location?.name
    .toUpperCase()
    .split(' ')
    .join('_')
  const antIconLoading = <LoadingOutlined style={{ fontSize: 12 }} spin />
  const [onLoading, setOnLoading] = useState(false)
  return (
    <Row
      align="middle"
      style={{
        pointerEvents: onLoading ? 'none' : 'auto',
      }}
      onClick={() => {
        if (!onLoading) {
          setOnLoading(true)
          EntranceAPI.getPosterQRCode(
            token,
            entrance.id,
            `${locationName}-${entranceName}.pdf`
          ).then(() => {
            setOnLoading(false)
          })
        }
      }}
    >
      {onLoading ? (
        <Spin
          indicator={antIconLoading}
          style={{
            marginRight: '5px',
            marginBottom: '2px',
            display: 'inline-block',
          }}
        />
      ) : null}
      Download Poster
    </Row>
  )
}

export const ManageLocation = () => {
  const [locations, setLocations] = useState<Location[]>(null!)
  const [templates, setTemplates] = useState<Template[]>(null!)
  const [totalApproval, setTotalApproval] = useState<number>(0)
  const [loading, setLoading] = useState(false)
  const [user] = useAuthUser()
  const [role] = useAuthRole(user?.accessToken)
  const [entranceLoading, setEntranceLoading] = useState<{
    loading: boolean
    index: number
  }>({
    loading: false,
    index: -1,
  })

  // handle for pagination
  const [pagination, setPagination] =
    useState<TablePaginationType>(initPagination)

  const [userList, setUserList] = useState(Array<UserModel>())
  const [searchTerm, setSearchTerm] = useState("")

  const fetchLocations = (token: string, pagination: TablePaginationType, searchTerm: string = "") => {
    setLoading(true)
    LocationAPI.list(token, {
      items_per_page: pagination.pageSize,
      page: pagination.current,
      search: searchTerm
    })
      .then((resp) => {
        if (resp && resp.status) {
          const { _, data: items, total = 0 } = resp
          setLoading(false)
          setLocations(items)
          setPagination({ ...pagination, total })
        } else {
          message.error("There seems to be an issue at this moment. Try again.")
        }
      })
      .catch((error: any) => {
        setLoading(false)
        message.error(error.message)
      })
  }

  const fetchTemplates = (token: string, pagination?: TablePaginationType) => {
    TemplateAPI.list(token)
      .then(({ items, total }) => {
        setTemplates(items)
      })
      .catch((err: Error) => {
        message.error(err.message)
      })
  }

  const fetchPendingApprovalTotalOnly = (token: string) => {
    setLoading(true)
    PendingApprovalAPI.list(token, {
      items_per_page: 1,
      page: 1,
    })
      .then((resp) => {
        if (resp && resp.status) {
          setLoading(false)
          setTotalApproval(resp.total)
        }
      })
      .catch((error) => {
        setLoading(false)
        message.error(error.message)
      })
  }

  const fetchUsers = (token: string) => {
    getUsersAPI.list(token)
    .then((resp) => {
      if (resp && resp.status) {
        setUserList(resp.data)
      } else {
        setUserList([])
      }
    })
    .catch((err) => {
      console.log(`getUsers response failed ${err.message}`)
    })
  }

  const getUserNameDisplay = (value: string) => {
    if (userList !== ([] || null || undefined)) {
      const user = userList.filter((u) => value.includes(u.phone))[0]
      if (user !== (null || undefined)) {
        return user.name + ` (${value.slice(0, 1) + value.slice(1).replace(/.(?=....)/g, '*')})`
      } else {
        if (value[0] === '+') {
          return value.slice(0, 4) + value.slice(3).replace(/.(?=.....)/g, '*')
        } else if (value[0] >= '0' && value[0] <= '9') {
          return value.slice(0, 1) + value.slice(1).replace(/.(?=.....)/g, '*')
        } else {
          return value
        }
      }
    } else {
      return value
    }
  }

  useEffect(() => {
    if (user) {
      fetchUsers(user?.accessToken)
      fetchTemplates(user?.accessToken)
      fetchLocations(user?.accessToken, initPagination)
      if (role?.name === 'ge-super-admin') {
        fetchPendingApprovalTotalOnly(user?.accessToken)
      }
    }
  }, [user, role])

  // handle edit location
  const [selectedLocation, setSelectedLocation] = useState<Location>(null!)

  // handle modal
  const [visibleModal, setVisibleModal] = useState<MODAL_TAG | null>(null)

  const openModal = (type: MODAL_TAG | null) => {
    setVisibleModal(type)
  }

  const closeModal = () => {
    setVisibleModal(null)
  }

  const handleOK = () => {
    closeModal()
    // refresh
    fetchLocations(user?.accessToken, pagination)
  }

  const handleSearch = (keyword) => {
    fetchLocations(user?.accessToken, pagination, keyword)
    setSearchTerm(keyword)
  }

  // handle action
  const handleDeleteLocation = (location: Location) => {
    const locationName = location ? location.name : 'this location'
    Modal.confirm({
      title: `Confirm Delete of ${locationName}?`,
      content: `Are you sure you want to delete ${locationName}? You cannot undo this.`,
      icon: null,
      width: 500,
      okText: 'Confirm',
      okType: 'default',
      okButtonProps: { className: 'btn-create' },
      cancelText: 'Cancel',
      cancelButtonProps: { className: 'btn-default' },
      centered: true,
      maskClosable: false,
      async onOk() {
        // handle change confirm status
        try {
          const messageRes = await LocationAPI.delete(
            user?.accessToken,
            location.id
          )
          message.success(messageRes)
          fetchLocations(user?.accessToken, pagination)
        } catch (error) {
          message.error(error.message)
        }
      },
      onCancel() {},
    })
  }

  const columns: ColumnType<Location>[] = [
    {
      width: '12%',
      title: 'ID',
      key: 'id',
      align: 'left',
      dataIndex: 'id',
      ellipsis: { showTitle: true },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      width: '25%',
      title: 'Location',
      align: 'left',
      key: 'name',
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      width: '30%',
      title: 'Admin Email',
      align: 'left',
      key: 'dr_email',
      dataIndex: 'dr_email',
      ellipsis: { showTitle: true },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value.join(',\n')}
        </Tooltip>
      ),
    },
    {
      width: '25%',
      align: 'left',
      title: 'Managed By',
      key: 'managedBy',
      dataIndex: 'owner',
      render: (value = 'N/A') => (
        <Tooltip placement="topLeft" title={getUserNameDisplay(value)}>
          {getUserNameDisplay(value)}
        </Tooltip>
      ),
    },
    {
      width: '15%',
      title: 'Action',
      align: 'center',
      key: 'action',
      render: (_, record) => {
        const handleAction = (type: MODAL_TAG) => {
          setSelectedLocation(record)
          openModal(type)
        }
        return (
          <Dropdown placement="bottomLeft"
            overlay={
            <Menu>
              {locationTableMoreActions.map(item => (
                  <Menu.Item key={item.key}>
                      {(item.actionTag !== MODAL_TAG.DELETE_LOCATION ) && <a href="#" onClick={() => handleAction(item.actionTag)}>{item.text}</a>}
                      {(item.actionTag === MODAL_TAG.DELETE_LOCATION ) && <a href="#" onClick={() => handleDeleteLocation(record)}><span className='danger-color'>{item.text}</span></a>}
                  </Menu.Item>
              ))}
            </Menu>}>
            <Button type="link" className='link-button-plain'>More<DownOutlined /></Button>
          </Dropdown>
        )
      },
    },
  ]

  const locationTableMoreActions = [
    {
      key: "createEntrance",
      text: "Create Entrance",
      actionTag: MODAL_TAG.CREATE_ENTRANCE,
    },
    {
      key: "editLocation",
      text: "Edit",
      actionTag: MODAL_TAG.EDIT_LOCATION,
    },
    {
      key: "deleteLocation",
      text: "Delete",
      actionTag: MODAL_TAG.DELETE_LOCATION,
    },
  ]

  const handleChangePage = (pagination: any) => {
    fetchLocations(user?.accessToken, pagination)
  }
  // handle edit entrance
  const [entranceSelected, setEntranceSelected] = useState<Entrance>(null!)

  // handle action
  const handleDeleteEntrance = (entrance: Entrance) => {
    const entranceName = entrance ? entrance.name : "this entrance"
    Modal.confirm({
      title: `Confirm Delete of ${entranceName}?`,
      content: `Are you sure you want to delete ${entranceName}? You cannot undo this.`,
      icon: null,
      width: 500,
      okText: 'Confirm',
      okType: 'default',
      okButtonProps: { className: 'btn-create' },
      cancelText: 'Cancel',
      cancelButtonProps: { className: 'btn-default' },
      centered: true,
      maskClosable: false,
      async onOk() {
        // handle change confirm status
        try {
          const messageRes = await EntranceAPI.delete(
            user?.accessToken,
            entrance.id
          )
          message.success(messageRes)
          fetchLocations(user?.accessToken, pagination)
        } catch (error) {
          message.error(error.message)
        }
      },
      onCancel() {},
    })
  }

  // handle for entrances table
  const entranceTableColumn: ColumnType<Entrance> & { roles?: string[] }[] = [
    {
      width: '12%',
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      render: (value) => (
        <Tooltip placement='topLeft' title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      width: '20%',
      title: 'Entrance',
      key: 'name',
      dataIndex: 'name',
      render: (value) => (
        <Tooltip placement='topLeft' title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      width: '20%',
      title: 'Room(s)',
      key: 'rooms',
      dataIndex: 'rooms',
      render: (value = ["-"]) => (
        <Tooltip placement="topLeft" title={value.join(', ')}>
          {value.join(', ')}
        </Tooltip>
      ),
    },
    {
      width: '20%',
      title: 'Template',
      key: 'template_name',
      dataIndex: 'template_name',
      render: (value) => (
        <Tooltip placement='topLeft' title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      width: '30%',
      title: 'Action',
      key: 'action',
      align: 'center',
      render: (_, record) => {
        const handleClickModal = (tag: MODAL_TAG) => {
          openModal(tag)
          setEntranceSelected(record)
        }
        return (
          <Dropdown placement="bottomLeft"
            overlay={
            <Menu>
              {entranceTableMoreActions.map(item => (
                  <Menu.Item key={item.key}>
                      {(item.actionTag === MODAL_TAG.MANAGE_ROOMS ) && <a href="#" onClick={() => handleClickModal(item.actionTag)}>{item.text}</a>}
                      {(item.actionTag === MODAL_TAG.DOWNLOAD_POSTER ) && <DownloadEntranceURL entrance={record} token={user?.accessToken}/>}
                      {role?.name === 'ge-super-admin' && ((item.actionTag === MODAL_TAG.ASSIGN_MODAL ) && <a href="#" onClick={() => handleClickModal(item.actionTag)}>{item.text}</a>)}
                      {(item.actionTag === MODAL_TAG.EDIT_ENTRANCE ) && <a href="#" onClick={() => handleClickModal(item.actionTag)}>{item.text}</a>}
                      {(item.actionTag === MODAL_TAG.DELETE_ENTRANCE ) && <a href="#" onClick={() => handleDeleteEntrance(record)}><span className='danger-color'>{item.text}</span></a>}
                  </Menu.Item>
              ))}
            </Menu>}>
            <Button type="link" className='link-button-plain'>More<DownOutlined /></Button>
          </Dropdown>
        )
      },
    },
  ]

  const entranceTableMoreActions = [
    {
      key: "editEntrance",
      text: "Edit Entrance",
      actionTag: MODAL_TAG.EDIT_ENTRANCE,
    },
    {
      key: "assignEntrance",
      text: "Assign Admin",
      actionTag: MODAL_TAG.ASSIGN_MODAL,
    },
    {
      key: "manageRooms",
      text: "Manage Rooms",
      actionTag: MODAL_TAG.MANAGE_ROOMS
    },
    {
      key: "downloadPoster",
      text: "Download Poster",
      actionTag: MODAL_TAG.DOWNLOAD_POSTER
    },
    {
      key: "deleteEntrance",
      text: "Delete",
      actionTag: MODAL_TAG.DELETE_ENTRANCE,
    },
  ]

  const expandedRowRender = (location: Location | undefined) => {

    const entrances = location?.entrances
      ? location?.entrances.map((item) => ({ ...item, location }))
      : undefined

    if (
      location &&
      entranceLoading.loading &&
      entranceLoading.index === parseInt(location.id)
    ) {
      return <Spin spinning={true} />
    } else {
      return location && !location.entrances?.length ? (
        <span>This location has no entrance/room</span>
      ) : (
        <Table
          className="entrance-table"
          // size="small"
          columns={
            entranceTableColumn.filter(
              (e) => !e.roles || e.roles.includes(role?.name)
            ) as ColumnType<Entrance>[]
          }
          dataSource={entrances}
          rowKey={(record) => record.id}
          pagination={false}
        />
      )
    }
  }
  
  return (
    <Row className="">
      <Col span={24}>
        <Card
          loading={loading}
          title={
            <Title level={2}>
              Location Management
            </Title>
          }
          extra={
            <Button
              className="btn-create"
              type="primary"
              onClick={() => openModal(MODAL_TAG.CREATE_LOCATION)}
            >
              Create
            </Button>
          }
        >
          <Row>{role?.name === 'ge-super-admin' && totalApproval > 0 && <>
            <div className='title-alert-box'>You have <Button type='link' onClick={() => openModal(MODAL_TAG.PENDING_MODAL)}>{totalApproval} {((totalApproval > 1) ? " requests" : " request")}</Button> that would require your approval.</div>
          </>}</Row>
          <Row>
            View and manage locations.
          </Row>
          <Row>
          <Search className='title-search-bar' placeholder="Search location..." onSearch={handleSearch} allowClear defaultValue={searchTerm}/>
          </Row>

          <Table
            className="location-table"
            pagination={pagination}
            columns={columns}
            dataSource={locations}
            rowKey={(record) => record.id}
            onChange={handleChangePage}
            expandable={{
              expandedRowRender,
              onExpand: async (expanded, record) => {
                if (expanded) {
                  setEntranceLoading({
                    loading: true,
                    index: parseInt(record.id),
                  })

                  let entrances: Entrance[] = []
                  await EntranceAPI.list(
                    user?.accessToken,
                    parseInt(record.id)
                  ).then((response) => {
                    if (response && response.status) {
                      if (response.data.length > 0) {                 
                        let counter = 0
                        response.data.forEach((e, i) => {
                          (async () => {
                            let entrance = e
                            await getRoomsByEntranceAPI.list(e.id)
                            .then((resp) => {
                              if (resp && resp.status) {
                                const { data } = resp
                                if (data.length !== 0) {
                                  const rooms = data.rooms.map(function(value) {
                                    return value.roomName
                                  })
                                  entrance.rooms = rooms
                                }
                              }
                            })
                            .then(() => {
                              entrances.push(entrance)
                              counter += 1
                              if (counter === response.data.length) {
                                setEntranceLoading({ loading: false, index: -1 })
                                let indexFound = locations.findIndex(
                                (e) => e.id === record.id
                              )
                              setLocations([
                                ...locations.slice(0, indexFound),
                                {
                                  ...record,
                                  entrances: entrances ?? [],
                                },
                                ...locations.slice(indexFound + 1),
                              ])
                              }
                            })
                          })()
                        })
                      } else {
                        setEntranceLoading({ loading: false, index: -1 })
                      }
                    } else {
                      message.error("There seems to be an issue at this moment. Try again.")
                    }
                  }).catch((err) => {
                    console.log(`request failed ${err.message}`)
                  })
                }
              },
            }}
          />
        </Card>
      </Col>
      {visibleModal === MODAL_TAG.CREATE_LOCATION && <CreateLocationModal
        roleName={role?.name}
        accessToken={user?.accessToken}
        visible={visibleModal === MODAL_TAG.CREATE_LOCATION}
        onOk={handleOK}
        onCancel={closeModal}
      />}
      {visibleModal === MODAL_TAG.EDIT_LOCATION && <EditLocationModal
        roleName={role?.name}
        accessToken={user?.accessToken}
        visible={visibleModal === MODAL_TAG.EDIT_LOCATION}
        onOk={handleOK}
        onCancel={closeModal}
        location={selectedLocation}
      />}
      {visibleModal === MODAL_TAG.CREATE_ENTRANCE && <CreateEntranceModal
        roleName={role?.name}
        accessToken={user?.accessToken}
        visible={visibleModal === MODAL_TAG.CREATE_ENTRANCE}
        onOk={handleOK}
        onCancel={closeModal}
        location={selectedLocation}
        templates={templates}
      />}
      {(visibleModal === MODAL_TAG.EDIT_ENTRANCE) && <EditEntranceModal
        roleName={role?.name}
        accessToken={user?.accessToken}
        visible={visibleModal === MODAL_TAG.EDIT_ENTRANCE}
        onOk={handleOK}
        onCancel={closeModal}
        entrance={entranceSelected}
        templates={templates}
      />}
      {role?.name === 'ge-super-admin' && visibleModal === MODAL_TAG.PENDING_MODAL && (
        <PendingModal
          accessToken={user?.accessToken}
          visible={visibleModal === MODAL_TAG.PENDING_MODAL}
          onCancel={closeModal}
          totalApproval={totalApproval}
          setTotalApproval={setTotalApproval}
        />
      )}
      {(visibleModal === MODAL_TAG.ASSIGN_MODAL) && <AssignModal
        accessToken={user?.accessToken ?? ""}
        userList={userList}
        visible={visibleModal === MODAL_TAG.ASSIGN_MODAL}
        onCancel={closeModal}
        entrance={entranceSelected}
      />}
      {(visibleModal === MODAL_TAG.MANAGE_ROOMS) && <ManageRoomsModal
        accessToken={user?.accessToken}
        visible={visibleModal === MODAL_TAG.MANAGE_ROOMS}
        onOk={handleOK}
        onCancel={closeModal}
        entrance={entranceSelected}
      />}
    </Row>
  )
}
