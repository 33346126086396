import React, { useMemo, useState } from 'react'
import { Redirect } from 'react-router-dom'
import queryString from 'query-string'

import { RoutePaths } from 'hooks/useNavigation'
import { useRouter } from 'hooks/useRouter'
import { CheckingAPI, CheckListQuestion } from 'api/Main'

import { message } from 'antd'

export const withEvent = (Component: (props: any) => JSX.Element) => {
  return ({
    location: { search },
    match: {
      params: { code, key },
    },
  }: any) => {
    const [
      env,
      swiftId,
      eventId,
      locationId,
      entranceId,
      ...splitName
    ] = code.split('-')

    const { REACT_APP_SWIFT_ID } = process.env

    if (
      !env ||
      !eventId ||
      isNaN(eventId) ||
      !locationId ||
      isNaN(locationId) ||
      !entranceId ||
      isNaN(entranceId) ||
      !splitName.length ||
      swiftId !== REACT_APP_SWIFT_ID
    ) {
      return <Redirect to={RoutePaths.INDEX} />
    }
    const router = useRouter()

    const [questions, setQuestions] = useState<CheckListQuestion[]>([])

    useMemo(async () => {
      if (eventId && locationId && entranceId && key) {
        await CheckingAPI.checkEvent({
          event_id: eventId,
          location_id: locationId,
          entrance_id: entranceId,
          key: key,
        }).catch((error: any) => {
          message.error(error.message)
          router.push(RoutePaths.INDEX)
        })

        await CheckingAPI.getQuestion(entranceId).then((data: any) => {
          setQuestions(data)
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <Component
        event={{
          eventId,
          eventName: splitName.join(' '),
          locationId,
          entranceId,
          residentInfo: { ...queryString.parse(search) },
        }}
        questions={questions}
      />
    )
  }
}
