import React from 'react'
import { Result, Button } from 'antd'
import { useHistory } from 'react-router-dom'
import { RoutePaths } from '../../../hooks/useNavigation'
// import { LeftOutlined } from "@ant-design/icons";

function SessionExpire() {
  const history = useHistory()
  return (
    <Result
      status="warning"
      title="Session Timeout"
      subTitle="Your session has expired due to inactivity"
      extra={
        <Button
          type="primary"
          key="console"
          onClick={() => {
            // history.push('/admin/login')
            window.location.href = '/admin/login'
          }}
        >
          Back to GovEntry portal
        </Button>
      }
    />
  )
}

export default SessionExpire
