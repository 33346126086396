const apiURL = process.env.REACT_APP_API_URL;
const url = `${apiURL}/api/v2/getUsers`;

export type UserModel = {
    userId: number,
    name: string,
    phone: string,
    nric: string,
}

export const getUsersAPI = {
  async list(token: string): Promise<any> {
    const resp = await fetch(`${url}`,
      {
        credentials: 'include',
        method: 'GET',
        headers: {
                  'Content-Type': 'application/json',
                  'Content-Length': '0',
                  "Authorization": token
        },
      });
    const response = await resp.json();
    return response;
  },
}; 
