import React, { useEffect } from "react";
import { Typography } from "antd";

const { Paragraph, Title } = Typography;

export const TermOfUsePage = () => {
  useEffect(() => {
    document.title = "Terms Of Use"
  }, [])
  return (
    <div className="term-of-use-page container">
      <Title level={4}>General</Title>
      <Paragraph>
        1. Welcome to the GovEntry national check-in form service (“GovEntry”)
        operated by the Swift Office. These Terms of Use (“Terms”) govern your
        use of GovEntry. Please read these Terms carefully as any non-compliance
        may result in civil or criminal liability.
      </Paragraph>
      <Paragraph>
        2. By accessing and using any part of GovEntry, you agree to be bound by
        these Terms. You may access and use GovEntry in the manner permitted
        under these Terms. If you do not agree to these Terms, please do not use
        GovEntry.
      </Paragraph>
      <Title level={4}>Use of GovEntry</Title>
      <Paragraph>
        3. Unless otherwise authorised by Swift Office, we grant you a
        non-exclusive, limited, revocable, non-sub-licensable and
        non-transferrable right to use GovEntry for your personal non-commercial
        use only.
      </Paragraph>
      <Paragraph>
        4. You agree that you will not, directly or indirectly —
      </Paragraph>
      <Paragraph className="tabs-1">
        (a) modify, reverse-engineer, decompile, adapt, publish, redistribute or
        interfere with or intercept any transaction which is part of GovEntry;
      </Paragraph>
      <Paragraph className="tabs-1">
        (b) use GovEntry for any commercial purpose or for the benefit of any
        third party (save where authorised by Swift Office), including renting,
        selling, leasing or directly or indirectly charging others for the use
        of GovEntry;
      </Paragraph>
      <Paragraph className="tabs-1">
        (c) remove, circumvent, impair, bypass, disable or otherwise interfere
        with any feature of GovEntry;
      </Paragraph>
      <Paragraph className="tabs-1">
        (d) access, submit or use any data that is not yours, or which you are
        not validly authorised to access, submit or use;
      </Paragraph>
      <Paragraph className="tabs-1">
        (e) misrepresent or make false or misleading claims regarding GovEntry;
      </Paragraph>
      <Paragraph className="tabs-1">
        (f) use GovEntry for any illegal activity, unlawful purpose, or purposes
        prohibited by these Terms or in breach of these Terms;
      </Paragraph>
      <Paragraph className="tabs-1">
        (g) use any device, software, exploits, or routine, including any virus,
        Trojan horse, worm, time bomb, robot, spider, data-mining or data
        scraping tool or cancel bot intended to damage or interfere with the
        proper operation of GovEntry or to intercept or expropriate any data
        from GovEntry;
      </Paragraph>
      <Paragraph className="tabs-1">
        (h) use GovEntry in any manner that could damage, disrupt, disable,
        overburden, or impair the operation of GovEntry or interfere with any
        person’s use of GovEntry;
      </Paragraph>
      <Paragraph className="tabs-1">
        (i) while using GovEntry, upload, post or transmit any material of any
        type that —
      </Paragraph>
      <Paragraph className="tabs-2">
        (a) is contrary to any law, statute or subsidiary legislation;
      </Paragraph>
      <Paragraph className="tabs-2">
        (b) is false, offensive, defamatory, inaccurate, misleading or
        fraudulent; or
      </Paragraph>
      <Paragraph className="tabs-2">
        (c) infringes or violates the rights of any person;
      </Paragraph>
      <Paragraph className="tabs-2">
        (j) use GovEntry to access data not intended for you.
      </Paragraph>
      <Paragraph>
        5. You agree to comply with any and all guidelines, notices, operating
        rules and policies and instructions pertaining to the use of GovEntry,
        including any amendments to the foregoing, issued by Swift Office from
        time to time, as well as any applicable laws and regulations.
      </Paragraph>
      <Paragraph>
        6. You represent and warrant that your name, NRIC number and mobile
        number (“Contact Data”) submitted by you on GovEntry are true, current
        and complete. Through your use of GovEntry, you agree that such Contact
        Data may be shared, transferred and disclosed to the relevant public
        sector agencies and third parties for the purpose of preventing or
        controlling the incidence or transmission of COVID-19. Without
        limitation to the foregoing, you agree –
      </Paragraph>
      <Paragraph className="tabs-1">
        (a) to accurately provide your Contact Data when using GovEntry to
        record your entry into or exit from any relevant premises; and
      </Paragraph>
      <Paragraph className="tabs-1">
        (b) all or part of such Contact Data may be shared, transferred and
        disclosed to the owners of the relevant premises maintaining records of
        entry into and exit from their premises for the purpose of preventing or
        controlling the incidence or transmission of COVID-19.
      </Paragraph>
      <Paragraph>
        7. GovEntry is provided on an "as is" and "as available" basis without
        warranty of any kind. The accessibility and operation of GovEntry may
        rely on technologies outside our control. To the extent permitted by
        law, Swift Office does not make any representation or warranty of any
        kind whatsoever in relation to GovEntry and disclaim all express,
        implied and statutory warranties of any kind to you or any third party,
        whether arising from usage or custom or trade or by operation of law or
        otherwise, including any representation or warranty –
      </Paragraph>
      <Paragraph className="tabs-1">
        (a) as to the accuracy, completeness, correctness, currency, timeliness,
        reliability, availability, interoperability, security, non-infringement,
        title, merchantability, quality or fitness for any particular purpose of
        GovEntry; or
      </Paragraph>
      <Paragraph className="tabs-1">
        (b) that GovEntry or any associated function or feature will be
        continuously accessible, uninterrupted or error-free, or that defects
        will be corrected or that GovEntry and their servers are and will be
        free of virus or other malicious, destructive or corrupting code,
        programme or macro.
      </Paragraph>
      <Paragraph>
        8. Swift Office will not be liable to you or any third party for any
        damage or loss of any kind whatsoever and howsoever caused, including
        any direct or indirect, special or consequential damage, loss of income,
        revenue or profit, lost or damaged data, or damage to your device,
        software or any other property, whether arising directly or indirectly
        in connection with any of the following –
      </Paragraph>
      <Paragraph className="tabs-1">(a) your use of GovEntry;</Paragraph>
      <Paragraph className="tabs-1">
        (b) any loss or unavailability of use of GovEntry, howsoever caused;
      </Paragraph>
      <Paragraph className="tabs-1">
        (c) any breakdown or malfunction of any equipment system or software
        used in connection with GovEntry, whether belonging to us or not,
        including any electronic terminal, server or system, or
        telecommunication or other communications network or system;
      </Paragraph>
      <Paragraph className="tabs-1">
        (d) your device, computer or other electronic hardware not working or
        functioning properly;
      </Paragraph>
      <Paragraph className="tabs-1">
        (e) any inaccuracy or incompleteness in, or error or omission in the
        transmission of personal data or any other content made available or
        accessible via GovEntry;
      </Paragraph>
      <Paragraph className="tabs-1">
        (f) any delay, failure, or interruption in the transmission of personal
        data or any other content made available or accessible via GovEntry,
        whether caused by delay, failure, or interruption in transmission over
        the internet or otherwise;
      </Paragraph>
      <Paragraph className="tabs-1">
        (g) any decision made or action taken by you or any third party in
        reliance upon GovEntry or any personal data or any other content made
        available or accessible via GovEntry; or
      </Paragraph>
      <Paragraph className="tabs-1">
        (h) any virus or other malicious, destructive or corrupting code,
        programme or macro in GovEntry.
      </Paragraph>
      <Paragraph>
        9. You acknowledge that your use of GovEntry contains the possibility of
        human and machine errors, inaccuracies, omissions, delays,
        unavailability and losses, including the inadvertent loss of data which
        may give rise to loss or damage suffered by you, and you agree that you
        will not hold Swift Office liable in any way whatsoever for the said
        loss or damage. You agree that you Use GovEntry at your own discretion
        and risk and that you will be responsible for any loss or damage
        suffered by you and to your device, or loss, damage to or corruption of
        data that results from the use of GovEntry.
      </Paragraph>
      <Paragraph>
        10. You will not rely on GovEntry to claim or assert any form of
        legitimate expectation against Swift Office, whether or not arising out
        of or in connection with our roles and functions as a public authority.
      </Paragraph>
      <Paragraph>
        11. Without prejudice to the foregoing, no action may be brought by you
        against Swift Office, under these Terms or related to GovEntry, more
        than one (1) year after the cause of action arose.
      </Paragraph>
      <Title level={4}>Reservation of Rights</Title>
      <Paragraph>
        12. Swift Office reserves the right to change, modify or supplement
        these Terms at our discretion and at any time, by posting the changed,
        modified or supplemented Terms on or through GovEntry, or through such
        other means as Swift Office may deem appropriate. Your continued use of
        GovEntry following the posting of any change, modification or supplement
        will constitute your acceptance of such change, modification or
        supplement. If you do not agree to any change, modification or
        supplement to these Terms, please do not use GovEntry.
      </Paragraph>
      <Paragraph>13. Swift Office reserves the right to –</Paragraph>
      <Paragraph className="tabs-1">
        (a) update, enhance, upgrade, reduce, or otherwise modify or vary
        GovEntry, at any time, for any reason, with or without notice to you.
        These Terms will apply to all such updates, enhancements, upgrades,
        reductions, modifications or variations to GovEntry;
      </Paragraph>
      <Paragraph className="tabs-1">
        (b) suspend the use of GovEntry, during times of maintenance (whether
        scheduled or unscheduled), without notice or liability to you
        whatsoever;
      </Paragraph>
      <Paragraph className="tabs-1">
        (c) deny or restrict Use of GovEntry to you without ascribing any
        reasons and without liability to you whatsoever;
      </Paragraph>
      <Paragraph className="tabs-1">
        (d) discontinue or terminate GovEntry, at any time without notice or
        liability to you whatsoever, whereupon all rights granted to you under
        these Terms will also terminate immediately; and
      </Paragraph>
      <Paragraph className="tabs-1">
        (e) if your use of GovEntry is in breach of these Terms, we may
        immediately disable your use of GovEntry without notice and to take all
        such action as we consider appropriate, desirable or necessary.
      </Paragraph>
      <Paragraph>
        14. Swift Office retains the discretion in determining whether or not to
        provide maintenance and support services for GovEntry, and if so, the
        type and nature of such maintenance and support services.
      </Paragraph>
      <Title level={4}>Intellectual Property</Title>
      <Paragraph>
        15. You acknowledge that Swift Office owns all titles, rights and
        interest, including intellectual property rights, in and to GovEntry,
        including any software therein. You will not do or permit any act which
        is directly or indirectly likely to limit our titles, rights or interest
        in and to the same. Unless otherwise expressly permitted by law, you
        agree not to modify, adapt, translate, prepare derivative works from, or
        decompile, reverse engineer, disassemble or otherwise attempt to derive
        source code from, GovEntry. Without limiting the foregoing, you will not
        use in any way and will not reproduce any trademark, logo, trade name
        and similar mark that is associated with GovEntry, without Swift
        Office’s prior written consent.
      </Paragraph>
      <Paragraph>
        16. Swift Office does not represent or warrant that the Use of GovEntry
        by you will not constitute an infringement or misuse of any third party
        right, including intellectual property rights.
      </Paragraph>
      <Paragraph>
        17. No part of GovEntry may be reproduced or reused without Swift
        Office’s prior written permission.
      </Paragraph>
      <Title level={4}>Indemnity</Title>
      <Paragraph>
        18. You agree to indemnify Swift Office and hold Swift Office harmless
        from and against any and all claims, losses, liabilities, costs and
        expenses (including but not limited to legal costs and expenses on a
        full indemnity basis) made against or suffered or incurred by Swift
        Office howsoever arising directly or indirectly out of your access to or
        use of GovEntry, or your breach of any of these Terms.
      </Paragraph>
      <Title level={4}>Governing Law</Title>
      <Paragraph>
        19. These Terms shall be governed and construed in accordance with laws
        of the Republic of Singapore.
      </Paragraph>
      <br/>
    </div>
  );
};
