import React, { useState, useEffect } from 'react'
import { Table, Modal, message, Switch } from 'antd'
import { ColumnType } from 'antd/es/table'

import useAuthUser from 'hooks/useAuthUser'
import { QuestionAPI, Question } from 'api/Question'

type TablePaginationType = {
  pageSize: number
  page: number
}

const initPagination: TablePaginationType = {
  pageSize: 10,
  page: 1,
}

export type AddQuestionModalProps = {
  visible: boolean
  onOk: (id: number, payload: any) => void
  onCancel: () => void
  template: any
}

export const AddQuestionModal = ({
  visible,
  onOk: updateTemplate,
  onCancel,
  template,
}: AddQuestionModalProps) => {
  const questionColumns: ColumnType<Question>[] = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: 'Question',
      key: 'title',
      dataIndex: 'title',
      render: (value: string, record: Question) => (
        <div>
          <p>{value}</p>
          <small style={{ color: '#999' }}>{record.description}</small>
        </div>
      ),
    },
    {
      title: 'Action',
      width: 80,
      render: (record: Question) => {
        return (
          <Switch
            defaultChecked={assignedQuestions.includes(record.id)}
            onChange={(checked) => {
              if (checked) {
                setAssignedQuestions([
                  ...new Set([...assignedQuestions, record.id]),
                ])
              } else {
                setAssignedQuestions(
                  assignedQuestions.filter((e: number) => e !== record.id)
                )
              }
            }}
          />
        )
      },
    },
  ]

  const [user] = useAuthUser()
  const [loading, setLoading] = useState(false)

  const [questions, setQuestions] = useState<Question>()
  const [assignedQuestions, setAssignedQuestions] = useState<Question>()
  const [totalQuestions, setTotalQuestions] = useState<number>(0)
  const [pagination, setPagination] =
    useState<TablePaginationType>(initPagination)

  const fetchQuestions = (token: string, pagination?: TablePaginationType) => {
    QuestionAPI.list(token, {
      items_per_page: pagination.pageSize,
      page: pagination.page,
    })
      .then(({ items, total }) => {
        setQuestions(items)
        setTotalQuestions(total)
      })
      .catch((err: Error) => {
        message.error(err.message)
      })
  }

  useEffect(() => {
    if (user) {
      fetchQuestions(user.accessToken, pagination)
    }
  }, [user, pagination])

  useEffect(() => {
    setAssignedQuestions(template?.questions.map((e: any) => e.id))
    setPagination(initPagination)
  }, [template])

  const handleSubmit = async () => {
    try {
      setLoading(true)
      updateTemplate(template.id, {
        questions: assignedQuestions,
      })
      onDestroy()
    } catch (error) {
      if (error.message) {
        message.error(error.message)
      }
    } finally {
      setLoading(false)
    }
  }

  const onDestroy = () => {
    setPagination(initPagination)
    setTotalQuestions(0)
    onCancel()
  }

  return (
    <Modal
      title="Add Question sss"
      centered
      width={1000}
      visible={visible}
      onOk={handleSubmit}
      cancelButtonProps={{
        className: 'btn-default',
      }}
      onCancel={onDestroy}
      okButtonProps={{
        className: 'btn-edit',
        type: 'default',
      }}
      confirmLoading={loading}
      maskClosable={false}
      cancelText={'Close'}
      destroyOnClose
    >
      <Table
        rowKey="id"
        columns={questionColumns}
        dataSource={questions}
        pagination={{
          total: totalQuestions,
          onChange: (page, pageSize) => {
            setPagination({
              page,
              pageSize,
            })
          },
        }}
      />
    </Modal>
  )
}
