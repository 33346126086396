import { Modal, Form, Input, message, Button } from 'antd'
import React, { useState } from 'react'

export type CreateTemplateModalProps = {
  visible: boolean
  onOk: (payload: any) => void
  onCancel: () => void
}

export const CreateTemplateModal = ({
  visible,
  onOk: createTemplate,
  onCancel,
}: CreateTemplateModalProps) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    try {
      setLoading(true)
      await form.validateFields()
      const payload = form.getFieldsValue()
      createTemplate({
        ...payload,
        name: payload.name.trim(),
      })
      onCancel()
    } catch (error) {
      if (error.message) {
        message.error(error.message)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      title="Create Template"
      centered
      visible={visible}
      onOk={handleSubmit}
      onCancel={onCancel}
      confirmLoading={loading}
      maskClosable={false}
      cancelText={'Close'}
      destroyOnClose
      footer={[
        <Button key="back" className="btn-default" onClick={onCancel}>
          Close
        </Button>,
        <Button
          loading={loading}
          htmlType="submit"
          className="btn-edit"
          onClick={handleSubmit}
        >
          Ok
        </Button>,
      ]}
    >
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item
          name="name"
          label="Name"
          rules={[
            { required: true, whitespace: true, message: 'Name is required' },
          ]}
        >
          <Input className="border-round" />
        </Form.Item>
      </Form>
    </Modal>
  )
}
