import React from 'react'
import { Row, Space } from 'antd'
import appLogo from 'assets/images/logo.png'
import { ReactComponent as AppStoreLogo } from 'assets/icons/app-store.svg'
import { ReactComponent as GooglePlayLogo } from 'assets/icons/google-play.svg'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { DEVICES, getDeviceType } from 'utils/device-detect'
import { envConfig } from 'config'

type Props = {
  onCloseBanner: () => void
}

export const DownloadBanner: React.FC<Props> = ({ onCloseBanner }) => {
  const device = getDeviceType()
  const downloadURL =
    device === DEVICES.UNKNOWN
      ? ''
      : device === DEVICES.ANDROID
      ? envConfig.appLink.android
      : envConfig.appLink.ios
  return (
    <div className="download-banner d-flex flex-column">
      <Space className="download-banner__top" align="start">
        <img className="app-logo" src={appLogo} alt="app-logo" />
        <div className="download-banner__info d-flex flex-column">
          <b>Use SwiftMED App to check in</b>
          <span>SwiftMED will soon be needed for GovEntry.</span>
        </div>
      </Space>
      <Row className="download-banner__bottom" justify="center" align="middle">
        {downloadURL ? (
          <div className="download-banner__download-btn">
            <a href={downloadURL} target="_blank" rel="noopener noreferrer">
              Set up your App now
            </a>
          </div>
        ) : (
          <Space className="download-banner__download-btn-group">
            <a
              className="app-store-btn"
              href={envConfig.appLink.android}
              target="_blank"
              rel="noopener noreferrer"
            >
              <GooglePlayLogo />
            </a>
            <a
              className="google-play-btn"
              href={envConfig.appLink.ios}
              target="_blank"
              rel="noopener noreferrer"
            >
              <AppStoreLogo />
            </a>
          </Space>
        )}
      </Row>
      <CloseIcon onClick={onCloseBanner} className="close-btn" />
    </div>
  )
}
