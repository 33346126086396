import React, { useEffect, useState } from 'react'
import { Modal, Form, Input, message, Select } from 'antd'

import { AnswerType } from 'api/Main'
import { NegativeConditionsComponent } from 'components/NegativeConditionsComponent'

const { Option } = Select

enum ALLOW_OTHERS_OPTION {
  // CheckBox = 'checkbox',
  SelectBox = 'selectbox',
}

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

type ConditionType = {
  operator?: string
  value?: string
}

export type CreateQuestionModalProps = {
  visible: boolean
  onOk: (payload: any) => void
  onCancel: () => void
}

export const CreateQuestionModal = ({
  visible,
  onOk: createQuestion,
  onCancel,
}: CreateQuestionModalProps) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [answerType, setAnswerType] = useState<string>('')
  const [conditions, setConditions] = useState<ConditionType[]>([])

  const handleSubmit = async () => {
    try {
      setLoading(true)
      await form.validateFields()
      const payload = form.getFieldsValue()

      if (payload.answer_type === AnswerType.Decimal) {
        createQuestion({
          ...payload,
          title: payload.title.trim(),
          negative_conditions: payload.negative_conditions.filter(
            (e: any) => e.value && e.value !== ''
          ),
        })
      } else {
        createQuestion({
          ...payload,
          title: payload.title.trim(),
        })
      }
      onDestroy()
    } catch (error) {
      if (error.message) {
        message.error(error.message)
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const values = form.getFieldsValue()
    form.setFieldsValue({
      ...values,
      negative_conditions: [...conditions],
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditions])

  const updateStateCondition = (index: number, newValue: ConditionType) => {
    setConditions((prevState) => {
      const condition = prevState[index]
      const clone = prevState.slice()

      clone.splice(index, 1, {
        ...condition,
        ...newValue,
      })

      return clone
    })
  }

  const onDestroy = () => {
    form.resetFields()
    setAnswerType('')
    setConditions([])
    onCancel()
  }

  return (
    <Modal
      title="Create Question"
      centered
      width={650}
      visible={visible}
      onOk={handleSubmit}
      okButtonProps={{ className: 'btn-edit', type: 'default' }}
      onCancel={onDestroy}
      cancelButtonProps={{ className: 'btn-default' }}
      confirmLoading={loading}
      maskClosable={false}
      cancelText={'Close'}
      destroyOnClose
    >
      <Form {...layout} form={form} onFinish={handleSubmit} labelAlign="left">
        <Form.Item
          name="title"
          label="Title"
          rules={[
            { required: true, whitespace: true, message: 'Title is required' },
          ]}
        >
          <Input className="border-round" />
        </Form.Item>
        <Form.Item name="description" label="Description" rules={[]}>
          <Input.TextArea className="border-round" />
        </Form.Item>
        <Form.Item
          name="answer_type"
          label="Answer Type"
          extra="Answers of CheckBox and SelectBox must be added after."
          rules={[
            {
              required: true,
              message: 'Answer type is required',
            },
          ]}
        >
          <Select
            style={{ width: '100%' }}
            placeholder="Please select answer type"
            onChange={(value) => {
              form.setFieldsValue({ answer_type: value })
              setAnswerType(value as string)
            }}
          >
            {Object.values(AnswerType).map((type: AnswerType) => {
              return (
                <Option value={type.toLowerCase()}>
                  {`${type[0].toUpperCase()}${type.slice(1).toLowerCase()}`}
                </Option>
              )
            })}
          </Select>
        </Form.Item>
        {Object.values(ALLOW_OTHERS_OPTION).includes(
          answerType.toLowerCase() as ALLOW_OTHERS_OPTION
        ) && (
          <Form.Item name="others_text" label="'Others' field name" rules={[]}>
            <Input className="border-round" />
          </Form.Item>
        )}
        <NegativeConditionsComponent
          answerType={answerType}
          updateStateCondition={updateStateCondition}
          conditions={conditions}
          setConditions={setConditions}
        />
      </Form>
    </Modal>
  )
}
