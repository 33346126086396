const apiURL = process.env.REACT_APP_API_URL
const url = `${apiURL}/api/v1/entry-log`

export interface TimestampEntries {
  timestamp: string
  entrance_id: number
  entrance_name: string
}

export interface EntryLog {
  phone_number: string
  location_id: number
  location_name?: string
  entrance_id?: number
  entrance_name?: string
  checkin_at?: TimestampEntries
  checkout_at?: TimestampEntries
}

export type QueryEntry = {
  start_time?: string
  end_time?: string
  location_id?: number
  entrance_id?: number
  phone_number?: string
}

export const EntryLogAPI = {
  async list(
    token: string,
    query: QueryEntry,
    sorter?: any
  ): Promise<{ data: EntryLog[]; status: number }> {
    const sortStr = sorter ? `sort${sorter.field}=${sorter.order}` : '';
    const res = await fetch(
      `${url}?start_time=${query.start_time || ''}&end_time=${query.end_time || ''
      }&location_id=${query.location_id || ''}&entrance_id=${query.entrance_id || ''
      }&phone_number=${query.phone_number || ''}&${sortStr}`,
      {
        credentials: 'include',
        headers: {
          Authorization: token,
        },
      }
    )

    const { status, data, message } = await res.json()
    if (status === false || !data?.length)
      throw Error(message || 'Data not found')
    return { data, status }
  },
  async export(
    token: string,
    query: QueryEntry,
  ): Promise<any> {
    const res = await fetch(
      `${url}/export?start_time=${query.start_time || ''}&end_time=${query.end_time || ''
      }&location_id=${query.location_id || ''}&entrance_id=${query.entrance_id || ''
      }&phone_number=${query.phone_number || ''}`,
      {
        credentials: 'include',
        headers: {
          Authorization: token,
        },
      }
    )


    const data = await res.blob()
    const blob = new Blob([data], { type: 'application/pdf' })

    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = `Export-Contact-Tracing_${query.start_time?.split('-').join('')}-${query.end_time?.split('-').join('')}.csv`
    link.click()
    link.remove()
  },
}
