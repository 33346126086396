const baseURL = process.env.REACT_APP_API_URL;
const path = '/api/v2/ap/userinfo';

export const userInfo = {
    async post(accessToken: string): Promise<any> {
        const userInfoUrl = new URL(path, baseURL);
        const res = await fetch(userInfoUrl.href, {
            method: 'POST',
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": accessToken,
            }
        })

        const response = await res.json();
        if (response && response.status) {
            const { data } = response;
            return data;
        }
        return response;
    },
};
