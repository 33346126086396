import React, { useState, useEffect } from 'react'
import moment from 'moment'

import { Modal, Button, Table, Tag, Descriptions, message, Tooltip, Dropdown, Menu } from 'antd'

import { DownOutlined } from '@ant-design/icons'
import { ColumnsType } from 'antd/es/table'
import { Pending, PendingApprovalAPI } from 'api/PendingApproval'
import { UserModel, getUsersAPI } from '../../../../api/GetUsers'

import useAuthUser from 'hooks/useAuthUser'
import DescriptionsItem from 'antd/lib/descriptions/Item'

export enum ApprovalActions {
  approve, reject
}

export type PendingModalProps = {
  visible: boolean
  onCancel: () => void
  totalApproval: number
  setTotalApproval: any
  accessToken: string
}

type TablePaginationType = {
  current: number
  pageSize: number
  total: number
}

const initPagination: TablePaginationType = {
  pageSize: 10,
  current: 1,
  total: 0,
}

export const PendingModal = ({
  accessToken,
  visible,
  onCancel,
  totalApproval,
  setTotalApproval,
}: PendingModalProps) => {
  const [loading, setLoading] = useState(false)
  const [approvals, setApproval] = useState<Pending[]>(null!)
  const [pagination, setPagination] =
    useState<TablePaginationType>(initPagination)
  const [user] = useAuthUser()
  const [userList, setUserList] = useState(Array<UserModel>())

  const fetchUsers = () => {
    getUsersAPI.list(accessToken)
    .then((resp) => {
      setUserList(resp.data)
    })
    .catch((err) => {
      console.log(`getUsers response failed ${err.message}`)
    })
  }

  const getUserNameDisplay = (value: string) => {
    if (userList !== ([] || null || undefined)) {
      const user = userList.filter((u) => value.includes(u.phone))[0]
      if (user !== (null || undefined)) {
        return user.name
      } else {
        return "N/A"
      }
    }
    return "N/A"
  }

  const fetchPendingApproval = (
    token: string,
    pagination: TablePaginationType
  ) => {
    setLoading(true)
    PendingApprovalAPI.list(token, {
      items_per_page: pagination.pageSize,
      page: pagination.current,
    })
      .then((resp) => {
        if (resp && resp.status) {
          setLoading(false)
          setApproval(resp.data)
          setTotalApproval(resp.total)
        }
      })
      .catch((error) => {
        setLoading(false)
        message.error(error.message)
      })
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  useEffect(() => {
    if (user) {
      fetchPendingApproval(user?.accessToken, pagination)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, pagination])

  const approvalTableMoreActions = [
    {
      key: "approve",
      text: "Approve",
      actionTag: ApprovalActions.approve,
    },
    {
      key: "reject",
      text: "Reject",
      actionTag: ApprovalActions.reject,
    },
  ]

  const columns: ColumnsType<Pending> = [
    {
      width: '15%',
      title: 'Premise Type',
      key: 'area_type',
      dataIndex: 'area_type',
      render: (value) => value[0].toUpperCase() + value.slice(1).toLowerCase(),
    },
    {
      width: '25%',
      title: 'Requester',
      key: 'user_id',
      dataIndex: 'user_id',
      render: (value = 'N/A') => (
        <Tooltip placement="topLeft" title={getUserNameDisplay(value)}>
          {getUserNameDisplay(value)}
        </Tooltip>
      ),
    },
    {
      width: '20%',
      title: 'Phone No.',
      key: 'phone',
      dataIndex: 'user_id',
    },
    {
      width: '20%',
      title: 'Created at',
      key: 'created_at',
      dataIndex: 'created_at',
      render: (value) => moment(value).format('DD MMM YYYY HH:mm:ss'),
    },
    {
      width: '15%',
      title: 'Action',
      align: 'center',
      key: 'action',
      render: (_, record) => {
        const handleAction = async (type: ApprovalActions) => {
          switch (type) {
            case ApprovalActions.approve:
                await PendingApprovalAPI.approve(user?.accessToken, record.id)
                  .then((resp) => {
                    if (resp && resp.status) {
                      message.success(`You have approved ${getUserNameDisplay(record.user_id) ?? "this user"}’s request successfully.`)
                    } else {
                      message.error("There has been an issue at the moment. Try again.")
                    }
                  })
                  .catch((error) => {
                    message.error(error.message)
                  })
                fetchPendingApproval(user?.accessToken, pagination)
            break
            case ApprovalActions.reject:
                await PendingApprovalAPI.reject(user?.accessToken, record.id)
                  .then((resp) => {
                    if (resp && resp.status) {
                      message.success(`You have rejected ${user?.name ?? "this user"}’s request successfully.`)
                    } else {
                      message.error("There has been an issue at the moment. Try again.")
                    }
                  })
                  .catch((error) => {
                    message.error(error.message)
                  })
                fetchPendingApproval(user?.accessToken, pagination)
              break
            }
          }

          return (
            <Dropdown placement="bottomLeft"
              overlay={
              <Menu>
                {approvalTableMoreActions.map(item => (
                    <Menu.Item key={item.key}>
                        {(item.actionTag === ApprovalActions.approve ) && <a href="#" onClick={() => handleAction(item.actionTag)}>{item.text}</a>}
                        {(item.actionTag === ApprovalActions.reject ) && <a href="#" onClick={() => handleAction(item.actionTag)}><span className='danger-color'>{item.text}</span></a>}
                    </Menu.Item>
                ))}
              </Menu>}>
              <Button type="link" className='link-button-plain'>More<DownOutlined /></Button>
            </Dropdown>
          )
        },
      },
    ]

  return (
    <Modal
      className="location-modal"
      title="Pending Approval"
      centered
      visible={visible}
      onCancel={onCancel}
      maskClosable={false}
      width={1000}
      footer={[
        <Button key="back" className="btn-default" onClick={onCancel}>
          Cancel
        </Button>,
      ]}
    >
      <Table
        className="location-table"
        rowKey={(record) => record.id}
        columns={columns}
        loading={loading}
        dataSource={approvals}
        pagination={{
          total: totalApproval,
          onChange: (page, _) => {
            setPagination({
              ...pagination,
              current: page,
            })
          },
        }}
        expandable={{
          expandedRowRender: ({ type, area_type, area_data }) =>
            area_type === 'location' ? (
              <Descriptions 
              title={area_data.name}
              labelStyle={{ fontWeight: 700 }}
              >
                <DescriptionsItem span={3} label='Admin Email'>{area_data.dr_email}</DescriptionsItem>
                <DescriptionsItem span={3} label='Action Type'>{type}</DescriptionsItem>
              </Descriptions>
            ) : (
              <Descriptions
                title={area_data.name}
                labelStyle={{ fontWeight: 700 }}
              >
                <DescriptionsItem span={3} label='Location'>{area_data.location.name}</DescriptionsItem>
                <DescriptionsItem span={3} label='Admin Email'>{area_data.location.dr_email}</DescriptionsItem>
                <DescriptionsItem span={3} label='Action Type'>{type}</DescriptionsItem>
              </Descriptions>
            ),
        }}
      />
    </Modal>
  )
}
