const apiURL = process.env.REACT_APP_API_URL;
const url = `${apiURL}/api/v1/admins`;
const urlV2 = `${apiURL}/api/v2`;
export interface User {
  id: number,
  role_id: number,
  identity: string,
  nric: string,
  name: string,
  created_at?: string,
  updated_at?: string,
}

export interface IUserAPIV2 {
  userId: number,
  name: string,
  phone: string,
  nric?: string,
}

export type PaginationQueryType = {
  items_per_page: number;
  page: number;
  query?: any;
};

export const UserAPI = {

  async list(
    token: string,
    // pagination: PaginationQueryType
  ): Promise<{ items: User[]; total: number }> {
    const res = await fetch(`${url}`,
      {
        credentials: 'include',
        headers: {
          "Authorization": token
        }
      }
    );
    const response = await res.json();
    return response;
  },
  
  async listV2(
    token: string,
    // pagination: PaginationQueryType
  ): Promise<IUserAPIV2[]> {
    const res = await fetch(`${urlV2+'/getUsers'}`,
      {
        credentials: 'include',
        headers: {
          "Authorization": token
        },
        method: 'GET'
      }
    );
    const { status, data: items, message } = await res.json()
    if (status === false || !items?.length)
      throw Error(message || 'Data not found')
    return items 
  },

  async listV2Existing(
    token: string,
    groupId: number
    // pagination: PaginationQueryType
  ): Promise<IUserAPIV2[]> {
    const res = await fetch(`${urlV2+'/getUsers'}`,
      {
        credentials: 'include',
        headers: {
          "Authorization": token,
          "Content-Type": "application/json",
        },
        method: 'POST',
        body: JSON.stringify({groupId})
      }
    );
    const { data: items } = await res.json()
    return items 
  },

  async create(token: string, payload: Pick<User, "role_id" | "identity" | "name" | "nric">): Promise<string> {
    console.log({payload})
    const {
      identity, //phone number
      name, //name
      nric, // nric
      role_id // role
    } = payload
    const res = await fetch(`${url}`, {
      credentials: 'include',
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": token
      },
      method: "post",
      body: JSON.stringify({
        identity,
        role_id
      }),
    });
    const res2 = await fetch(`${apiURL}/api/v2/addUser`, {
      credentials: 'include',
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": token
      },
      method: "post",
      body: JSON.stringify({
        name,
        nric,
        phone: identity
      }),
    });
    const result = await res.json();
    const result2 = await res2.json();
    
    if (result.status === false || result2.status === false) throw Error(result.message || result2.message || 'Create failed');
    return result.message || result2.message;
  },

  async update(token: string, id: string, payload: User): Promise<string> {
    const res = await fetch(`${url}/${id}`, {
      credentials: 'include',
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": token
      },
      method: "put",
      body: JSON.stringify(payload),
    });
    const { status, message } = await res.json();
    if (status === false) throw Error(message || 'Update failed');
    return message || "Update success";
  },

  async delete(token: string, id: string, identity: string): Promise<string> {
    const res = await fetch(`${url}/${id}`, {
      credentials: 'include',
      headers: {
        "Authorization": token
      },
      method: 'delete'
    });
    const res2 = await fetch(`${apiURL}/api/v2/deleteUser`, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body:JSON.stringify({
        identity
      }),
      method: 'post'
    });
    const result= await res.json();
    const result2 = await res2.json();
    if (result.status === false || result2.status === false) throw Error(result.message || 'Delete failed');
    return result.message || result2.message || "Delete success";
  },

  async addUserToGroup(token:string, payload: {groupId: number, users:number[]}): Promise<string>{
    const res = await fetch(`${urlV2+'/addUserToGroup'}`,{
      credentials: 'include',
      headers: {
        "Authorization": token,
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(payload)
    })
    const { status, message } = await res.json();
    if (status === false) throw Error(`User addition to group failed`);
    return message || "User added to group";
  }
}