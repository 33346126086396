export const DATE_FORMAT = 'DD/MM/YYYY'

export const LOCAL_STORAGE_KEY =
  process.env.REACT_APP_LOCAL_STORAGE_KEY || 'local-key'

export const MESSAGES = {
  invalidQRCode: 'This QR code is invalid',
  checkError: (type: string) => `There was an error to ${type}`,
  residentInfoError: 'Resident information is invalid',
  default: 'Something went wrong',
}

export enum YesNo {
  YES = 'YES',
  NO = 'NO',
}

export const envConfig = {
  appLink: {
    ios: process.env.REACT_APP_IOS_LINK || 'https://apps.apple.com/sg/app/swiftmed/id1500101826',
    android: process.env.REACT_APP_ANDROID_LINK || 'https://play.google.com/store/apps/details?id=com.swiftoffice.swiftmed&hl=en&gl=US',
  }
}

export const ROLES = {
  GE_SUPER_ADMIN: {
    id: 5,
    label: 'Super Admin',
    name: 'ge-super-admin',
    display: true,
  },
  GE_ADMIN: {
    id: 8,
    label: 'Admin',
    name: 'ge-admin',
    display: true
  },
  GE_USER: {
    id: 9,
    label: 'User',
    name: 'ge-user',
    display: true
  }
}

export const DISPLAY_ROLES = Object.values(ROLES).filter(item => item.display).sort((a, b) => Number(a.id) - Number(b.id))
