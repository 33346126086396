/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useEffect } from 'react'
// import { cache } from "swr";
import { Layout } from 'antd'
// import { RightOutlined, LeftOutlined } from "@ant-design/icons";
// import { useHistory } from "react-router-dom";

// layout
import HeaderLayout from './Header'
import SiderLayout from './Sider'

const { Footer, Content } = Layout

function Dashboard(props: any) {
  const { user, role, navigation, authDispatch } = props;
  useEffect(() => {
    if (role && role.status === false) {
      localStorage.clear()
      authDispatch({ loggedIn: false })
    }
  }, [role])
  return (
    <Layout>
      <HeaderLayout user={user} authDispatch={props.authDispatch}/>
      <Layout
        style={{
          paddingTop: 60,
        }}
      >
        <SiderLayout user={user} role={role} navigation={navigation} />
        <Layout
          style={{
            minHeight: '95vh',
          }}
        >
          <Content style={{ margin: '0 16px' }}>
            <div style={{ marginTop: 20, minHeight: 360 }}>
              {props.children}
            </div>
          </Content>
          {/* <Footer style={{ textAlign: 'center' }}>
            Made with ❤️ by the GovEntry ©2020
          </Footer> */}
        </Layout>
      </Layout>
    </Layout>
  )
}

export default Dashboard
