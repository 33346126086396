import { Entrance } from './Entrance'

const apiURL = process.env.REACT_APP_API_URL
const url = `${apiURL}/api/v1/locations`
const urlV2 = `${apiURL}/api/v2/getLocations`

export interface Location {
  id: number
  dr_email: string
  name: string
  owner?: string
  is_medical: boolean
  entrances?: Entrance[]
  address?: string
  longitude: number
  latitude: number
  created_by?: string
  created_at?: string
  updated_at?: string
}

export interface ILocationAPIV2 {
  locationId: number,
  locationName: string
}

export type Coordinate = {
  longitude: number
  latitude: number
}

export type PaginationQueryType = {
  items_per_page: number
  page: number
  search?: string
}

export const LocationAPI = {
  async list(
    token: string,
    pagination?: PaginationQueryType
  ): Promise<{ items: Location[]; total: number }> {
    const query = new URLSearchParams(pagination as any)
    const res = await fetch(`${url}?${query}`, {
      credentials: 'include',
      headers: {
        Authorization: token,
      },
    })
    const response = await res.json()
    return response
  },

  async listV2(
    token: string,
  ): Promise<{ items: ILocationAPIV2[] }> {
    const res = await fetch(`${urlV2}`, {
      credentials: 'include',
      headers: {
        Authorization: token,
      },
    })
    const { status, data: items, message, total = 0 } = await res.json()
    if (status === false || !items?.length)
      throw Error(message || 'Data not found')
    return items 
  },

  async create(token: string, payload: Location): Promise<string> {
    const res = await fetch(`${url}`, {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
      method: 'post',
      body: JSON.stringify(payload),
    })
    const response = await res.json()
    return response
  },

  async update(token: string, id: number, payload: Location): Promise<string> {
    const res = await fetch(`${url}/${id}`, {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
      method: 'put',
      body: JSON.stringify(payload),
    })
    const response = await res.json()
    return response
  },

  async delete(token: string, id: number): Promise<string> {
    const res = await fetch(`${url}/${id}?`, {
      credentials: 'include',
      method: 'delete',
      headers: {
        Authorization: token,
      },
    })
    const response = await res.json()
    return response
  },

  async pin(token: string, lat: number, lng: number): Promise<any> {
    const res = await fetch(`${url}/pin?latitude=${lat}&longitude=${lng}`, {
      credentials: 'include',
      method: 'get',
      headers: {
        Authorization: token,
      },
    })
    const result = await res.json()
    // if (status === false) throw Error(message || 'Search failed');
    return result
  },
}
