import React, { useState, useEffect } from 'react'

import moment from 'moment'
import { Table, Button, Space, message, Modal } from 'antd'
import { CaretRightFilled } from '@ant-design/icons'
import { ColumnType } from 'antd/es/table'

import { YesNo } from 'config'
import { TemplateAPI, Template } from 'api/Template'
import { AnswerType } from 'api/Main'
import useAuthUser from 'hooks/useAuthUser'

import { AddQuestionModal } from './AddQuestionModal'
import { CreateTemplateModal } from './CreateTemplateModal'
import { EditTemplateModal } from './EditTemplateModal'

type TemplateType = {
  id: number
  name: string
  questions?: QuestionType[]
  created_by?: string
  updated_by?: string
  created_at: string
  updated_at: string
}

type QuestionType = {
  id: number
  title: string
  description?: string
  answer_type: string
  others_text?: string
  negative_conditions?: any[]
  created_by?: string
  updated_by?: string
  created_at: string
  updated_at: string
}

type TablePaginationType = {
  pageSize: number
  page: number
}

enum MODAL_TAG {
  CREATE_TEMPLATE = 'CREATE_TEMPLATE',
  EDIT_TEMPLATE = 'EDIT_TEMPLATE',
  ADD_QUESTION = 'ADD_QUESTION',
}

export function TemplatesTab() {
  const templateColumns: ColumnType<TemplateType>[] = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: 'Template Name',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Created',
      key: 'created_by',
      dataIndex: 'created_by',
      render: (value: string, record: any) => (
        <div>
          {value}
          <br />
          <small>
            <CaretRightFilled />{' '}
            {moment(record.created_at).format('DD/MM/YYYY HH:mm:ss')}
          </small>
        </div>
      ),
    },
    {
      title: 'Updated',
      key: 'updated_by',
      dataIndex: 'updated_by',
      render: (value: string, record: any) => (
        <div>
          {value}
          <br />
          <small>
            <CaretRightFilled />{' '}
            {moment(record.updated_at).format('DD/MM/YYYY HH:mm:ss')}
          </small>
        </div>
      ),
    },
    {
      title: 'No. Questions',
      key: 'questions',
      dataIndex: 'questions',
      render: (value: string) => (value && value.length) ?? 0,
    },
    {
      title: 'Action',
      align: 'center',
      width: 'fit-content',
      render: (record) => (
        <Space>
          <Button
            className="btn-create"
            onClick={() => {
              setSelectedTemplate(record)
              openModal(MODAL_TAG.ADD_QUESTION)
            }}
          >
            Add question
          </Button>
          <Button
            className="btn-edit"
            onClick={() => {
              setSelectedTemplate(record)
              openModal(MODAL_TAG.EDIT_TEMPLATE)
            }}
          >
            Edit
          </Button>
          <Button
            className="btn-delete"
            onClick={() => {
              Modal.confirm({
                title: 'Confirm',
                content: `Are you want to delete template '${record.name}'?`,
                okType: 'default',
                okButtonProps: { className: 'btn-delete' },
                cancelButtonProps: { className: 'btn-default' },
                onOk: () => {
                  deleteTemplate(record.id)
                },
              })
            }}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ]
  const questionColumns: ColumnType<QuestionType>[] = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: 'Question',
      key: 'title',
      dataIndex: 'title',
    },
    {
      title: 'Answer Type',
      key: 'answer_type',
      dataIndex: 'answer_type',
      render: (value) => value[0].toUpperCase() + value.slice(1).toLowerCase(),
    },
    {
      title: 'Description',
      key: 'description',
      dataIndex: 'description',
    },
    {
      title: 'Negative Conditions',
      key: 'negative_conditions',
      dataIndex: 'negative_conditions',
      render: (value, record) => {
        if (value && value.length) {
          switch (record.answer_type) {
            case AnswerType.Boolean: {
              return value[0].value ? YesNo.YES : YesNo.NO
            }
            default: {
              return `${value.length} conditions`
            }
          }
        }
      },
    },
    {
      title: 'Action',
      align: 'center',
      width: 'fit-content',
      render: (record) => (
        <Space>
          <Button
            onClick={() => {
              handleUnassign(record)
            }}
            className="btn-delete"
          >
            Unassign
          </Button>
        </Space>
      ),
    },
  ]

  const [user] = useAuthUser()
  const [visibleModal, setVisibleModal] = useState<MODAL_TAG | null>(null)
  const [templates, setTemplates] = useState<Template | null>(null)
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(
    null
  )

  const fetchTemplates = (token: string, pagination?: TablePaginationType) => {
    TemplateAPI.list(token)
      .then(({ items, total }) => {
        setTemplates(items)
      })
      .catch((err: Error) => {
        message.error(err.message)
      })
  }

  useEffect(() => {
    if (user) {
      fetchTemplates(user.accessToken)
    }
  }, [user])

  const openModal = (type: MODAL_TAG | null) => {
    setVisibleModal(type)
  }

  const closeModal = () => {
    setVisibleModal(null)
  }

  const createTemplate = async (payload: Pick<Template, 'name'>) => {
    await TemplateAPI.create(user?.accessToken, payload)
      .then((result: string) => {
        if (result) {
          message.success(result)
        }
        fetchTemplates(user.accessToken)
      })
      .catch((err: Error) => {
        message.error(err.message)
      })
  }

  const updateTemplate = async (
    id: number,
    payload: Pick<Template, 'name' | 'questions'>
  ) => {
    await TemplateAPI.update(user?.accessToken, id, payload)
      .then((result: string) => {
        if (result) {
          message.success(result)
        }
        fetchTemplates(user.accessToken)
      })
      .catch((err: Error) => {
        message.error(err.message)
      })
  }

  const deleteTemplate = async (id: number) => {
    await TemplateAPI.delete(user?.accessToken, id)
      .then((result: string) => {
        if (result) {
          message.success(result)
        }
        fetchTemplates(user.accessToken)
      })
      .catch((err: Error) => {
        message.error(err.message)
      })
  }
  //Unassign questionTemplate
  const handleUnassign = async (record: any) => {
    Modal.confirm({
      title: 'Do you want to unassign this question?',
      icon: null,
      okText: 'Yes',
      okType: 'default',
      okButtonProps: { className: 'btn-delete' },
      cancelText: 'No',
      cancelButtonProps: { className: 'btn-default' },
      centered: true,
      maskClosable: false,
      async onOk() {
        // handle change confirm status
        try {
          const newValue = templates
            .find((e: any) => e.id === record.template_id)
            .questions.filter((value: any) => record.id !== value.id)
            .map((e: any) => {
              return e.id
            })

          const messageRes = await TemplateAPI.update(
            user.accessToken,
            record.template_id,
            { questions: newValue }
          )

          message.success(messageRes)
          fetchTemplates(user.accessToken)
        } catch (error) {
          if (error.message) {
            message.error(error.message)
          }
        }
      },
      onCancel() {},
    })
  }

  return (
    <div>
      <Table
        title={() => (
          <Space direction="vertical" align="end" style={{ width: '100%' }}>
            <Button
              className="btn-create"
              onClick={() => {
                openModal(MODAL_TAG.CREATE_TEMPLATE)
              }}
            >
              Create Template
            </Button>
          </Space>
        )}
        rowKey="id"
        columns={templateColumns}
        dataSource={templates}
        scroll={{
          x: 500,
        }}
        pagination={{
          pageSize: 10,
        }}
        expandable={{
          expandedRowRender: (record) => (
            <Table
              columns={questionColumns}
              dataSource={record.questions}
              pagination={false}
            />
          ),
          rowExpandable: (record) =>
            !!(record.questions && record.questions.length),
        }}
      />
      <CreateTemplateModal
        visible={visibleModal === MODAL_TAG.CREATE_TEMPLATE}
        onOk={createTemplate}
        onCancel={closeModal}
      />
      <EditTemplateModal
        visible={visibleModal === MODAL_TAG.EDIT_TEMPLATE}
        onOk={updateTemplate}
        template={selectedTemplate}
        onCancel={closeModal}
      />
      <AddQuestionModal
        visible={visibleModal === MODAL_TAG.ADD_QUESTION}
        onOk={updateTemplate}
        template={selectedTemplate}
        onCancel={closeModal}
      />
    </div>
  )
}
