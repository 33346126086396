import { Modal, Form, Input, message, Button, Select, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import { Validator } from 'utils/validator'
import { Entrance } from '../../../../api/Entrance'

import { getRoomsByEntranceAPI } from '../../../../api/RoomsByEntrance'
import { addRoomsToEntranceAPI } from '../../../../api/AddRoomsToEntrance'
const { Option } = Select

export type ManageRoomsModalProps = {
  entrance: Entrance
  visible: boolean
  onOk: () => void
  onCancel: () => void
  accessToken: string
}
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

export const ManageRoomsModal = ({
  visible,
  onOk,
  onCancel,
  entrance,
  accessToken,
}: ManageRoomsModalProps) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getRoomsByEntranceAPI.list(entrance.id)
    .then((resp) => {
        if (resp && resp.status) {
            const { data } = resp
            if (data.length !== 0) {
              const rooms = data.rooms.map(function(value) {
                return value.roomName
              })
              entrance.rooms = rooms
              form.setFieldsValue({rooms: rooms})
            }}
    })
}, [])

  const handleSave = async () => {
    setLoading(true)
    try {
    const { rooms } = form.getFieldsValue()

    let newRooms: any[] = []
    rooms.forEach((r) => {
            newRooms.push({roomName: r})
    })

    const hasError = await form
        .validateFields()
        .then(() => true)
        .catch(() => false)
      if (hasError === false) throw new Error('')

      await addRoomsToEntranceAPI.list(accessToken, entrance.id, newRooms)
      .then((resp) => {
        if (resp && resp.status) {
            message.success(`Your rooms for ${entrance.name} has been updated successfully.`)
        } else {
            message.error("There seems to be an issue at this moment. Try again.")
        }
      })
      onOk()
      form.resetFields()
      setLoading(false)
    } catch (error) {
      if (error.message) {
        message.error("There seems to be an issue at this moment. Try again.")
      }
      setLoading(false)
    }
  }

  return (
    <Modal
      title="Manage Rooms"
      centered
      className='location-modal location-room-modal'
      visible={visible}
      onOk={handleSave}
      onCancel={onCancel}
      confirmLoading={loading}
      maskClosable={false}
      cancelText={'Close'}
      footer={[
        <Button key="cancel"
        onClick={onCancel}
        className='btn-default'
        >
          <span>Cancel</span>
        </Button>,
        <Button
          loading={loading}
          key="save"
          className="btn-create"
          onClick={handleSave}
        >
          Save
        </Button>,
      ]}
    >
      <Form form={form} {...layout} onFinish={handleSave}>
        <Form.Item
              name="rooms"
              style={{
                overflow: 'hidden ',
              }}
              label="Room(s)"
              labelCol={{ span: 24 }}
              rules={[
                {
                  validator: (_, value) => {
                    return !value.filter((e: string) => {
                        console.log(value, e)
                        return (
                            e &&
                            (!Validator.isAlphanumericWithHyphen(e))
                          )
                    }).length
                      ? Promise.resolve()
                      : Promise.reject('Room name is invalid')
                  },
                },
              ]}
            >
              <Select
                ref={null}
                mode="tags"
                style={{ width: '100%' }}
                placeholder="Enter room(s)"
                tokenSeparators={[',']}
                open={false}
                tagRender={({ value, closable, onClose }) => (
                  <Tag
                    closable={closable}
                    onClose={onClose}
                    style={{
                      marginRight: 3,
                      overflow: 'hidden',
                      width: '100%',
                    }}
                    className="btn-tag"
                  >
                    {value}
                  </Tag>
                )}
              ></Select>
            </Form.Item>
            <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>Only alphanumeric characters and - are valid. Use commas (,) to separate rooms.</span>
      </Form>
    </Modal>
  )
}
