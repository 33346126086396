import styled from "@emotion/styled";
import Button, { ButtonProps } from "@mui/material/Button";
import React from "react";

export default function SingpassLoginButton (props) {

    const SingpassButton = styled(Button)<ButtonProps>(() => ({
        padding: '12px',
        backgroundColor: '#F4333D',
        '&:hover': {
            backgroundColor: '#F4333D',
        },
    }));
    
    return (<SingpassButton variant="contained" {...props}>
    <svg width="152" height="15" viewBox="0 0 152 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.6771 9.29352H5.8072V11.125H0.287598V1.38477H2.6771V9.29352Z" fill="white" />
        <path d="M10.4961 11.236C9.73221 11.236 9.04284 11.0741 8.428 10.7504C7.82247 10.4266 7.34271 9.9641 6.98871 9.36285C6.64402 8.7616 6.47168 8.0586 6.47168 7.25385C6.47168 6.45835 6.64868 5.75998 7.00268 5.15873C7.35668 4.54823 7.8411 4.0811 8.45594 3.75735C9.07078 3.4336 9.76015 3.27173 10.524 3.27173C11.2879 3.27173 11.9773 3.4336 12.5922 3.75735C13.207 4.0811 13.6914 4.54823 14.0454 5.15873C14.3994 5.75998 14.5764 6.45835 14.5764 7.25385C14.5764 8.04935 14.3948 8.75235 14.0314 9.36285C13.6774 9.9641 13.1884 10.4266 12.5642 10.7504C11.9494 11.0741 11.26 11.236 10.4961 11.236ZM10.4961 9.18248C10.9526 9.18248 11.3392 9.01598 11.6559 8.68298C11.982 8.34998 12.145 7.8736 12.145 7.25385C12.145 6.6341 11.9866 6.15773 11.6699 5.82473C11.3625 5.49173 10.9805 5.32523 10.524 5.32523C10.0583 5.32523 9.67165 5.49173 9.36423 5.82473C9.05681 6.14848 8.9031 6.62485 8.9031 7.25385C8.9031 7.8736 9.05215 8.34998 9.35026 8.68298C9.65768 9.01598 10.0396 9.18248 10.4961 9.18248Z" fill="white" />
        <path d="M18.8067 3.27173C19.3563 3.27173 19.8361 3.38273 20.246 3.60473C20.6652 3.82673 20.9866 4.1181 21.2101 4.47885V3.38273H23.5996V11.1111C23.5996 11.8234 23.4552 12.4662 23.1665 13.0397C22.887 13.6225 22.4538 14.085 21.8669 14.4272C21.2893 14.7695 20.5673 14.9406 19.701 14.9406C18.5458 14.9406 17.6096 14.6677 16.8923 14.122C16.175 13.5855 15.7651 12.8547 15.6626 11.9297H18.0241C18.0987 12.2257 18.2757 12.457 18.5551 12.6235C18.8346 12.7992 19.1793 12.8871 19.5892 12.8871C20.0829 12.8871 20.4742 12.7437 20.763 12.457C21.0611 12.1795 21.2101 11.7309 21.2101 11.1111V10.015C20.9772 10.3757 20.6558 10.6717 20.246 10.903C19.8361 11.125 19.3563 11.236 18.8067 11.236C18.1639 11.236 17.5816 11.0741 17.06 10.7504C16.5383 10.4174 16.1237 9.95023 15.8163 9.34898C15.5182 8.73848 15.3691 8.03548 15.3691 7.23998C15.3691 6.44448 15.5182 5.7461 15.8163 5.14485C16.1237 4.5436 16.5383 4.0811 17.06 3.75735C17.5816 3.4336 18.1639 3.27173 18.8067 3.27173ZM21.2101 7.25385C21.2101 6.66185 21.0425 6.19473 20.7071 5.85248C20.381 5.51023 19.9805 5.3391 19.5054 5.3391C19.0302 5.3391 18.625 5.51023 18.2896 5.85248C17.9636 6.18548 17.8006 6.64798 17.8006 7.23998C17.8006 7.83198 17.9636 8.30373 18.2896 8.65523C18.625 8.99748 19.0302 9.1686 19.5054 9.1686C19.9805 9.1686 20.381 8.99748 20.7071 8.65523C21.0425 8.31298 21.2101 7.84585 21.2101 7.25385Z" fill="white" />
        <path d="M29.4912 2.57811C29.072 2.57811 28.7273 2.45786 28.4572 2.21736C28.1963 1.96761 28.0659 1.66236 28.0659 1.30161C28.0659 0.931606 28.1963 0.626356 28.4572 0.385856C28.7273 0.136106 29.072 0.0112305 29.4912 0.0112305C29.9011 0.0112305 30.2365 0.136106 30.4973 0.385856C30.7675 0.626356 30.9026 0.931606 30.9026 1.30161C30.9026 1.66236 30.7675 1.96761 30.4973 2.21736C30.2365 2.45786 29.9011 2.57811 29.4912 2.57811ZM30.679 3.38286V11.1251H28.2895V3.38286H30.679Z" fill="white" />
        <path d="M37.1617 3.29956C38.0746 3.29956 38.8013 3.59556 39.3416 4.18756C39.8912 4.77031 40.166 5.57506 40.166 6.60181V11.1251H37.7905V6.92094C37.7905 6.40294 37.6554 6.00056 37.3853 5.71381C37.1151 5.42706 36.7518 5.28369 36.2953 5.28369C35.8389 5.28369 35.4755 5.42706 35.2054 5.71381C34.9352 6.00056 34.8001 6.40294 34.8001 6.92094V11.1251H32.4106V3.38281H34.8001V4.40956C35.0424 4.06731 35.3684 3.79906 35.7783 3.60481C36.1882 3.40131 36.6493 3.29956 37.1617 3.29956Z" fill="white" />
        <path d="M55.9809 3.38281L53.8709 11.1251H51.2299L50.0002 6.10231L48.7286 11.1251H46.1015L43.9775 3.38281H46.367L47.471 8.91894L48.7845 3.38281H51.3137L52.6412 8.89119L53.7312 3.38281H55.9809Z" fill="white" />
        <path d="M58.0938 2.57811C57.6746 2.57811 57.3299 2.45786 57.0597 2.21736C56.7989 1.96761 56.6685 1.66236 56.6685 1.30161C56.6685 0.931606 56.7989 0.626356 57.0597 0.385856C57.3299 0.136106 57.6746 0.0112305 58.0938 0.0112305C58.5037 0.0112305 58.839 0.136106 59.0999 0.385856C59.37 0.626356 59.5051 0.931606 59.5051 1.30161C59.5051 1.66236 59.37 1.96761 59.0999 2.21736C58.839 2.45786 58.5037 2.57811 58.0938 2.57811ZM59.2815 3.38286V11.1251H56.892V3.38286H59.2815Z" fill="white" />
        <path d="M65.3588 9.11322V11.1251H64.143C63.2766 11.1251 62.6012 10.917 62.1168 10.5007C61.6324 10.0752 61.3902 9.3861 61.3902 8.43335V5.3531H60.4399V3.38285H61.3902V1.49585H63.7797V3.38285H65.3448V5.3531H63.7797V8.4611C63.7797 8.69235 63.8355 8.85885 63.9473 8.9606C64.0591 9.06235 64.2454 9.11322 64.5063 9.11322H65.3588Z" fill="white" />
        <path d="M71.4824 3.29942C72.3767 3.29942 73.0941 3.59542 73.6344 4.18742C74.1747 4.77017 74.4448 5.57492 74.4448 6.60167V11.1249H72.0693V6.9208C72.0693 6.4028 71.9342 6.00042 71.6641 5.71367C71.3939 5.42692 71.0306 5.28355 70.5741 5.28355C70.1177 5.28355 69.7543 5.42692 69.4842 5.71367C69.214 6.00042 69.0789 6.4028 69.0789 6.9208V11.1249H66.6895V0.857422H69.0789V4.4233C69.3212 4.08105 69.6519 3.80817 70.0711 3.60467C70.4903 3.40117 70.9607 3.29942 71.4824 3.29942Z" fill="white" />
        <path d="M83.4104 11.3264C85.6462 11.3264 87.0063 10.1662 87.0063 8.326C87.0063 7.06592 86.2308 6.20582 84.6292 5.85579L83.058 5.50567C82.4633 5.36569 82.1512 5.17568 82.1512 4.82564C82.1512 4.47561 82.4431 4.25554 83.1581 4.25554C83.9872 4.28429 84.7791 4.6031 85.3947 5.15564L86.7137 3.23549C85.6757 2.51438 84.4235 2.16203 83.1581 2.23534C81.0636 2.23534 79.6531 3.23549 79.6531 5.00563C79.6399 5.63402 79.8634 6.24467 80.2811 6.71688C80.6995 7.1891 81.2802 7.48849 81.9098 7.55594L83.471 7.92601C84.2465 8.09598 84.4779 8.326 84.4779 8.62608C84.4779 8.92609 84.1658 9.21616 83.471 9.21616C82.4858 9.19696 81.5364 8.84408 80.781 8.216L79.3813 10.0763C80.5342 10.9515 81.961 11.3943 83.4104 11.3264Z" fill="white" />
        <path d="M89.614 5.18486C89.9175 5.18486 90.2141 5.09544 90.4656 4.92802C90.7179 4.76052 90.9143 4.52248 91.0292 4.24414C91.1449 3.96579 91.1752 3.65961 91.1146 3.36446C91.0548 3.06931 90.9081 2.79844 90.6931 2.58623C90.478 2.37409 90.204 2.2301 89.9067 2.17252C89.6093 2.11502 89.3011 2.14654 89.0217 2.26317C88.7414 2.37972 88.5031 2.57613 88.3362 2.82742C88.1693 3.07879 88.0816 3.37363 88.0831 3.67472C88.0808 3.87467 88.118 4.07309 88.1941 4.25816C88.2702 4.44332 88.3835 4.61128 88.5264 4.75219C88.6692 4.8931 88.8392 5.00402 89.0256 5.07841C89.2127 5.15272 89.4129 5.18895 89.614 5.18486Z" fill="white" />
        <path d="M87.9639 11.1252H91.2671L90.5723 5.69458C89.9481 5.85461 89.2929 5.85461 88.6688 5.69458L87.9639 11.1252Z" fill="white" />
        <path d="M92.8174 11.1243H95.4964V6.42377C95.4964 5.27368 96.06 4.56359 97.1484 4.56359C98.2361 4.56359 98.7298 5.15366 98.7298 6.42377V11.1243H101.369V5.7637C101.369 3.42345 100.19 2.22334 98.1755 2.22334C97.6507 2.20068 97.129 2.31199 96.6601 2.54655C96.1912 2.78119 95.7907 3.13131 95.4964 3.56351V3.00342C95.5042 2.92141 95.4933 2.83862 95.4654 2.76115C95.4374 2.68361 95.3924 2.61315 95.3334 2.55495C95.2752 2.49668 95.2038 2.45197 95.1261 2.42407C95.0477 2.39609 94.9647 2.3856 94.8816 2.39331H92.8678L92.8174 11.1243Z" fill="white" />
        <path d="M110 2.39442C109.917 2.38671 109.834 2.39719 109.756 2.42517C109.678 2.45308 109.607 2.49779 109.548 2.55606C109.489 2.61426 109.444 2.68471 109.416 2.76226C109.388 2.83973 109.378 2.92252 109.385 3.00453V3.53456C109.093 3.10597 108.693 2.76002 108.225 2.53039C107.757 2.30084 107.237 2.19539 106.716 2.22445C105.632 2.32805 104.626 2.82901 103.893 3.62968C103.161 4.43034 102.755 5.4732 102.755 6.55491C102.755 7.63662 103.161 8.67948 103.893 9.48015C104.626 10.2808 105.632 10.7818 106.716 10.8854C107.22 10.9169 107.725 10.8301 108.188 10.6321C108.652 10.434 109.062 10.1302 109.385 9.74524V10.3254C109.386 10.5993 109.33 10.8705 109.221 11.1221C109.111 11.3736 108.951 11.6002 108.75 11.7877C108.549 11.9753 108.311 12.1198 108.052 12.2123C107.792 12.3048 107.516 12.3434 107.24 12.3256C106.251 12.3446 105.293 11.9867 104.561 11.3254L103.181 13.3257C104.356 14.2078 105.81 14.6473 107.281 14.5658C109.92 14.5658 112.065 13.2557 112.065 9.96531V2.39442H110ZM107.421 8.57511C107.023 8.57511 106.634 8.45787 106.303 8.23803C105.971 8.01826 105.713 7.70584 105.56 7.34039C105.408 6.97486 105.368 6.57272 105.446 6.18468C105.524 5.79672 105.716 5.44029 105.998 5.16055C106.279 4.88082 106.638 4.69034 107.029 4.61311C107.42 4.53595 107.824 4.57557 108.192 4.72696C108.561 4.87835 108.875 5.13473 109.097 5.46364C109.318 5.79256 109.436 6.17929 109.436 6.57495C109.436 7.10544 109.224 7.61419 108.846 7.98928C108.469 8.36444 107.956 8.57511 107.421 8.57511Z" fill="white" />
        <path d="M119.44 2.22366C118.902 2.20007 118.368 2.30707 117.881 2.53539C117.395 2.76371 116.972 3.10642 116.65 3.53385V3.00374C116.657 2.92173 116.647 2.83902 116.619 2.76147C116.591 2.68393 116.546 2.61355 116.487 2.55527C116.428 2.497 116.357 2.45229 116.279 2.42439C116.201 2.39648 116.118 2.386 116.036 2.39371H114.021V14.3251H116.65V10.1646C117 10.5503 117.431 10.8543 117.913 11.0549C118.396 11.2554 118.917 11.3476 119.44 11.3247C120.016 11.3101 120.583 11.1793 121.106 10.9402C121.63 10.7012 122.099 10.359 122.486 9.93431C122.872 9.50958 123.167 9.01131 123.353 8.4698C123.539 7.92829 123.613 7.35479 123.569 6.78414C123.616 6.21203 123.545 5.63637 123.36 5.09247C123.175 4.54857 122.88 4.04784 122.494 3.62095C122.107 3.19406 121.637 2.84996 121.112 2.60977C120.586 2.3695 120.017 2.23808 119.44 2.22366ZM118.735 8.99443C118.285 9.01239 117.839 8.89615 117.456 8.66074C117.073 8.42532 116.77 8.08146 116.586 7.67338C116.401 7.26537 116.344 6.81182 116.421 6.37121C116.498 5.9306 116.707 5.52306 117.02 5.20108C117.332 4.87911 117.735 4.65741 118.176 4.56453C118.616 4.47172 119.075 4.51188 119.492 4.68008C119.909 4.84819 120.266 5.13656 120.517 5.50795C120.769 5.87942 120.901 6.31694 120.9 6.76418C120.915 7.05424 120.87 7.34438 120.768 7.61649C120.665 7.88859 120.508 8.13696 120.305 8.34608C120.102 8.55521 119.857 8.72063 119.587 8.83217C119.317 8.94371 119.027 8.9989 118.735 8.99443Z" fill="white" />
        <path d="M132.281 2.39401C132.198 2.3863 132.115 2.39671 132.037 2.42469C131.959 2.45259 131.888 2.4973 131.829 2.55558C131.77 2.61385 131.725 2.68423 131.697 2.76177C131.669 2.83932 131.659 2.92203 131.666 3.00405V3.53415C131.34 3.09948 130.909 2.75229 130.413 2.52359C129.917 2.29496 129.372 2.19197 128.826 2.22396C128.25 2.23861 127.683 2.36934 127.159 2.60838C126.636 2.84741 126.166 3.18966 125.78 3.61439C125.394 4.03905 125.099 4.53731 124.912 5.0789C124.726 5.62041 124.652 6.19391 124.697 6.76448C124.652 7.33505 124.726 7.90855 124.912 8.45006C125.099 8.99165 125.394 9.48984 125.78 9.91457C126.166 10.3393 126.636 10.6815 127.159 10.9206C127.683 11.1596 128.25 11.2904 128.826 11.305C129.372 11.3343 129.916 11.2301 130.411 11.0017C130.906 10.7733 131.337 10.4275 131.666 9.99481V11.1249H134.305V2.39401H132.281ZM129.531 8.99473C128.96 8.95681 128.425 8.70475 128.034 8.28973C127.643 7.87471 127.425 7.32773 127.425 6.75947C127.425 6.19121 127.643 5.64423 128.034 5.22921C128.425 4.8142 128.96 4.56221 129.531 4.52421C130.102 4.56221 130.637 4.8142 131.028 5.22921C131.419 5.64423 131.637 6.19121 131.637 6.75947C131.637 7.32773 131.419 7.87471 131.028 8.28973C130.637 8.70475 130.102 8.95681 129.531 8.99473Z" fill="white" />
        <path d="M139.645 11.3257C141.88 11.3257 143.241 10.1656 143.241 8.32534C143.241 7.06518 142.465 6.20509 140.863 5.85505L139.302 5.50502C138.698 5.36496 138.385 5.17495 138.385 4.82491C138.385 4.47488 138.677 4.25488 139.393 4.25488C140.221 4.28355 141.014 4.60237 141.629 5.15498L142.949 3.23476C141.91 2.51365 140.658 2.1613 139.393 2.2346C137.298 2.2346 135.888 3.23476 135.888 5.00498C135.874 5.63328 136.098 6.24394 136.516 6.71615C136.934 7.18836 137.514 7.48775 138.144 7.5552L139.705 7.92528C140.481 8.09525 140.712 8.32534 140.712 8.62535C140.712 8.92536 140.39 9.21542 139.705 9.21542C138.72 9.19623 137.771 8.84334 137.016 8.21527L135.696 10.1355C136.851 11.0037 138.278 11.4393 139.725 11.3657L139.645 11.3257Z" fill="white" />
        <path d="M147.983 11.3262C150.22 11.3262 151.579 10.1661 151.579 8.32585C151.579 7.06569 150.803 6.2056 149.202 5.85556L147.631 5.50553C147.037 5.36554 146.724 5.17553 146.724 4.8255C146.724 4.47546 147.017 4.25539 147.731 4.25539C148.557 4.28299 149.347 4.60211 149.957 5.15549L151.277 3.23527C150.244 2.51207 148.993 2.15942 147.731 2.23519C145.636 2.23519 144.226 3.23527 144.226 5.00549C144.212 5.63387 144.437 6.24452 144.854 6.71674C145.272 7.18887 145.853 7.48834 146.482 7.55579L148.044 7.92579C148.819 8.09584 149.05 8.32585 149.05 8.62594C149.05 8.92595 148.738 9.21593 148.044 9.21593C147.058 9.19682 146.11 8.84393 145.354 8.21585L144.035 10.1361C145.19 11.0043 146.617 11.4399 148.064 11.3663L147.983 11.3262Z" fill="white" />
    </svg>
</SingpassButton>)
}