import React from 'react'

export const Policy = () => {
  return (
    <div className="policy">
      {/* <p style={{ fontSize: 16, fontWeight: 700 }}>Acknowledgement of declaration</p> */}
      
    </div>
  )
}
