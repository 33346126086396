/* eslint-disable no-useless-escape */
export const Validator = {
  isPhoneNumber(phoneNumber: string) {
    const regex = /^[\+]{0,1}[0-9]{8,16}$/;
    return regex.test(String(phoneNumber).toLowerCase());
  },
  isEmptyString(str: string) {
    if (str.trim() === '') return true
    return false
  },
  isNRIC(ic: string) {
    const regex = /^[STFGM]\d{7}[A-Z]$/;
    return regex.test(String(ic).toUpperCase());
  },
  isAlphanumericWithHyphen(str: string) {
    const regex = /^[a-zA-Z0-9-]*$/;
    return regex.test(str)
  },
  // const long = /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/;
  isLatitude(coordinate: number | string) {
    return isFinite(+coordinate) && Math.abs(+coordinate) <= 90
  },
  // const lat = /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;
  isLongitude(coordinate: number | string) {
    return isFinite(+coordinate) && Math.abs(+coordinate) <= 180
  },
  isLatLong(lat: number | string, long: number | string) {
    return this.isLatitude(lat) && this.isLongitude(long);
  },
  isEmail(email: string) {
    const regex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    return regex.test(email)
  },
  isBuildingAdminEmail(email: string) {
    const regexs = [/@defence\.gov\.sg$/, /@mindef\.gov\.sg$/];
    return regexs.some(e => e.test(email))
  }
}