import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Select, Button } from "antd";
import { DISPLAY_ROLES } from "config";

const { Option } = Select;

interface Props {
  visible: boolean;
  onOk: (e: any, context: any) => void;
  onCancel: (e: any) => void;
}

const layout = {
  labelCol: { span: 5 },
  // wrapperCol: { span: 20 },
}

function CreateUserModal(props: Props) {
  const { visible, onOk: propsOnOk, onCancel: propsOnCancel } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [roleID, setRoleID] = useState<number>(null!)

  useEffect(() => {
  }, [visible, roleID])
  const onChangeRole = (id: number) => {
    setRoleID(id)
  }

  const onOk = async () => {
    const context = {
      form,
      modal: {
        success() {
          form.resetFields();
          setLoading(false);
        },
        error(fields: any[]) {
          form.setFields(fields);
          setLoading(false);
        },
      },
    };

    setLoading(true);
    try {
      const values = await form.validateFields();
      propsOnOk(values, context);

    } catch (e) {
      setLoading(false);
    }
  };
  const onCancel = () => {
    form.resetFields();
    propsOnCancel(null);
  };
  // @ts-ignore
  return (
    <Modal
      title="Create User"
      visible={visible}
      onOk={onOk}
      okButtonProps={{ loading }}
      onCancel={onCancel}
      maskClosable={false}
      destroyOnClose
      getContainer={false}
      footer={[
        <Button key="back" className="btn-default" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" className="btn-edit" onClick={onOk}>
          Save
        </Button>,
      ]}
    >
      <Form form={form} {...layout} name="admin-user-group">
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Name is required' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="nric"
          label="NRIC/FIN"
          rules={[{ required: true, message: 'NRIC/FIN is required', pattern: /^[STFGM]\d{7}[A-Z]$/i}]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="identity"
          label="Phone No."
          rules={[{ required: true, message: 'Phone No is required (8 digits)', pattern: /^\d{8}$/}]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Role"
          name="role_id"
          rules={[{ required: true, message: "Please select a role" }]}
        >
          <Select onChange={onChangeRole} placeholder="Choose a role">
            {DISPLAY_ROLES.map(({ id, label }) => (
              <Option value={id} key={id}>
                {label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CreateUserModal;
