import React, { useEffect, useMemo, useState } from 'react'
import { Button, Divider, message, Space, Modal, Typography, Form } from 'antd'

import { CardDefault } from 'components/cards/CardDefault'
import {
  DeclarationForm,
  DeclarationFormField,
} from 'components/DeclarationForm'
import { Policy } from 'components/Policy'

import { useRouter } from 'hooks/useRouter'

import { ReactComponent as CheckInIcon } from 'assets/icons/checkin-icon.svg'
import { ReactComponent as DangerousIcon } from 'assets/icons/dangerous-icon.svg'

import { RoutePaths } from 'hooks/useNavigation'
import { MESSAGES } from 'config'
// import { LocalStorage } from 'utils/local-storage'
import { Validator } from 'utils/validator'

import {
  AnswerType,
  CheckingAPI,
  CheckingInfo,
  CheckListQuestion,
} from 'api/Main'

import { DeclarationCheckList } from 'components/DeclarationCheckList'
import { IsDangerousAnswer } from '../../../components/DeclarationCheckList'

const { Text } = Typography

type Props = {
  event?: any
  questions: CheckListQuestion[]
}

export type Declaration = {
  title: string
  answer: any
  isValid: boolean
  answer_type: string
  negative_conditions: any[]
}

const initResidentInfo = {
  phone: '',
  ic: '',
  name: '',
  // reason: '',
}

const initFormErrors = {
  phone: true,
  ic: true,
  name: true,
  // reason: false,
}

export type ResidentInfo = typeof initResidentInfo //& { reason?: string }

export const EventDeclarationPage = ({ event, questions }: Props) => {
  const router = useRouter()
  const [form] = Form.useForm()
  const [checklist] = Form.useForm()

  const [residentInfo, setResidentInfo] = useState<ResidentInfo>(() => {
    return {
      ...initResidentInfo,
      ...event.residentInfo,
    }
  })

  const [declarations, setDeclarations] = useState<{
    [key: number]: Declaration
  }>({})

  // const [initial, setInitial] = useState(true)
  // const [isRemember, setIsRemember] = useState(true)
  const [isDangerous, setDangerous] = useState<boolean>(false)
  // const [loading, setLoading] = useState(false)

  useEffect(() => {
    setDeclarations(
      questions.reduce((acc: any, curr) => {
        const currentAnswer =
          curr.answer_type === AnswerType.Boolean ? false : ''
        const isValid = curr.answer_type === AnswerType.Boolean ? true : false
        acc[curr.question_id] = {
          title: curr.title,
          answer: currentAnswer,
          isValid,
        }
        return acc
      }, {})
    )
  }, [questions])

  useEffect(() => {
    if (isDangerous) {
      showDangerousPopup()
    }
  }, [isDangerous])

  const showDangerousPopup = (callback?: Function) => {
    Modal.error({
      icon: null,
      centered: true,
      content: (
        <Space align="center" direction={'vertical'}>
          <DangerousIcon
            width={60}
            height={60}
            style={{ marginRight: '15px' }}
          />
          <Text type="danger" style={{ fontSize: '15px' }}>
            Please seek assistance from our medical staff after checking-in.
          </Text>
        </Space>
      ),
      className: 'dangerous-popup',
      okText: 'Yes, I will',
      okButtonProps: {
        shape: 'round',
        className: 'btn-delete',
        style: {
          padding: '0 40px',
          margin: '0 auto',
        },
      },
      onOk: () => {
        if (callback) {
          callback()
        }
      },
    })
  }

  const handleChangeDeclares = (values: any) => {
    setDeclarations((preState: any) => ({ ...preState, ...values }))
  }

  const changeResidentInfo = (changed: object) => {
    setResidentInfo({ ...residentInfo, ...changed })
  }

  const [formHasError, setFormHasError] = useState(initFormErrors)
  const noError = useMemo(() => {
    const newError = { ...formHasError }
    if (router.state?.type === RoutePaths.CHECK_IN) {
      const validDeclaration = Object.values(declarations).every(
        (item) => item.isValid === true
      )
      if (!validDeclaration) return false
    }

    // if (!questions?.find((e) => e.title.toLowerCase() === 'purpose of visit')) {
    //   delete newError.reason
    // }

    // validate declaration
    const result = Object.values(newError).every((status) => status === false)
    return result
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formHasError, router.state, declarations])

  // validate
  useEffect(() => {
    setFormHasError((errors) => {
      let {
        phone,
        ic,
        name,
        // reason
      } = errors
      phone = Validator.isPhoneNumber(residentInfo.phone) === false
      ic = Validator.isNRIC(residentInfo.ic) === false
      if (router.state?.type === RoutePaths.CHECK_IN) {
        name = !residentInfo.name
        // reason = !residentInfo.reason
      } else {
        name = false
        // reason = false
      }
      return {
        ...errors,
        phone,
        ic,
        name,
        // reason,
      }
    })
  }, [residentInfo, router.state])

  const residentFormField: DeclarationFormField = [
    {
      name: 'ic',
      placeholder: 'NRIC',
      label: 'NRIC',
      value: residentInfo.ic,
      isShow: true,
    },
    {
      name: 'phone',
      placeholder: 'Mobile number',
      label: 'Mobile number',
      value: residentInfo.phone,
      isShow: true,
    },
    {
      name: 'name',
      placeholder: 'Full Name in NRIC',
      label: 'Name',
      value: residentInfo.name,
      isShow: true,
    },
  ]

  // check-in, check-out action

  // go to complete page
  const goToComplete = async (type: string) => {
    try {
      // setLoading(true)
      await checklist.validateFields()
      const declarationForm = await checklist.getFieldsValue()

      if (!event) throw Error(MESSAGES.checkError(type))
      const questionRegex = /\[(\d+)\](.+$)/
      const declarationAnswers = Object.entries(declarationForm).map(
        (item, index) => {
          return {
            title: item[0].match(questionRegex)[2].trim(),
            answer: item[1],
          }
        }
      )

      let payload: CheckingInfo = {
        ic: residentInfo.ic,
        name: residentInfo.name,
        phone_number: residentInfo.phone,
        location_id: event.locationId,
        entrance_id: event.entranceId,
        type: 'check-in',
        declarations: declarationAnswers,
      }

      const checkingResult = await CheckingAPI.check(payload)
      const isDangerous = !!Object.values(declarations).filter((e) => {
        return (
          e.answer === true ||
          (e.answer_type === 'decimal' &&
            IsDangerousAnswer(e.answer, e.negative_conditions))
        )
      }).length

      router.push(RoutePaths.EVENT_COMPLETE, {
        isDangerous,
        eventName: event.eventName,
        eventId: event.eventId,
        entryId: checkingResult.data.entry_id,
      })
    } catch (error) {
      if (error.message) {
        message.error(error.message)
      }
    } finally {
      // setLoading(false)
    }
  }

  return (
    <div className="declaration-page container d-flex align-items-center flex-column">
      <CardDefault classNames="form-card" contentCentered>
        <Space
          className="building-name"
          align="center"
          direction="vertical"
          size={1}
        >
          <p>{event?.eventName}</p>
          <span className="d-inline-block text-center">
            {' '}
            {event?.entranceName}{' '}
          </span>
        </Space>
        <DeclarationForm
          onChange={changeResidentInfo}
          initialValues={residentInfo}
          fields={residentFormField}
          form={form}
          type={RoutePaths.CHECK_IN}
          // isShowExtends={router.state?.type === RoutePaths.CHECK_IN}
          // reason={questions?.find(
          //   (e) => e.title.toLowerCase() === 'purpose of visit'
          // )}
          // extendsError={formHasError.reason && initial === false}
        />
        <Divider type="horizontal">Declaration Checklist</Divider>
        <DeclarationCheckList
          form={checklist}
          declarations={declarations}
          onChange={handleChangeDeclares}
          checklist={questions?.filter(
            (e) => e.title.toLowerCase() !== 'purpose of visit'
          )}
          isDangerous={isDangerous}
          setDangerous={setDangerous}
        />
        <Divider type="horizontal" />
        <Policy />
      </CardDefault>
      <div className="bottom-panel">
        <div className="d-flex justify-content-center align-items-center z-10">
          <Button
            // loading={loading}
            shape="round"
            disabled={!noError}
            className="btn-check-in"
            onClick={() => goToComplete(RoutePaths.CHECK_IN)}
          >
            <span>Check-in</span>
            <CheckInIcon style={{ height: 'auto', width: 30 }} />
          </Button>
        </div>
      </div>
    </div>
  )
}
