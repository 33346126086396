import { IRoomAPIV2 } from './Rooms';

const apiURL = process.env.REACT_APP_API_URL;
const urlPrefix = `${apiURL}/api/v2/`;

export interface IRoomGroupDetails{
    groupId: number,
    entrances?: {entranceId: number, entranceName: string, locationId: number}[],
    description: string, 
    locations?: {locationId: number, locationName: string}[],
    groupName: string,
    rooms?: IRoomAPIV2 [],
}   

export const GroupsAPI = {
    async list( 
        token: string): Promise<{items: IRoomGroupDetails[], total: number}> {
        const resp = await fetch(`${urlPrefix+'getGroups'}`,
        {
            credentials: 'include',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
            },
        });

        const {status, data: items, message} = await resp.json();
        if (status === false || !items?.length) throw Error(message || 'Data not found')
        return { items , total: items.length }
    },

    async delete(token: string, groupId: IRoomGroupDetails["groupId"]): Promise<void> {
        const resp = await fetch(`${urlPrefix + 'deleteGroup'}`,
        {
          credentials: 'include',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
          body: JSON.stringify({groupId})
        });
      const {status, message} = await resp.json();
      if (status === false ) throw Error(message || 'Group deletion failed')
    },

    async create(token:string, data: any): Promise<any> {
        console.log('data is ',data)
        const resp = await fetch(`${urlPrefix + 'addGroup'}`,
        {
          credentials: 'include',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
          body: JSON.stringify(data)
        });
      const response = await resp.json();
      console.log('response is ', response);
      return response;
    },

    async update(token:string): Promise<any>{

    }
}

