import React, { useEffect, useState } from 'react'

import {
  Table,
  Row,
  Card,
  Col,
  Typography,
  Button,
  Modal,
  message,
  Tooltip,
  Dropdown,
  Menu,
} from 'antd'
import { ColumnType } from 'antd/es/table'
import { DownOutlined } from '@ant-design/icons';

import { IRoomGroupDetails, GroupsAPI } from 'api/AP/RoomAccessManagement'
import { CreateGroupModal } from './GroupModal/CreateGroupModal'
import { EditGroupModal } from './GroupModal/EditGroupModal'
import { EditUsersModal } from './UsersModal/EditUsersModal'

import useAuthUser from 'hooks/useAuthUser'

import './style.less'
const { Title } = Typography

enum MODAL_TAG {
  CREATE_GROUP = 'CREATE_GROUP',
  EDIT_GROUP = 'EDIT_GROUP',
  DELETE_GROUP = 'DELETE_GROUP',
  MANAGE_USERS = 'MANAGE_USERS',
}

const roomAccessTableMoreActions = [
    {
      key: "editGroup",
      text: "Edit Group",
      actionTag: MODAL_TAG.EDIT_GROUP,
    },
    {
      key: "manageUser",
      text: "Manage Users",
      actionTag: MODAL_TAG.MANAGE_USERS,
    },
    {
      key: "deleteGroup",
      text: "Delete",
      actionTag: MODAL_TAG.DELETE_GROUP,
    },
]

type TablePaginationType = {
  current: number
  pageSize: number
  total: number
}

const initPagination: TablePaginationType = {
  pageSize: 10,
  current: 1,
  total: 0,
}

export const RoomAccessManagement = () => {
  const [groups, setGroups] = useState<IRoomGroupDetails[]>(null!)
  const [loading, setLoading] = useState(false)

  const [user] = useAuthUser()
  // handle for pagination
  const [pagination, setPagination] = useState<TablePaginationType>(initPagination)

  const fetchGroups = (token: string, pagination: TablePaginationType) => {
    setLoading(true)
    GroupsAPI.list(token)
      .then(({ items, total }:{items: IRoomGroupDetails,total: number}) => {
        setLoading(false)
        console.log('groups are ', items)
        setGroups(items)
        setPagination({ ...pagination, total })
      })
      .catch((error: any) => {
        setLoading(false)
        message.error(error.message)
      })
  }
  // handle edit group
  const [selectedGroup, setSelectedGroup] = useState<IRoomGroupDetails>(null!)

  // handle modal
  const [visibleModal, setVisibleModal] = useState<MODAL_TAG | null>(null)

  useEffect(() => {
    if (user) {
      fetchGroups(user?.accessToken, initPagination)
    }
  }, [user])

  const openModal = (type: MODAL_TAG | null) => {
    setVisibleModal(type)
  }

  const closeModal = () => {
    setVisibleModal(null)
  }

  const handleOK = () => {
    closeModal()
    // refresh the page to reflect new group added
    fetchGroups(user?.accessToken, pagination)
  }

  const handleDeleteGroup = (groupDetails: IRoomGroupDetails) => {
    Modal.confirm({
      title: `Are you sure if you want to delete ${
        groupDetails ? groupDetails.groupName : 'N/A'
      }?`,
      icon: null,
      okText: 'Yes',
      okType: 'default',
      okButtonProps: { className: 'btn-delete-custom' },
      cancelText: 'Cancel',
      cancelButtonProps: { className: 'btn-cancel' },
      centered: true,
      maskClosable: false,
      async onOk() {
        // handle change confirm status
        try {
          const messageRes = await GroupsAPI.delete(
            user?.accessToken,
            groupDetails.groupId
          )
          message.success(messageRes)
          fetchGroups(user?.accessToken, pagination)
        } catch (error) {
          message.error(error.message)
        }
      }
    })
  }

  const columns: ColumnType<IRoomGroupDetails>[] = [
    {
      width: '10%',
      title: 'ID',
      key: 'groupId',
      align: 'left',
      dataIndex: 'groupId',
      ellipsis: { showTitle: true },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      width: '15%',
      title: 'Name',
      align: 'left',
      key: 'groupName',
      dataIndex: 'groupName',
      ellipsis: { showTitle: true },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      width: '20%',
      title: 'Location',
      align: 'left',
      key: 'locations',
      dataIndex: 'locations',
      ellipsis: { showTitle: true },
      render: (_, {locations}) => {
        if(locations){
            return(
                <>
                {locations.map((location, index) => {
                    let extra = ","
                    if(index === locations.length-1){
                        extra = ''
                    }
                    return (
                        
                        <Tooltip placement='topLeft' title={location.locationName} key={location.locationId}>
                            {location.locationName + extra }&nbsp;
                        </Tooltip>
                    );
                })}
                </>
            )
        }
        return <>{'No Location Available'}</>
    }
    },
    {
      width: '20%',
      title: 'Entrance',
      align: 'left',
      key: 'entrances',
      dataIndex: 'entrances',
      ellipsis: true,
      render: (_, {entrances}) => {
        if(entrances){
            return(
                <>
                {entrances.map((entrance, index) => {
                    let extra = ","
                    if(index === entrances.length-1){
                        extra = ''
                    }
                    return (
                        <Tooltip placement='topLeft' title={entrance.entranceName} key={entrance.entranceId}>
                            {entrance.entranceName + extra}&nbsp;
                        </Tooltip>
                    );
                })}
            </>
            )
        }
        return <>{'No Entrance Available'}</>
    }
    },
    {
      width: '20%',
      align: 'left',
      title: 'Room(s)',
      key: 'rooms',
      dataIndex: 'rooms',
      render: (_, {rooms}) => {
        if(rooms){
            return(
                <>
                {rooms.map((room, index) => {
                    let extra = ","
                    if(index === rooms.length-1){
                        extra = ''
                    }
                    return (
                    <Tooltip placement='topLeft' title={room.roomName} key={room.roomId}>
                        {room.roomName + extra}&nbsp;
                    </Tooltip>
                );
                })}
            </>
            )
        }
        return <>{'No Rooms Available'}</>
    }
    },
    {
      width: '15%',
      title: 'Action',
      align: 'center',
      key: 'action',
      render: (_, record: IRoomGroupDetails) => {
        const handleAction = (type: MODAL_TAG) => {
          setSelectedGroup(record)
          openModal(type)
        }
        return (
          <Dropdown placement="bottomLeft"
            overlay={
            <Menu>
              {roomAccessTableMoreActions.map(item => (
                  <Menu.Item key={item.key}>
                      {(item.actionTag !== MODAL_TAG.DELETE_GROUP ) && <a href="#" onClick={() => handleAction(item.actionTag)}>{item.text}</a>}
                      {(item.actionTag === MODAL_TAG.DELETE_GROUP ) && <a href="#" onClick={() => handleDeleteGroup(record)}><span className='danger-color'>{item.text}</span></a>}
                  </Menu.Item>
              ))}
            </Menu>}>
            <Button type="link" className='link-button-plain'>More<DownOutlined /></Button>
          </Dropdown>
        )
      },
    },
  ]

  const handleChangePage = (pagination: any) => {
    setPagination({ ...pagination })
  }

  return (
    <Row className="">
      <Col span={24}>
        <Card
          loading={loading}
          title={
            <Title level={2}>
              Room Access Management
            </Title>
          }
          extra={
            <Button
              className="btn-create"
              type="primary"
              onClick={() => openModal(MODAL_TAG.CREATE_GROUP)}
            >
              Create
            </Button>
          }
        >
          <Table
            className="groups-table"
            title={() => (
              <Row justify="space-between" align="middle">
                <Col>View and manage groups.</Col>
              </Row>
            )}
            pagination={pagination}
            columns={columns}
            dataSource={groups}
            rowKey={(record) => record.groupId}
            onChange={handleChangePage}
          />
        </Card>
      </Col>
     {visibleModal === MODAL_TAG.CREATE_GROUP && <CreateGroupModal
        accessToken={user?.accessToken}
        visible={visibleModal === MODAL_TAG.CREATE_GROUP}
        onOk={handleOK}
        onCancel={closeModal}
      />}
      {visibleModal === MODAL_TAG.EDIT_GROUP && <EditGroupModal
        accessToken={user?.accessToken}
        visible={visibleModal === MODAL_TAG.EDIT_GROUP}
        onOk={handleOK}
        onCancel={closeModal}
        groupInfo={selectedGroup}
      />}
      {visibleModal === MODAL_TAG.MANAGE_USERS && <EditUsersModal
        accessToken={user?.accessToken}
        visible={visibleModal === MODAL_TAG.MANAGE_USERS}
        onOk={handleOK}
        onCancel={closeModal}
        groupInfo={selectedGroup}
      />}
    </Row>
  )
}
