import React, { useState } from 'react'
import { ReactComponent as BackButtonIcon } from 'assets/icons/backbtn-icon.svg'
import { useRouter } from 'hooks/useRouter'
import { DownloadBanner } from 'components/DownloadBanner'
import { SwiftEntryLogo } from 'components/SwiftEntryLogo'
import iBackButton from  '../../../assets/icons/i-back-button.png'

type Props = {
  title: string
}

export const Header = ({ title }: Props) => {
  const router = useRouter()
  const canGoBack = router.state?.canGoBack

  // Change to true if want to show download banner
  const [isShowDownload, setIsShowDownload] = useState(false)
  const goBack = () => {
    router.history.goBack()
  }
  return (
    <header className="header">
      {isShowDownload && (
        <DownloadBanner onCloseBanner={() => setIsShowDownload(false)} />
      )}
      <div className="d-flex justify-content-center align-items-center" style={{ position: 'relative', paddingTop: 40, marginLeft: 10 }}>
      {canGoBack && (
          <div
            className="back-btn d-flex"
            onClick={goBack}
          >
            <img style={{ width: 24, height: 24 }} src={ iBackButton } alt='back-button' />
          </div>
        )}
        <div><SwiftEntryLogo color="default"/></div>
      </div>
    </header>
  )
}
