import React, { useState } from 'react'

import { Row, Col, Card, Typography, Tabs } from 'antd'
import { ContainerOutlined, QuestionCircleOutlined } from '@ant-design/icons'

import { QuestionsTab } from './QuestionsTab'
import { TemplatesTab } from './TemplatesTab'

const { Title } = Typography
const { TabPane } = Tabs

export function Questionnaire() {
  const defaultActiveKey = 'templates'
  const [tab, setTab] = useState<string>(defaultActiveKey)

  return (
    <Row>
      <Col span={24}>
        <Card
          title={
            <Title style={{ color: '#6b6b6b' }} level={2}>
              Questionnaire Management
            </Title>
          }
        >
          <Tabs
            defaultActiveKey={defaultActiveKey}
            onChange={(activeKey) => setTab(activeKey)}
            // centered
          >
            <TabPane
              tab={
                <span>
                  <ContainerOutlined />
                  Templates
                </span>
              }
              key="templates"
              style={{ padding: 0 }}
            >
              {tab === 'templates' && <TemplatesTab />}
            </TabPane>
            <TabPane
              tab={
                <span>
                  <QuestionCircleOutlined />
                  Questions
                </span>
              }
              key="questions"
              style={{ padding: 0 }}
            >
              {tab === 'questions' && <QuestionsTab />}
            </TabPane>
          </Tabs>
        </Card>
      </Col>
    </Row>
  )
}
