import React, { useEffect } from 'react';
import { AlertStatus } from '../AlertLabel';

import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import './style.css'
import { ThemeProvider, createTheme } from '@mui/material';

type Props = {
    status: AlertStatus,
    message: string,
    onClose: () => void
}

export const NotificationBar = ({ status, message, onClose }: Props) => {

    const [open, setOpen] = React.useState(false);
    const [snackbarBgColor, setSnackbarBgColor] = React.useState("");
    const [snackbarColor, setSnackbarColor] = React.useState("");
    
      const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        
        onClose();
        setOpen(false);
      };

      const action = (
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <span style={{ fontSize: 14, fontWeight: 700 }}>Close</span>
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );

      const notificationBarTheme = React.useMemo(() => 
        createTheme({
          components: {
            MuiSnackbarContent: {
              styleOverrides: {
                root: {
                  display: 'flex',
                  flexDirection: 'row'
                },
                message: {
                  flex: '5'
                },
                action: {
                  flex: '1'
                }
  
              }
            }
          }
        }), []
      )

    useEffect(() => {
        if (status === AlertStatus.success) {
            setSnackbarBgColor('#B9FAD2');
            setSnackbarColor('#166341');
        } else if (status ===  AlertStatus.error) {
            setSnackbarBgColor('#FFD2D2');
            setSnackbarColor('#701919');
        }
        setOpen(true);
    }, [])

    return (
      <ThemeProvider theme={notificationBarTheme}>
        <Snackbar
        ContentProps={{
            sx: {
              background: snackbarBgColor,
              color: snackbarColor,
              fontSize: 14,
              fontWeight: 400,
            }
          }}
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        message={message}
        action={action}
        />
      </ThemeProvider>
    )
}
