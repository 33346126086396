const apiURL = process.env.REACT_APP_API_URL
const urlQuestion = `${apiURL}/api/v1/questions`
const urlAnswer = `${apiURL}/api/v1/answers`

export interface Question {
  id: string
  title: string
  description: string
  answer_type: string
  others_text: string
  negative_conditions: any[]
  answers: Answer[]
  created_by?: string
  updated_by?: string
  created_at?: string
  updated_at?: string
}

export interface Answer {
  id: string
  question_id: number
  answer: string
  created_by?: string
  updated_by?: string
  created_at?: string
  updated_at?: string
}

export type PaginationQueryType = {
  items_per_page: number
  page: number
}

export const QuestionAPI = {
  async list(
    token: string,
    dataQuery?: any
  ): Promise<{ items: Question[]; total: number }> {
    const params = dataQuery ?? {}
    const query = new URLSearchParams(params)

    const res = await fetch(`${urlQuestion}?${query}`, {
      credentials: 'include',
      headers: {
        Authorization: token,
      },
    })
    const { status, data: items, message, total = 0 } = await res.json()
    if (status === false || !items?.length)
      throw Error(message || 'Data not found')

    return { items, total }
  },

  async create(token: string, payload: Question): Promise<string> {
    const res = await fetch(`${urlQuestion}`, {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
      method: 'post',
      body: JSON.stringify(payload),
    })
    const { status, message } = await res.json()
    if (status === false) throw Error(message || 'Create failed')
    return message
  },

  async update(
    token: string,
    id: string | number,
    payload: Question
  ): Promise<string> {
    const res = await fetch(`${urlQuestion}/${id}`, {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
      method: 'put',
      body: JSON.stringify(payload),
    })
    const { status, message } = await res.json()
    if (status === false) throw Error(message || 'Update failed')
    return message
  },

  async delete(token: string, id: string | number): Promise<string> {
    const res = await fetch(`${urlQuestion}/${id}`, {
      credentials: 'include',
      method: 'delete',
      headers: {
        Authorization: token,
      },
    })
    const { status, message } = await res.json()
    if (status === false) throw Error(message || 'Delete failed')
    return message
  },

  async createAnswer(
    token: string,
    questionId: string | number,
    payload: Pick<Answer, 'answer'>
  ): Promise<string> {
    const res = await fetch(`${urlQuestion}/${questionId}/answers`, {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
      method: 'post',
      body: JSON.stringify(payload),
    })
    const { status, message } = await res.json()
    if (status === false) throw Error(message || 'Create answer failed')
    return message
  },

  async updateAnswer(
    token: string,
    id: string | number,
    payload: Answer
  ): Promise<string> {
    const res = await fetch(`${urlAnswer}/${id}`, {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
      method: 'put',
      body: JSON.stringify(payload),
    })
    const { status, message } = await res.json()
    if (status === false) throw Error(message || 'Update answer failed')
    return message
  },

  async deleteAnswer(token: string, id: number): Promise<string> {
    const res = await fetch(`${urlAnswer}/${id}`, {
      credentials: 'include',
      method: 'delete',
      headers: {
        Authorization: token,
      },
    })
    const { status, message } = await res.json()
    if (status === false) throw Error(message || 'Delete answer failed')
    return message
  },
}
