import React, { useEffect } from 'react'
import { cache } from 'swr'
import { Layout, Button } from 'antd'
import { useHistory } from 'react-router-dom'
import { LeftOutlined } from '@ant-design/icons'

// style
import './style.css'
import useAuthUser from '../../../hooks/useAuthUser'

function ErrorPage(props: any) {
  const history = useHistory()
  const { authDispatch, _ } = props

  const logout = async (e: any) => {
    e.preventDefault()
    authDispatch({loggedIn: false})
    cache.clear()
    localStorage.clear()
    // history.push('/admin/login')
    window.location.href = '/admin/login'
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <div className="error-page__wrap">
        <div className="error-page__content">
          <h1>403</h1>
          <div className="error-page__desc">
            Permission denied.
            <br />
            Please contact system administrator for more information.
          </div>
          <Button
            type="primary"
            shape="round"
            icon={<LeftOutlined />}
            onClick={logout}
          >
            Back to login
          </Button>
        </div>
      </div>
    </Layout>
  )
}

export default ErrorPage
