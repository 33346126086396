import React, { useState, useEffect } from 'react'
import moment from 'moment'

import {
  Row,
  Col,
  Card,
  Typography,
  DatePicker,
  Button,
  Input,
  AutoComplete,
  Table,
  message,
} from 'antd'

import { CaretRightFilled } from '@ant-design/icons'
import { ColumnsType } from 'antd/es/table'

import useAuthUser from '../../../hooks/useAuthUser'

import { EntryLogAPI, EntryLog, QueryEntry } from '../../../api/EntryLog'
import { Location, LocationAPI } from '../../../api/Location'
import { Entrance, EntranceAPI } from '../../../api/Entrance'

const { Title } = Typography
const { RangePicker } = DatePicker

type TablePaginationType = {
  current: number
  pageSize: number
  total: number
  search: string
}
const initPagination: TablePaginationType = {
  pageSize: 10,
  current: 1,
  total: 0,
  search: '',
}

type RangeValue = [moment.Moment, moment.Moment] | null

export function ContactTracing() {
  const [user, , dispatch] = useAuthUser()
  const [loading, setLoading] = useState(false)
  const [dateDuration, setDateDuration] = useState<RangeValue>(null)
  const [entry, setEntry] = useState<EntryLog[]>([])
  const [location, setLocation] = useState<number>(null!)
  const [entrance, setEntrance] = useState<number>(null!)
  const [locationSearchOptions, setLocationSearchOptions] = useState<
    Location[]
  >([])
  const [entranceSearchOptions, setEntranceSearchOptions] = useState<
    Entrance[]
  >([])
  const [phoneNumber, setPhoneNumber] = useState<string>('')

  const [pagination] = useState<TablePaginationType>(initPagination)
  /**
   * Fetch API for search location
   */
  const fetchLocations = (token: string, pagination: TablePaginationType) => {
    LocationAPI.list(token, {
      items_per_page: pagination.pageSize,
      page: pagination.current,
      search: pagination.search,
    })
      .then(({ items }) => {
        // if (items && items.length > 0) {
        setLocationSearchOptions(items)
        // }
      })
      .catch(() => {
        setLocationSearchOptions([])
      })
  }

  const fetchEntrances = (token: string, search: string) => {
    EntranceAPI.list(token, location, search)
      .then(({ data }) => {
        const entrances = data
        console.log(entrances)
        if (entrances && entrances.length > 0) {
          setEntranceSearchOptions(entrances)
        }
      })
      .catch(() => {
        setEntranceSearchOptions([])
      })
  }

  const searchLocation = (inputLocation: string) => {
    pagination.search = inputLocation
    fetchLocations(user?.accessToken, pagination)
  }

  const searchEntrance = (inputEntrance: string) => {
    console.log(user)

    fetchEntrances(user?.accessToken, inputEntrance)
  }

  const fetchEntryLogs = async (
    token: string,
    locationId: number,
    dateRange?: RangeValue,
    phoneNumber?: string,
    entranceId?: number,
    sorter?: any,
    type?: string
  ) => {
    let query: QueryEntry = {}

    if (locationId) {
      query.location_id = locationId
    }
    if (phoneNumber) {
      //encode a plus sign(+) for back-end can get +phoneNumber
      //without encodeURL +9090909 || with encodeURL %2090909
      query.phone_number = encodeURIComponent(phoneNumber)
    }

    if (entranceId) {
      query.entrance_id = entranceId
    }

    if (dateRange) {
      query.start_time = dateRange[0]?.format('YYYY-MM-DD')
      query.end_time = dateRange[1]?.format('YYYY-MM-DD')
    }

    query = Object.fromEntries(
      Object.entries(query).filter(([value]) => value !== undefined)
    )

    setLoading(true)

    if (type === 'export') {
      await EntryLogAPI.export(token, query)
    } else {
      await EntryLogAPI.list(token, query, sorter)
        .then((entries) => {
          setEntry(entries.data ?? [])
        })
        .catch((error) => {
          setEntry([])
          message.error(error.message)
        })
    }

    setLoading(false)
  }

  const SubmitButton = () => {
    if (dateDuration === null) {
      message.warning('Please fill in the time range before submit')
    } else {
      fetchEntryLogs(
        user?.accessToken,
        location,
        dateDuration,
        phoneNumber,
        entrance
      )
      setLocationSearchOptions([])
    }
  }

  const ExportButton = () => {
    if (dateDuration === null) {
      message.warning('Please fill in the time range before submit')
    } else {
      fetchEntryLogs(
        user?.accessToken,
        location,
        dateDuration,
        phoneNumber,
        entrance,
        undefined,
        'export'
      )
      setLocationSearchOptions([])
    }
  }

  const changeDateDuration = (newDateDuration: any) => {
    setDateDuration(newDateDuration)
  }
  const handleSelectLocation = (locationName: string) => {
    const theChosenOne = locationSearchOptions.find(
      (el) => el.name === locationName
    )
    if (theChosenOne) {
      setLocation(theChosenOne.id)
    }
  }

  useEffect(() => {
    const userObj = JSON.parse(localStorage.getItem("residentInfo") ?? "{}")
    console.log({ userObj })
    dispatch({ user: userObj, loggedIn: true })
    console.log({ user })
  }, [])

  useEffect(() => {
    // setEntrance(null)
    if (user) {
      fetchEntrances(user?.accessToken, '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, location])

  const handleSelectEntrance = (entranceName: string) => {
    const theChosenOne = entranceSearchOptions.find(
      (el) => el.name === entranceName
    )
    if (theChosenOne) {
      setEntrance(theChosenOne.id)
    }
  }

  const columns: ColumnsType<EntryLog> = [
    {
      width: '10%',
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
    },
    {
      width: '20%',
      title: 'Contact Number',
      key: 'phone_number',
      dataIndex: 'phone_number',
    },
    {
      width: '20%',
      title: 'Location Name',
      key: 'location_name',
      dataIndex: 'location_name',
    },
    {
      width: '20%',
      title: 'Check-in at',
      key: 'checkin_at',
      sorter: true,
      dataIndex: 'checkin_at',
      render: (value: any) => {
        return (
          value &&
          `${moment(value.timestamp).utc().format('DD/MM/YYYY HH:mm:ss')} [${value.entrance_name
          }]`
        )
      },
    },
    {
      width: '22.5%',
      title: 'Check-out at',
      key: 'checkout_at',
      sorter: true,
      dataIndex: 'checkout_at',
      render: (value: any) => {
        return (
          value &&
          `${moment(value.timestamp).utc().format('DD/MM/YYYY HH:mm:ss')} [${value.entrance_name
          }]`
        )
      },
    },
  ]

  const renderTitle = (name: string, address: string) => {
    return (
      <div>
        {name}
        <br />
        <small>
          <CaretRightFilled /> {address}
        </small>
      </div>
    )
  }

  return (
    <Row>
      <Col span={24}>
        <Card
          title={
            <Title level={2}>
              Entry Log
            </Title>
          }
        >
          <Row justify="space-between" style={{ marginBottom: '24px', gap: '8px 8px' }}>
            <Row style={{gap: '8px 8px'}}>
              <Col>
                <RangePicker
                  format="DD/MM/YYYY"
                  value={dateDuration}
                  onChange={changeDateDuration}
                />
              </Col>
              <Col>
                <Input.Search
                  placeholder="Search phone number"
                  onChange={(e) => {
                    setPhoneNumber(e.target.value)
                  }}
                />
              </Col>
              <Col>
                {
                  locationSearchOptions.length > 0 &&
                  <AutoComplete
                    className="autocomplete-search"
                    dropdownClassName="certain-category-search-dropdown"
                    dropdownMatchSelectWidth={500}
                    style={{ width: 250 }}
                    options={locationSearchOptions.map((el) => {
                      return {
                        value: el.name,
                        label: renderTitle(el.name, el.address),
                      }
                    })}
                    onSelect={(value) => {
                      handleSelectLocation(value)
                    }}
                  >
                    <Input.Search
                      placeholder="Search location"
                      onChange={(e) => {
                        searchLocation(e.target.value)
                        if (e.target.value === null || e.target.value === '') {
                          setLocation(null)
                        }
                      }}
                    />
                  </AutoComplete>
                }

              </Col>
              <Col>
                {
                  entranceSearchOptions.length > 0 &&
                  <AutoComplete
                    className="autocomplete-search"
                    dropdownClassName="certain-category-search-dropdown"
                    dropdownMatchSelectWidth={500}
                    style={{ width: 250 }}
                    value={
                      entranceSearchOptions.find((el) => el.id === entrance)
                        ?.name ?? ''
                    }
                    options={entranceSearchOptions.map((el) => {
                      return {
                        value: el.name,
                        label: el.name,
                      }
                    })}
                    disabled={!location || !entranceSearchOptions.length}
                    onSelect={(value) => {
                      handleSelectEntrance(value)
                    }}
                  >
                    <Input.Search
                      placeholder="Search entrance"
                      onChange={(e) => {
                        searchEntrance(e.target.value)
                        if (e.target.value === null || e.target.value === '') {
                          setEntrance(null)
                        }
                      }}
                    />
                  </AutoComplete>
                }
              </Col>
            </Row>
            <Row style={{gap: '8px'}}>
              <Col>
                <Button
                  className="btn-create"
                  type="primary"
                  onClick={SubmitButton}
                >
                  Submit
                </Button>
              </Col>
              <Col>
                <Button onClick={ExportButton}>
                  Export
                </Button>
              </Col>
            </Row>
          </Row>
          <Table
            loading={loading}
            className="location-table"
            columns={columns}
            dataSource={entry}
            title={() => `Total records found: ${entry ? entry.length : 'N/A'}`}
            onChange={(_, __, sorter: any) => {
              if (sorter.column) {
                let sort = sorter

                switch (sort.field) {
                  case 'checkin_at':
                  case 'checkout_at': {
                    sort.field = `[${sort.field}][timestamp]`
                    break
                  }
                  default: {
                    sort.field = `[${sort.field}]`
                    break
                  }
                }

                fetchEntryLogs(
                  user?.accessToken,
                  location,
                  dateDuration,
                  phoneNumber,
                  entrance,
                  sort
                )
              } else {
                fetchEntryLogs(
                  user?.accessToken,
                  location,
                  dateDuration,
                  phoneNumber,
                  entrance
                )
              }
            }}
            rowKey={(entry) => JSON.stringify(entry)}
          />
        </Card>
      </Col>
    </Row>
  )
}
