import React, { useState, useMemo } from 'react'

import { Modal, Button, Table, Switch, message, Tooltip } from 'antd'
import { UserModel } from '../../../../api/GetUsers'

import { ColumnsType } from 'antd/es/table'
import {
  // TablePaginationType,
  Assignment,
  AssignmentAPI,
} from 'api/Assignment'
import { Entrance } from 'api/Entrance'
import useAuthUser from 'hooks/useAuthUser'

export type AssignModalProps = {
  userList: Array<UserModel>
  visible: boolean
  onCancel: () => void
  accessToken: string
  entrance: Entrance
}

// const initPagination: TablePaginationType = {
//   pageSize: 10,
//   current: 1,
//   total: 0,
// };

export const AssignModal = ({
  userList,
  visible,
  accessToken,
  onCancel,
  entrance,
}: AssignModalProps) => {
  const [loading, setLoading] = useState(false)
  const [totalUsers, setTotalUsers] = useState<number>(0)
  const [assignment, setAssignment] = useState<Assignment[]>([])

  const fetchAssignment = async (token: string, areaId: string) => {
    setLoading(true)
    await AssignmentAPI.listGeUsersAssigned(token, 'entrance', parseInt(areaId))
      .then(({ data, total }: { data: any; total: number }) => {
        setAssignment(data)
        setTotalUsers(total)
      })
      .catch((err) => {
        message.error(err.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const updateAssignment = (
    token: string,
    areaId: string,
    assignees: string[]
  ) => {
    setLoading(true)
    AssignmentAPI.postGeUserAssign(token, {
      area_type: 'entrance',
      area_id: areaId,
      assignees,
    })
      .then((res) => {
        if (res.status) {
          message.success(res.message)
        } else {
          message.error(res.message)
        }
      })
      .catch((err) => {
        message.error(err.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useMemo(() => {
    if (accessToken) {
      fetchAssignment(accessToken, entrance?.id)
    }
  }, [entrance])

  const columns: ColumnsType<any> = [
    {
      title: 'Identity',
      key: 'identity',
      dataIndex: 'identity',
      render: (value = 'N/A') => (
        <Tooltip placement="topLeft" title={getUserNameDisplay(value)}>
          {getUserNameDisplay(value)}
        </Tooltip>
      ),
    },
    {
      title: 'Assigned',
      key: 'assigned',
      dataIndex: 'assigned',
      width: 40,
      align: 'center',
      render: (value, record) => (
        <Switch
          defaultChecked={value}
          onChange={(checked) => {
            const indexFound = assignment.findIndex(
              (e) => e.identity === record.identity
            )
            setAssignment([
              ...assignment.slice(0, indexFound),
              {
                identity: record.identity,
                assigned: checked,
              },
              ...assignment.slice(indexFound + 1),
            ])
          }}
        />
      ),
    },
  ]

  const onSaveAssignment = () => {
    const listAssignees = assignment
      .filter((e) => e.assigned === true)
      .map((e) => e.identity)

    updateAssignment(accessToken, entrance.id, listAssignees)
    onCancel()
  }

  const getUserNameDisplay = (value: string) => {
    if (userList !== ([] || null || undefined)) {
      const user = userList.filter((u) => value.includes(u.phone))[0]
      if (user !== (null || undefined)) {
        return user.name + ` (${user.nric.slice(0, 1) + user.nric.slice(1).replace(/.(?=....)/g, '*')}, ${value.slice(0, 1) + value.slice(1).replace(/.(?=....)/g, '*')})`
      } else {
        return value.slice(0, 1) + value.slice(1).replace(/.(?=....)/g, '*')
      }
    }
    return value.slice(0, 1) + value.slice(1).replace(/.(?=....)/g, '*')
  }

  return (
    <Modal
      className="assign-modal, location-modal"
      title="Assignment"
      centered
      visible={!loading && visible}
      onCancel={onCancel}
      maskClosable={false}
      width={750}
      destroyOnClose
      footer={[
        <Button key="back" className="btn-default" onClick={onCancel}>
          Close
        </Button>,
        <Button key="back" className="btn-create" onClick={onSaveAssignment}>
          Save
        </Button>,
      ]}
    >
      <Table
        rowKey={(record) => record.identity}
        columns={columns}
        loading={loading}
        dataSource={assignment}
        pagination={{
          total: totalUsers,
          pageSize: 10,
        }}
      />
    </Modal>
  )
}
