import React, { useEffect, useState } from 'react'

import iAlert from '../../assets/icons/i-alert.png'
import iAlertWarning from '../../assets/icons/i-alert-orange.png'

export enum AlertStatus {
    success,
    info,
    warning,
    error
}

type Props = {
    status: AlertStatus,
    message: string
}

export const AlertLabel = ({ status, message }: Props) => {

    const [backgroundColor, setBackgroundColor] = useState('');
    const [textColor, setTextColor] = useState('');
    const [icon, setIcon] = useState('');

    const getStyle = () => {
        switch (status) {
            case AlertStatus.info:
                setBackgroundColor('#027DA6');
                setTextColor('#FFFFFF');
                setIcon(iAlert);
                break;
            case AlertStatus.warning:
                setBackgroundColor('#FEE1CE');
                setTextColor('#B2441E');
                setIcon(iAlertWarning);
                break;
            case AlertStatus.success: // edit when there is more information
                setBackgroundColor('#FEE1CE');
                setTextColor('#B2441E');
                setIcon(iAlert);
                break;
            case AlertStatus.error: // edit when there is more information
                setBackgroundColor('#FEE1CE');
                setTextColor('#B2441E');
                setIcon(iAlertWarning);
                break;
        }
    };

    useEffect(() => {
        getStyle();
    });
  
  return (
    <div style={{ display: 'flex', borderRadius: 4, backgroundColor: backgroundColor, color: textColor, padding: 12 }}>
        <span><img style={{ width: 18, height: 18, objectFit: 'contain', alignSelf: 'flex-start' }} src={ icon } alt="alert" /></span>
        <span style={{ marginLeft: 10, flex: '1 1 auto' }}>{ message }</span>
    </div>

  )
}
