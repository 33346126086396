import { MESSAGES } from 'config'

const apiURL = process.env.REACT_APP_API_URL

export type LocationInfo = {
  id: string
  entranceID: string
  name: string
  swiftId: string
  env: string
}

export type CheckingInfo = {
  ic: number
  location_id: number
  entrance_id: number
  phone_number: string
  type: 'check-in' | 'check-out'
  name?: string
  reason?: string
  declarations?: any
}

export type EventType = {
  event_id: number
  location_id: number
  entrance_id: number
  encoded_key: string
}

export enum AnswerType {
  Boolean = 'boolean',
  Decimal = 'decimal',
  TextField = 'textfield',
  CheckBox = 'checkbox',
  SelectBox = 'selectbox',
}

export type CheckListQuestion = {
  question_id: number
  title: string
  description?: string
  answer_type: AnswerType
  answers?: Array<{ answer: string; answer_id: number }>
}

export const CheckingAPI = {
  async check(payload: CheckingInfo): Promise<any> {
    try {
      const res = await fetch(`${apiURL}/api/v1/entry-log`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'post',
        body: JSON.stringify(payload),
      })
      const { status, message, data } = await res.json()
      if (status === false) throw Error('')
      return { message, data }
    } catch (_) {
      throw Error(MESSAGES.checkError(payload.type))
    }
  },

  async checkLocation(locationID: number, entranceID: number): Promise<string> {
    try {
      const res = await Promise.all<{ status: boolean; data: any }>([
        fetch(`${apiURL}/api/v1/location/${locationID}`, {
          method: 'get',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }).then((res) => res.json()),
        fetch(`${apiURL}/api/v1/entrance/${entranceID}`, {
          method: 'get',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }).then((res) => res.json()),
      ])
      // check location and entrance is invalid
      if (res.every((item) => item.status) === false) {
        throw new Error('')
      }
      // check entrance in location
      const [location, entrance] = res
      if (location.data[0].id !== entrance.data[0].location_id) {
        throw new Error('')
      }
      return entrance.data[0]
    } catch (error) {
      throw Error(MESSAGES.invalidQRCode)
    }
  },

  async checkEvent(payload: EventType): Promise<any> {
    try {
      const res = await fetch(`${apiURL}/api/v1/event/validator`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'post',
        body: JSON.stringify(payload),
      })
      const { status, message, data } = await res.json()
      if (status === false) throw Error(message)
      return { message, data }
    } catch (err) {
      // @ts-ignore
      throw Error(err.message || 'Check event error!')
    }
  },

  async getQuestion(id: number): Promise<CheckListQuestion[]> {
    try {
      const res = await fetch(`${apiURL}/api/v1/declarations/${id}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      const { status, data } = await res.json()
      if (status === false) throw Error('')
      return data
    } catch (_) {
      throw Error(MESSAGES.default)
    }
  },

  async getEntrance(id: number): Promise<any> {
    const res = await fetch(`${apiURL}/api/v1/entrance/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    const { status, data, message } = await res.json()
    if (status === false) {
      return message
    } else {
      return data
    }
  },
}
