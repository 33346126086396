const apiURL = process.env.REACT_APP_API_URL;
const url = `${apiURL}/api/v2/addRoomsToEntryLog`;

export const addRoomsToEntryLogAPI = {
  async list( 
    entryLogId: string,
    entranceId: number,
    rooms: any[],
    ): Promise<any> {
    const resp = await fetch(`${url}`,
      {
        method: 'POST',
        body: JSON.stringify({
            "entryLogId": entryLogId,
            "entranceId": entranceId,
            "rooms": rooms,
        }),
        headers: {
                  'Content-Type': 'application/json',
                  'Content-Length': '0',
        },
      });
    const response = await resp.json();
    return response;
  },
};
