const apiURL = process.env.REACT_APP_API_URL;
const url = `${apiURL}/api/v2/ap/usercsvupload`;

interface CSVResponse {
    data: any,
    message: any,
    status: boolean
}

export const CsvAPI = {
    async validate(
        token: string,
        formData: any
      ): Promise<CSVResponse> {
      const resp = await fetch(`${url}/validate`,
      {
        credentials: 'include',
        headers: {
            Authorization: token,
            // do not need to put content type so boundary can be generated automatically
            // "content-type": "multipart/form-data"
        },
        method: 'POST',
        body: formData,
      })

      const response = await resp.json();
      
      return response;
    },

    async upload( 
        token: string,
        formData: any,
        csvChecked = false
        ): Promise<CSVResponse> {
        const resp = await fetch(`${url}/upload/:${csvChecked}`,
        {
            credentials: 'include',
            headers: {
                Authorization: token,
                // do not need to put content type so boundary can be generated automatically
                // "content-type": "multipart/form-data"
            },
            method: 'POST',
            body: formData,
          })
        
        const response = await resp.json();
        return response;
      },
  };