import { Modal, Form, Input, message, Button, Select, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import { AdminAPI } from 'api/AdminGe'
import { Location, LocationAPI } from 'api/Location'
import { Validator } from 'utils/validator'
import { YesNo } from 'config'

export type EditLocationModalProps = {
  location: Location
  visible: boolean
  roleName: string
  onOk: () => void
  onCancel: () => void
  accessToken: string
}
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

export const EditLocationModal = ({
  visible,
  roleName,
  onOk,
  onCancel,
  location,
  accessToken,
}: EditLocationModalProps) => {
  const [form] = Form.useForm()
  const [user, setUser] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (location !== null) {
      form.setFieldsValue({
        name: location.name,
        dr_email: location.dr_email,
        // is_medical: location.is_medical ? YesNo.YES : YesNo.NO,
        // address: location.address,
        // longitude: location.longitude,
        // latitude: location.latitude,
        owner: location.owner,
      })
      if (roleName === 'ge-super-admin') {
        fetchAdminList(accessToken)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const fetchAdminList = (token: string) => {
    AdminAPI.list(token)
      .then((res: any) => {
        setUser(res)
      })
      .catch((error) => {
        setLoading(false)
        message.error(error.message)
      })
  }

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const { name, dr_email, owner } = form.getFieldsValue()
      const editedLocation = {
        name,
        dr_email,
        owner: owner ?? 'N/A',
      }
      const hasError = await form
        .validateFields()
        .then(() => true)
        .catch(() => false)
      if (hasError === false) throw new Error('')

      await LocationAPI.update(
        accessToken,
        location.id,
        editedLocation as Location
      ).then((resp) => {
        if (resp && resp.status) {
          const text = (roleName === 'ge-super-admin') ? `Your location ${location.name} has been edited successfully.` : `Your request to update ${location.name} has been submitted for approval.`
          message.success(text)
      } else {
          message.error("There seems to be an issue at this moment. Try again.")
      }
      })
      onOk()
      form.resetFields()
      setLoading(false)
    } catch (error) {
      if (error.message) {
        message.error("There seems to be an issue at this moment. Try again.")
      }
      setLoading(false)
    }
  }
  return (
    <Modal
      title="Edit Location"
      className='location-modal'
      centered
      visible={visible}
      onOk={handleSubmit}
      onCancel={onCancel}
      confirmLoading={loading}
      maskClosable={false}
      cancelText={'Close'}
      footer={[
        <Button key="back" className="btn-default" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          loading={loading}
          key="submit"
          className="btn-create"
          onClick={handleSubmit}
        >
          Save
        </Button>,
      ]}
    >
      <Form form={form} {...layout} onFinish={handleSubmit}>
        <Form.Item
          name="name"
          label="Location Name"
          labelCol={{ span: 24 }}
          rules={[{ required: true, message: 'Name is required' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="dr_email"
          label="Admin Email"
          labelCol={{ span: 24 }}
          rules={[
            { required: true, message: 'Admin email is required' },
            {
              validator: (_, value) => {
                return !value.filter((e: string) => {
                  return (
                    e &&
                    (!Validator.isEmail(e) ||
                      !Validator.isBuildingAdminEmail(e))
                  )
                }).length
                  ? Promise.resolve()
                  : Promise.reject('You have entered an invalid email address.')
              },
            },
          ]}
        >
          <Select
            mode="tags"
            style={{ overflow: 'hidden ', width: '100%' }}
            placeholder="Building Admin Email"
            tokenSeparators={[',']}
            // dropdownStyle={{ display: 'none' }}
            open={false}
            value={location?.dr_email || 'N/A'}
            tagRender={({ value, closable, onClose }) => (
              <Tag
                color={
                  value &&
                  (!Validator.isEmail(value) ||
                    !Validator.isBuildingAdminEmail(value))
                    ? 'red'
                    : 'green'
                }
                closable={closable}
                onClose={onClose}
                style={{
                  marginRight: 3,
                  overflow: 'hidden',
                  // maxWidth: '100%',
                  width: '100%',
                }}
              >
                {value}
              </Tag>
            )}
          ></Select>
          {/* <Input type="email" className="border-round" /> */}
        </Form.Item>
        {(roleName === 'ge-super-admin') && 
        <Form.Item 
        name="owner" 
        label="Managed By"
        labelCol={{ span: 24 }}
        >
          <Select defaultValue={location?.owner || 'N/A'}>
            {user.length ? (
              user.map(({ identity }) => (
                <Select.Option key={identity} value={identity}>
                  {identity}
                </Select.Option>
              ))
            ) : (
              <Select.Option value={'N/A'}>{null}</Select.Option>
            )}
          </Select>
        </Form.Item>}
      </Form>
    </Modal>
  )
}
