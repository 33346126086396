import 'leaflet/dist/leaflet.css'

import { ExclamationCircleOutlined } from '@ant-design/icons'
import {
  Modal,
  Form,
  Input,
  message,
  Button,
  Select,
  Row,
  Col,
  Tag,
  // Typography,
} from 'antd'
import React, { useState } from 'react'
import { Location, LocationAPI } from 'api/Location'
import { YesNo } from 'config'
import { Validator } from 'utils/validator'
import { MapContainer, TileLayer, useMapEvents } from 'react-leaflet'

import { LatLng, LatLngBoundsExpression } from 'leaflet'
import DraggableMarker from 'components/onemap/Marker'

export type CreateLocationModalProps = {
  visible: boolean
  roleName: string
  onOk: () => void
  onCancel: () => void
  accessToken: string
}

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

export const CreateLocationModal = ({
  visible,
  roleName,
  onOk,
  onCancel,
  accessToken,
}: CreateLocationModalProps) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [formErrors, setFormErrors] = useState({
    latitude: true,
    longitude: true,
  })

  const onChangeFormErrors = (name: string, value: string) => {
    const isValid =
      name === 'latitude'
        ? Validator.isLatitude(value)
        : Validator.isLongitude(value)
    if (formErrors[name] !== !isValid) {
      setFormErrors({ ...formErrors, [name]: !isValid })
    }
  }

  const handleCreateLocation = async () => {
    setLoading(true)

    try {
      const location = form.getFieldsValue() as Location
      // location.is_medical =
      //   location.is_medical.toString() === YesNo.YES ? true : false

      // const lat: number = +location.latitude
      // const lng: number = +location.longitude

      // location.latitude = lat
      // location.longitude = lng

      await new Promise((rs) => {
        setTimeout(() => {
          rs(1)
        }, 2000)
      })
      await LocationAPI.create(
        accessToken,
        location as Location
      ).then((resp) => {
        if (resp && resp.status) {
          const text = (roleName === 'ge-super-admin') ? `Your location ${location.name} has been created successfully.` : `Your request to create ${location.name} has been submitted for approval.`
          message.success(text)
        } else {
          message.error("There seems to be an issue at this moment. Try again.")
        }
      })
      onOk()
      form.resetFields()
    } catch (error) {
      if (error.message) {
        message.error("There seems to be an issue at this moment. Try again.")
      }
    }
    setLoading(false)
  }

  const confirmPopup = async () => {
    const isValidForm = await form
      .validateFields()
      .then(() => true)
      .catch(() => false)
    if (!isValidForm) return
    Modal.confirm({
      title: 'Confirmation',
      icon: <ExclamationCircleOutlined />,
      centered: true,
      width: 600,
      content: (
        <span>
          You cannot edit address, latitude and longitude after creating this
          location. <br /> Do you want to continue?
        </span>
      ),
      onOk() {
        handleCreateLocation()
      },
      onCancel() {},
    })
  }

  const bounds: LatLngBoundsExpression = [
    [1.48249, 103.6],
    [1.1425, 104.1555405],
  ]

  function LocationMarker() {
    const [position, setPosition] = useState<LatLng>()

    const map = useMapEvents({
      // click: (e) => {
      //   console.log(e);
      // },
      dblclick: async (e) => {
        setPosition(e.latlng)
        const searchRes = await LocationAPI.pin(
          accessToken,
          e.latlng.lat,
          e.latlng.lng
        )

        const { data, message: mess } = searchRes

        if (data) {
          form.setFieldsValue({
            name: `${
              data.BUILDINGNAME === 'null' ||
              typeof data.BUILDINGNAME === 'undefined'
                ? ''
                : data.BUILDINGNAME
            }`,
            address: `${typeof data.BLOCK !== 'undefined' ? data.BLOCK : ''} ${
              data.ROAD !== 'null' ? data.ROAD : ''
            } ${typeof data.POSTALCODE !== 'undefined' ? data.POSTALCODE : ''}`,
            latitude: data.LATITUDE,
            longitude: data.LONGITUDE,
          })
        } else {
          message.error(mess)
          form.setFieldsValue({
            name: '',
            address: '',
            latitude: e.latlng.lat,
            longitude: e.latlng.lng,
          })
        }
      },
      drag: () => {
        map.panInsideBounds(bounds, { animate: false })
      },
    })

    return position === undefined ? null : <DraggableMarker {...position} />
  }

  return (
    <Modal
      title="Create New Location"
      centered
      className='location-modal'
      visible={visible}
      onOk={handleCreateLocation}
      // onOk={confirmPopup}
      onCancel={onCancel}
      confirmLoading={loading}
      maskClosable={false}
      footer={[
        <Button key="back" className="btn-default" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          loading={loading}
          key="submit"
          className="btn-create"
          onClick={handleCreateLocation}
          // onClick={confirmPopup}
        >
          Submit
        </Button>,
      ]}
    >
          <Form
            form={form}
            {...layout}
            onFinish={confirmPopup}
          >
            <Form.Item
              name="name"
              label="Location Name"
              labelCol={{ span: 24 }}
              rules={[{ required: true, message: 'Name is required' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="dr_email"
              labelCol={{ span: 24 }}
              style={{
                overflow: 'hidden ',
              }}
              label="Admin Email"
              rules={[
                { required: true, message: 'Admin email is required' },
                {
                  validator: (_, value) => {
                    return !value.filter((e: string) => {
                      return (
                        e &&
                        (!Validator.isEmail(e) ||
                          !Validator.isBuildingAdminEmail(e))
                      )
                    }).length
                      ? Promise.resolve()
                      : Promise.reject('You have entered an invalid email address.')
                  },
                },
              ]}
            >
              <Select
                ref={null}
                mode="tags"
                style={{ width: '100%' }}
                placeholder="Admin Email"
                tokenSeparators={[',']}
                // dropdownStyle={{ display: 'none' }}
                open={false}
                tagRender={({ value, closable, onClose }) => (
                  <Tag
                    color={
                      value &&
                      (!Validator.isEmail(value) ||
                        !Validator.isBuildingAdminEmail(value))
                        ? 'red'
                        : 'green'
                    }
                    closable={closable}
                    onClose={onClose}
                    style={{
                      marginRight: 3,
                      overflow: 'hidden',
                      // maxWidth: '100%',
                      width: '100%',
                    }}
                    className="btn-tag"
                  >
                    {value}
                  </Tag>
                )}
              ></Select>
              {/* <Input type="email" className="border-round" /> */}
            </Form.Item>
          </Form>
    </Modal>
  )
}
