import React, { useEffect, useState } from 'react'

// pages
import ErrorPage from 'pages/AdminPages/ErrorPage'
import { Layout } from 'antd'
import { CircularProgress } from '@mui/material'


export default function Auth(props: any) {

const {dispatch, role} = props
  const [delayShowErrorPage, setDelayShowErrorPage] = useState(false)
  useEffect(()=>{
      if(role === null){
        setTimeout(()=>{
          setDelayShowErrorPage(true)
        }, 1000)
      }
  }, [role])
  // const role = {name: ROLES.GE_ADMIN.name, id: 8};
  //const role = {name: ROLES.GE_ADMIN.name};
  if (
    // (role === null || typeof role === 'undefined')||
    (role === null) ||
    (role && role.status === 'failure' && role.reason === 'No roles!') ||
    //@ts-ignore
    (role && role.status === false && ((role.message === 'Unauthorized') || (role.message === "Invalid accessToken"))) ||
    // invalid accessToken
    (role && role.name === null)
  ) {
    return (delayShowErrorPage || role !== null) ? 
    <ErrorPage authDispatch={dispatch} />:
    <Layout style={{ minHeight: '100vh', display: 'flex', alignItems: 'center', flexGrow: 1, justifyContent: 'center' }}><CircularProgress /></Layout>
  }

  const { children } = props
  return children
}
