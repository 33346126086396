import React, { useState, useEffect } from 'react'
import { Modal, Form, Input, message, Button } from 'antd'

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

export type CreateAnswerModalProps = {
  visible: boolean
  onOk: (payload: any) => void
  onCancel: () => void
}

export const CreateAnswerModal = ({
  visible,
  onOk: createAnswer,
  onCancel,
}: CreateAnswerModalProps) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    try {
      setLoading(true)
      await form.validateFields()
      const payload = form.getFieldsValue()
      createAnswer({
        ...payload,
        answer: payload.answer.trim(),
      })
      onCancel()
    } catch (error) {
      if (error.message) {
        message.error(error.message)
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    form.resetFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  return (
    <Modal
      title="Create Answer"
      centered
      visible={visible}
      onOk={handleSubmit}
      onCancel={onCancel}
      confirmLoading={loading}
      maskClosable={false}
      cancelText={'Close'}
      destroyOnClose
      footer={[
        <Button key="back" className="btn-default" onClick={onCancel}>
          Close
        </Button>,
        <Button
          loading={loading}
          htmlType="submit"
          className="btn-edit"
          onClick={handleSubmit}
        >
          Ok
        </Button>,
      ]}
    >
      <Form {...layout} form={form} onFinish={handleSubmit}>
        <Form.Item
          name="answer"
          label="Answer"
          rules={[
            { required: true, whitespace: true, message: 'Answer is required' },
          ]}
        >
          <Input className="border-round" />
        </Form.Item>
      </Form>
    </Modal>
  )
}
