import { useEffect, useReducer } from "react";

// import firebase from "libs/firebase";
// import auth from "api/Auth";

interface InitialState {
  user?: object | null;
  loggedIn?: boolean | null;
}

type ReducerFunction = (state: any, action: any) => any;

// state reducer
const initialState: InitialState = {
  user: null,
  loggedIn: null,
};

function reducer(state: any, action: any): any {
  return {
    ...state,
    ...action,
  };
}

function onAuthStateChange(callback: Function) {
  // return firebase.auth().onAuthStateChanged(async (user) => {
  //   if (user) {
  //     const { email, phoneNumber } = user;
  //     const accessToken = (await user.getIdTokenResult(true)).token;
  //     callback({
  //       user: {
  //         email,
  //         phoneNumber,
  //         accessToken,
  //       },
  //       loggedIn: true,
  //     });
  //   } else {
  //     callback({ loggedIn: false });
  //   }
  // });

  const userString = localStorage.getItem("residentInfo")
  if (userString) {
    const user = JSON.parse(userString)
    if (user) {
      const { name, nric, phone, accessToken } = user
      callback({
        user: {
          name,
          nric,
          phone,
          accessToken
        },
        loggedIn: true,
      })
    } else {
      localStorage.removeItem("residentInfo")
      callback({ loggedIn: false })
    }
  } else {
    localStorage.removeItem("residentInfo")
    callback({ loggedIn: false })
  }

}

function useAuthUser() {
  const [authState, dispatch] = useReducer<ReducerFunction, InitialState>(
    reducer,
    initialState,
    (o) => o
  );
  useEffect(() => {
    // const unsubscribe = onAuthStateChange(dispatch);
    // return () => {
    //   unsubscribe();
    // };
    onAuthStateChange(dispatch)
  }, [localStorage.getItem("residentInfo")]);
  return [authState.user, authState.loggedIn, dispatch];
}

export default useAuthUser;
