// constant
import { ROLES } from '../config'

// page
import { ManageLocation } from '../pages/AdminPages/ManageLocations'
import { ContactTracing } from '../pages/AdminPages/ContactTracing'
import { UserManagement } from '../pages/AdminPages/UserManagement'
import { Questionnaire } from '../pages/AdminPages/Questionnaire'
import { EventManagement } from '../pages/AdminPages/EventManagement'
import { RoomAccessManagement } from '../pages/AdminPages/RoomAccessManagement'
// import { AttendeeManagement } from '../pages/AdminPages/AttendeeManagement'

// icon
import { ReactComponent as TracingToolIcon } from '../assets/icons/tracing_tool.svg' //contact tracing
// import { ReactComponent as UserManagementIcon } from '../assets/icons/user_management.svg' // user roles
// import { ReactComponent as DeclarationIcon } from '../assets/icons/declaration.svg' // questionaire
// import { ReactComponent as GroupLocation } from '../assets/icons/location.svg' // location management
// import { ReactComponent as EventIcon } from '../assets/icons/event-2.svg' //event management
// v2
import { ReactComponent as  GroupLocation } from '../assets/icons/v2/Location.svg'
import { ReactComponent as UserManagementIcon } from '../assets/icons/v2/User_Roles.svg'
import { ReactComponent as DeclarationIcon } from '../assets/icons/v2/Questionnaire.svg'
import { ReactComponent as EventIcon } from '../assets/icons/v2/Events.svg'
import { ReactComponent as RoomAccessIcon } from '../assets/icons/v2/Room_Access.svg'
import { ReactComponent as EntryLogIcon} from '../assets/icons/v2/Entry.svg'

// import { ReactComponent as AttendeeIcon } from '../assets/icons/group.svg'

export enum RoutePaths {
  // Public pages
  INDEX = '/',
  MAIN = '/tenant/:id',
  ENROL = '/enrol',
  DECLARATION = '/declaration',
  CHECK_IN = '/check-in',
  CHECK_OUT = '/check-out',
  COMPLETE = '/complete',
  TERMS_OF_USER = '/terms-of-use',
  QUESTIONNAIRE = '/questionnaire',
  EVENT = '/event/:code/:encodedKey',
  EVENT_COMPLETE = '/event-completed',
  // Admin pages
  ADMIN = '/admin',
  ADMIN_USERS = '/admin/user',
  ADMIN_LOGIN = '/admin/login',
  ADMIN_LOCATION = '/admin/location',
  ADMIN_CONTACT_TRACING = '/admin/contact-tracing',
  ADMIN_SESSION_EXPIRE = '/admin/session-expire',
  ADMIN_QUESTIONNAIRE = '/admin/questionnaire',
  ADMIN_EVENT = '/admin/event',
  ADMIN_ATTENDEE = '/admin/attendee',
  ADMIN_ROOM_ACCESS = '/admin/room-access-management',
  // AP pages
  AP_LANDING = '/ap',
  AP_VISITORS = '/ap/visitors',
  AP_LOGIN = '/ap/login',
  AP_AUTH_CALLBACK = '/ap/callback',
}

export interface NavigationItem {
  roles?: string[]
  exact?: boolean
  key: string
  icon?: any
  page?: any
  text: string
  permitted?: boolean
}

const navigationList: NavigationItem[] = [
  {
    roles: [ROLES.GE_SUPER_ADMIN.name, ROLES.GE_ADMIN.name],
    key: RoutePaths.ADMIN_LOCATION,
    icon: GroupLocation,
    page: ManageLocation,
    text: 'Location Management',
  },
  {
    roles: [ROLES.GE_ADMIN.name],
    key: RoutePaths.ADMIN_CONTACT_TRACING,
    icon: EntryLogIcon,
    page: ContactTracing,
    text: 'Entry Log',
  },
  {
    roles: [ROLES.GE_SUPER_ADMIN.name],
    key: RoutePaths.ADMIN_USERS,
    icon: UserManagementIcon,
    page: UserManagement,
    text: 'User Roles',
  },
  {
    roles: [ROLES.GE_SUPER_ADMIN.name],
    key: RoutePaths.ADMIN_QUESTIONNAIRE,
    icon: DeclarationIcon,
    page: Questionnaire,
    text: 'Questionnaire',
  },
  /* removing event management feature due to security vulnerability and the feature is currently not being used */
  // {
  //   roles: [ROLES.GE_SUPER_ADMIN.name, ROLES.GE_ADMIN.name],
  //   key: RoutePaths.ADMIN_EVENT,
  //   icon: EventIcon,
  //   page: EventManagement,
  //   text: 'Event Management',
  // },
  {
    roles: [ROLES.GE_SUPER_ADMIN.name, ROLES.GE_ADMIN.name],
    key: RoutePaths.ADMIN_ROOM_ACCESS,
    icon: RoomAccessIcon,
    page: RoomAccessManagement,
    text: 'Room Access Management',
  },
  // {
  //   roles: [ROLES.GE_SUPER_ADMIN.name],
  //   key: RoutePaths.ADMIN_ATTENDEE,
  //   icon: AttendeeIcon,
  //   page: AttendeeManagement,
  //   text: 'Attendee Management',
  // },
]

export default function useNavigation(role: any): NavigationItem[] {
  const roleName = role?.name ?? null

  return navigationList.map((nav: NavigationItem) => ({
    ...nav,
    permitted: !nav?.roles || nav?.roles.indexOf(roleName) > -1,
  }))
}
