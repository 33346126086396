/* eslint-disable jsx-a11y/accessible-emoji */

// TODO: move under sgid component

import React, { useEffect, useState } from 'react'
import { Redirect } from "react-router-dom";
import { useRouter } from 'hooks/useRouter'

// components
import { Box, CircularProgress } from '@mui/material'

import { accessToken } from '../../../api/AP/getAccessToken'
import { userInfo } from '../../../api/AP/getUserInfo'
import { RoutePaths } from '../../../hooks/useNavigation';

import { useLocationParams } from 'contexts/LocationParams'
import { AuthType } from '../../PublicPages/DeclarationPage';
// import auth from '../../../api/Auth';


export enum AuthRequestType {
    apLogin, visitorLogin, adminLogin
}

function AuthCallback({ authDispatch }) {
    const router = useRouter()
    const locationParams = useLocationParams()

    const [tokenResponse, setTokenResponse] = useState();
    const [user, setUser] = useState()

    const [forwardPath, setForwardPath] = useState("")
    const [backwardPath, setBackwardPath] = useState("")
    const [itemToStore, setItemToStore] = useState("")
    const [authRequestType, setAuthRequestType] = useState("")

    const handleRedirectData = () => {
        setAuthRequestType(localStorage.getItem("authRequestType") ?? "")
        switch (authRequestType) {
            case AuthRequestType.apLogin.toString():
                setBackwardPath(RoutePaths.AP_LOGIN)
                setForwardPath(RoutePaths.AP_VISITORS)
                setItemToStore("apAuthenticated")
                break
            case AuthRequestType.visitorLogin.toString():
                setBackwardPath(RoutePaths.MAIN)
                setForwardPath(RoutePaths.DECLARATION)
                setItemToStore("visitorAuthenticated")
                break
            case AuthRequestType.adminLogin.toString():
                setBackwardPath(RoutePaths.ADMIN_LOGIN)
                setForwardPath(RoutePaths.ADMIN)
                setItemToStore("adminAuthenticated")
                break
            default:
                break
        }
    }


    useEffect(() => {
        if (!itemToStore) {
            handleRedirectData()
        }
        if (tokenResponse && user) {
            localStorage.setItem(itemToStore, user);
            // remove because it is interfering with existing redirect in code block
            // switch(authRequestType){
            //     case AuthRequestType.visitorLogin.toString():
            //         console.log("tokenResponse Updated visitorLogin", authRequestType)
            //         router.push(RoutePaths.DECLARATION, { canGoBack: true, type:RoutePaths.CHECK_IN, authType: AuthType.sgIdAuth })

            //         break
            //     case AuthRequestType.adminLogin.toString():
            //         console.log("tokenResponse Updated adminLogin", authRequestType)
            //         router.push(RoutePaths.ADMIN, { canGoBack: true, type:RoutePaths.ADMIN_LOGIN})
            //         break
            // }
        }
    }, [tokenResponse, user])

    useEffect(() => {
        handleRedirectData()

        // get code from redirect
        const { href } = window.location;
        const url = new URL(href);
        const searchParams = new URLSearchParams(url.search);

        const code = searchParams.get('code');
        const { REACT_APP_AP_REDIRECT_URL } = process.env
        if (!code) {
            // if code does not exist redirect to login
            window.location.replace(backwardPath)
        }
        if (!REACT_APP_AP_REDIRECT_URL) {
            return console.error("REACT_APP_AP_REDIRECT_URL not defined!")
        }

        // exchange code for access_token and redirect to page on success
        accessToken.post({ code })
            .then((body) => {
                if (!body.accessToken) {
                    localStorage.clear()
                    authDispatch({ loggedIn: false })
                    router.push(RoutePaths.ADMIN_LOGIN, { canGoBack: false, type: RoutePaths.ADMIN_LOGIN })
                }
                const { accessToken } = body
                setTokenResponse(body)
                userInfo.post(accessToken)
                    .then(response => {
                        setUser(response["myinfo.mobile_number"]);
                        const userObj = {
                            name: response["myinfo.name"],
                            phone: response["myinfo.mobile_number"],
                            nric: response["myinfo.nric_number"]
                        }
                        localStorage.setItem("residentInfo", JSON.stringify({ ...userObj, accessToken }))
                        authDispatch({ user: userObj, loggedIn: true, })
                    })

            })

    }, [])

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
            flexDirection: 'column',
            width: '100vw',
        }}>

            {(tokenResponse && user) && (
                (authRequestType === AuthRequestType.apLogin.toString() && <Redirect to={{ pathname: '/ap/visitors', state: { authType: AuthType.sgIdAuth } }} />) ||
                (authRequestType === AuthRequestType.visitorLogin.toString() && <Redirect to={{ pathname: '/declaration', state: { authType: AuthType.sgIdAuth } }} />) ||
                (authRequestType === AuthRequestType.adminLogin.toString() && <Redirect to={{ pathname: '/admin', state: { authType: AuthType.sgIdAuth } }} />)
            ) || <CircularProgress />}


        </Box>
    )
}

export default AuthCallback
