import { Modal, Form, Input, message, Button, Select, Tag, AutoComplete } from 'antd'
import React, { useEffect, useState } from 'react'
import { IRoomGroupDetails } from 'api/AP/RoomAccessManagement'
import { UserAPI, IUserAPIV2 } from 'api/User'

import '../style.less'

//To Do:
  /*
     1) Which user already added to group
  */

export type ManageUsersModalProps = {
  visible: boolean
  onOk: () => void
  onCancel: () => void
  accessToken: string
  groupInfo: IRoomGroupDetails
}
interface IUserSelectOption {
  value: number
  label: string
}
interface IUserState {
  userId: number
  groupId: number
}

const layout = {
  labelCol: { span: '100%' },
  wrapperCol: { span: '100%' },
}

export const EditUsersModal = ({
  visible,
  onOk,
  onCancel,
  accessToken,
  groupInfo
}: ManageUsersModalProps) => {
  const [form] = Form.useForm()
  const { groupId, groupName } = groupInfo;
  const [userOptionsForSelect, setUserOptionsForSelect] = useState<IUserSelectOption[]>([])
  const [usersSelected, setUsersSelected] = useState<number []>([]); 
  const [loading, setLoading] = useState(false)

  const fetchUserList = (token: string) => {
    UserAPI.listV2(token)
      .then((items: IUserAPIV2 []) => {
        let userItems = items.map((item: IUserAPIV2)=> ({
          value: item.userId,
          label: item.name + ' ' + `${'(' + item.nric.slice(0, 1) + item.nric.slice(1).replace(/.(?=....)/g, '*')+', '+ item.phone.slice(0, 1) + item.phone.slice(1).replace(/.(?=....)/g, '*') + ')'}`,
        }))
        setUserOptionsForSelect(userItems)
      })
      .catch((error) => {
        setLoading(false)
        message.error(error.message)
      })
  }

  const fetchUserAlreadyInGroup = (token: string, groupId: number)=> {
    UserAPI.listV2Existing(token, groupId)
    .then((items: IUserAPIV2 []) => {
        if(items.length > 0){
        let userItems = items.map((item: IUserAPIV2)=> (
          item.userId ))
        console.log("setting ", userItems)
        setUsersSelected(userItems)
        form.setFieldsValue({'users_select': userItems});
      }
    })
    .catch((error) => {
      setLoading(false)
      message.error(error.message)
    })
  }

  // const wrapPromise = (accessToken:string)=>{
  //   return (item: IUserState)=> {
  //     return UserAPI.addUserToGroup(accessToken, item)
  //   }
  // }

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const hasError = await form
        .validateFields()
        .then(() => true)
        .catch(() => false)
      if (hasError === false) throw new Error('')
      console.log("users sending are ",  {users: usersSelected, groupId})
      UserAPI.addUserToGroup(accessToken, {users: usersSelected, groupId})
      setLoading(false)
      onOk();
      message.success("User(s) have been updated successfully.")
    } catch (error) {
      if (error.message) {
        message.error(error.message)
      }
      setLoading(false)
    }
  }

  const handleUserSelect = (value: number)=>{
      console.log('user id selected is ', value);
      setUsersSelected([...usersSelected, value])
  }

  const handleUserDeSelect = (value: number)=>{
    let indexToRemove = usersSelected.findIndex((userId)=> userId === value)
    console.log('user id de-selected is ', value, indexToRemove);
    let copy =[...usersSelected]
    copy.splice(indexToRemove, 1)
    setUsersSelected(copy);
  }

  const tagRender = (props: any) => {
    const { label, onClose } = props;
    let shortLabel = label.split('(')[0].trim();
      return (
        <Tag
        color={'#2C4B9C'}
        closable={true}
        onClose={onClose}
        style={{ marginRight: 3 }}
        >
          {shortLabel}
        </Tag>
      );  
  };

  useEffect(()=> {
    fetchUserList(accessToken);
    fetchUserAlreadyInGroup(accessToken, groupId)
  }, [])

  return (
    <Modal
      title="Manage Users"
      width='35%'
      style={{marginLeft: 'auto', marginRight: 'auto', top: '10%'}}
      visible={visible}
      onOk={handleSubmit}
      onCancel={onCancel}
      confirmLoading={loading}
      maskClosable={false}
      footer={[
        <Button key="back" className="btn-cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          loading={loading}
          key="submit"
          className="btn-save"
          onClick={handleSubmit}
        >
          Save
        </Button>,
      ]}
    >
      <Form form={form} {...layout} onFinish={handleSubmit}>
        <Form.Item
          label="User(s)"
          colon={false}
          required={false}
        > 
        </Form.Item>
       <Form.Item
          name="users_select"
          colon={false}
          // NOTE: setting value in Select has no effect when its an form.item
          // rules={[{ required: true, message: 'User(s) is required' }]}
        >
          <Select
            mode='multiple'
            showSearch
            showArrow={true}
            tagRender={tagRender}
            placeholder={`Select user(s) for group ${groupName}`}  
            optionFilterProp="label"
            onSelect={(_, record) => handleUserSelect(record.value)}
            onDeselect={(_, record) => handleUserDeSelect(record.value)}
            filterOption={(input, option) =>
              (option?.label as string ?? "").toLowerCase().includes(input.toLowerCase())
           } 
          options={userOptionsForSelect}>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}
