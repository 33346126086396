const apiURL = process.env.REACT_APP_API_URL;
const url = `${apiURL}/api/v1/auth`;

export interface Role {
  id: string;
  name: string;
}

const auth = {
  async getRole(token: string): Promise<Role> {
    const res = await fetch(`${url}/role`,
      {
        credentials: 'include',
        headers: {
          "Authorization": token
        }
      });
    const rep = await res.json();
    // returning {role: { id: 1, name: "ge-admin"}}
    if (rep && rep.status) {
      const { data } = rep;
      return data;
    }
    return rep;
  },
};

export default auth;
