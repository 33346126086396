import React, { useState, useEffect } from 'react'
import { Modal, Form, Input, message, Button, Select } from 'antd'

import { AnswerType } from 'api/Main'
import { NegativeConditionsComponent } from 'components/NegativeConditionsComponent'

const { Option } = Select

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

type ConditionType = {
  operator?: string
  value?: string
}

enum ALLOW_OTHERS_OPTION {
  // CheckBox = 'checkbox',
  SelectBox = 'selectbox',
}

export type EditQuestionModalProps = {
  visible: boolean
  onOk: (id: number, payload: any) => void
  onCancel: () => void
  question: any
}

export const EditQuestionModal = ({
  visible,
  onOk: updateQuestion,
  onCancel,
  question,
}: EditQuestionModalProps) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [answerType, setAnswerType] = useState<string>('')
  const [conditions, setConditions] = useState<ConditionType[]>([])

  useEffect(() => {
    if (question) {
      form.setFieldsValue({ ...question })
      setAnswerType(question.answer_type)
      setConditions(question.negative_conditions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question])

  const handleSubmit = async () => {
    try {
      setLoading(true)
      const values = await form.validateFields()

      form.setFieldsValue({
        ...values,
        negative_conditions: conditions ? [...conditions] : [],
      })

      const payload = form.getFieldsValue()
      updateQuestion(question.id, {
        ...payload,
        title: payload.title.trim(),
        // negative_conditions: payload.negative_conditions.filter(
        //   (e: any) => e.value && e.value !== ''
        // ),
      })

      onCancel()
    } catch (error) {
      if (error.message) {
        message.error(error.message)
      }
    } finally {
      setLoading(false)
    }
  }

  const updateStateCondition = (index: number, newValue: ConditionType) => {
    setConditions((prevState = []) => {
      try {
        const condition = prevState[index]
        const clone = prevState.slice()
        clone.splice(index, 1, {
          ...condition,
          ...newValue,
        })
        return clone
      } catch (error) {
        message.error(error)
      }
    })
  }

  return (
    <Modal
      title="Edit Question"
      centered
      width={650}
      visible={visible}
      onOk={handleSubmit}
      onCancel={onCancel}
      confirmLoading={loading}
      maskClosable={false}
      cancelText={'Close'}
      destroyOnClose
      footer={[
        <Button key="back" className="btn-default" onClick={onCancel}>
          Close
        </Button>,
        <Button
          loading={loading}
          key="submit"
          className="btn-edit"
          onClick={handleSubmit}
        >
          Ok
        </Button>,
      ]}
    >
      <Form
        {...layout}
        form={form}
        onFinish={handleSubmit}
        initialValues={{
          ...question,
          answer_type: question?.answer_type.toLowerCase(),
        }}
      >
        <Form.Item
          name="title"
          label="Title"
          // initialValue={question?.title}
          rules={[{ required: true, message: 'Title is required' }]}
        >
          <Input className="border-round" />
        </Form.Item>
        <Form.Item name="description" label="Description" rules={[]}>
          <Input.TextArea className="border-round" />
        </Form.Item>
        <Form.Item
          name="answer_type"
          label="Answer Type"
          // initialValue={question?.answer_type.toLowerCase()}
          rules={[
            {
              required: true,
              message: 'Answer type is required',
            },
          ]}
        >
          <Select
            style={{ width: '100%' }}
            placeholder="Please select answer type"
            onChange={(value) => {
              form.setFieldsValue({ answer_type: value })
              setAnswerType(value as string)
              setConditions([])
            }}
          >
            {Object.values(AnswerType).map((type: AnswerType) => {
              return (
                <Option value={type.toLowerCase()}>
                  {`${type[0].toUpperCase()}${type.slice(1).toLowerCase()}`}
                </Option>
              )
            })}
          </Select>
        </Form.Item>
        {Object.values(ALLOW_OTHERS_OPTION).includes(
          answerType.toLowerCase() as ALLOW_OTHERS_OPTION
        ) && (
          <Form.Item name="others_text" label="'Others' field name" rules={[]}>
            <Input className="border-round" />
          </Form.Item>
        )}
        <NegativeConditionsComponent
          answerType={answerType}
          updateStateCondition={updateStateCondition}
          conditions={conditions}
          setConditions={setConditions}
        />
      </Form>
    </Modal>
  )
}
