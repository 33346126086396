import React, { Fragment } from 'react'
import { Form, Input, Button, Select, Row, Space, Radio } from 'antd'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'

import { AnswerType } from 'api/Main'

const { Option } = Select

export const initConditions = {
  operator: '==',
  value: '',
}

export const NegativeConditionsComponent = ({
  answerType,
  updateStateCondition,
  conditions,
  setConditions,
  existedAnswers,
}: {
  answerType: any
  updateStateCondition: any
  conditions: any
  setConditions: any
  existedAnswers: any[]
}) => {
  switch (answerType) {
    case AnswerType.Boolean: {
      return (
        <Fragment>
          <Form.Item name="negative_conditions" label="Negative Condition">
            <div>
              <Radio.Group
                onChange={(e) => {
                  updateStateCondition(0, {
                    operator: '==',
                    value: e.target.value,
                  })
                }}
              >
                <Radio.Button value={true}>Yes</Radio.Button>
                <Radio.Button value={false}>No</Radio.Button>
                <Radio.Button value={null}>Not both</Radio.Button>
              </Radio.Group>
            </div>
          </Form.Item>
        </Fragment>
      )
    }
    case AnswerType.Decimal: {
      return (
        <Fragment>
          <Form.Item
            name="negative_conditions"
            label="Negative Conditions"
            style={{ marginBottom: '10px' }}
          >
            <Row justify="end">
              <Space>
                {conditions && !!conditions.length && (
                  <Button
                    className="btn-delete"
                    style={{ padding: '0 8px' }}
                    onMouseUp={() => {
                      setConditions([
                        ...conditions.slice(0, conditions.length - 1),
                      ])
                    }}
                  >
                    <MinusOutlined />
                  </Button>
                )}
                <Button
                  className="btn-edit"
                  style={{ padding: '0 8px' }}
                  onMouseUp={() => {
                    conditions
                      ? setConditions([...conditions, initConditions])
                      : setConditions([initConditions])
                  }}
                >
                  <PlusOutlined />
                </Button>
              </Space>
            </Row>
          </Form.Item>
          {conditions && Array.isArray(conditions)
            ? conditions.map((value: any, index: number) => {
                return (
                  <Input.Group
                    key={index}
                    compact
                    className="question-conditions-group"
                  >
                    <div className="ant-input-group-addon">{index + 1}</div>
                    <Select
                      defaultValue="=="
                      onChange={(value) => {
                        updateStateCondition(index, {
                          operator: value,
                        })
                      }}
                    >
                      <Option value="==">Equal</Option>
                      <Option value=">">Greater Than</Option>
                      <Option value=">=">Greater Than Equal</Option>
                      <Option value="<">Less Than</Option>
                      <Option value="<=">Less Than Equal</Option>
                    </Select>
                    <Input
                      type="tel"
                      value={value.value}
                      onChange={(e) => {
                        updateStateCondition(index, {
                          value: e.target.value,
                        })
                      }}
                    />
                  </Input.Group>
                )
              })
            : null}
          <small>
            * Empty conditions are automatically cleared upon submission
          </small>
        </Fragment>
      )
    }
    // case AnswerType.CheckBox: {
    //   return (
    //     <Fragment>
    //       <Form.Item name="negative_conditions" label="Negative Conditions">
    //         <Checkbox.Group
    //           className="checkbox-list"
    //           style={{ width: '100%' }}
    //           onChange={(checked: boolean, value: string) => {
    //             console.log(checked, value)
    //           }}
    //         >
    //           <Row>
    //             {existedAnswers &&
    //               existedAnswers.map((el, index) => (
    //                 <Col span={24} key={index}>
    //                   <Checkbox
    //                     className="checkbox-list__item"
    //                     value={el.answer}
    //                   >
    //                     {el.answer}
    //                   </Checkbox>
    //                 </Col>
    //               ))}
    //           </Row>
    //         </Checkbox.Group>
    //       </Form.Item>
    //     </Fragment>
    //   )
    // }
    default:
      return null
  }
}
