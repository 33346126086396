//// CODE RELATED TO REASON WAS COMMENTED SINCE IN VER2, REASON IS MANADATORY AND IS PART OF THE DECLARATION FORM REGARDLESS OF THE ENTRANCE'S DECLARATION CHECKLIST

import React, { useEffect, useState } from 'react'

import { Form, Input, Select, Tag } from 'antd'
import { FormInstance, Rule, FormItemProps } from 'antd/lib/form'
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';

import { AuthType } from '../../pages/PublicPages/DeclarationPage';


export enum FormItemType {
  textInput, textAreaInput, dropdownSelect, dropdownMultiSelect, dropdownSelectWithText
}

export type DeclarationFormField = Array<{
  value: string
  label?: string
  name: string
  formItemType: FormItemType
  isAutoAuthDisabled: boolean
  placeholder: string
  rules?: Rule[]
  validateStatus?: FormItemProps['validateStatus']
  help?: string
  isShow?: boolean
  reason?: any
}>

export type DeclarationFormProps = {
  fields: DeclarationFormField
  initialValues: any
  onChange: (changed: any) => void
  form?: FormInstance<any>
  authType: AuthType
  extendsError: boolean
  purposes: any
  rooms: any
}

export const DeclarationForm = ({
  fields,
  onChange,
  initialValues,
  form,
  authType,
  extendsError,
  purposes,
  rooms,
}: DeclarationFormProps) => {
  
  const [canReset, setCanReset] = useState(true)

  const handleChangeSelect = (value: string) => {
    onChange({reason: value})
    form?.setFieldsValue({
      reason: value,
    })
  }

  const handleChangeMultiSelect = (value: string) => {
    onChange({rooms: value})
    form?.setFieldsValue({
      rooms: value,
    })
  }

  const tagRender = (props: CustomTagProps) => {
    const { label, } = props;
    return (
      <Tag
        color='#2C4B9C'
        style={{ marginRight: 3, margin: '0 40' }}
      >
        {label}
      </Tag>
    );
  };

  useEffect(() => {
    if ((authType === AuthType.sgIdAuth) && (initialValues.name !== '' && initialValues.name !== (null || undefined)) && canReset) {
      form?.resetFields()
      setCanReset(false)
    }
  }, [initialValues])

  return (
    <Form
      form={form}
      onValuesChange={onChange}
      initialValues={initialValues}
      className="declaration-form"
    >
      {fields.map(
        ({
          label,
          name,
          formItemType,
          isAutoAuthDisabled,
          placeholder,
          rules = [],
          validateStatus,
          help,
          isShow,
        }) =>
          isShow ? (
            <Form.Item
              rules={[
                { required: true, message: `Please input your ${label}!` },
                ...rules,
              ]}
              name={name}
              key={name}
              validateStatus={validateStatus}
              help={help}
            >
              {(() => {
                switch (formItemType) {
                  case FormItemType.dropdownSelect:
                    return <Select
                    placeholder={placeholder}
                    optionFilterProp="children"
                    showSearch={false}
                    allowClear={true}
                    onChange={(value) => {
                      handleChangeSelect(value)
                    }}>
                      {purposes.map((r, i) => {
                        return <Select.Option key={i} value={r}>{r}</Select.Option>
                      })}
                  </Select>
                  case FormItemType.dropdownMultiSelect:
                    return <Select
                    placeholder={placeholder}
                    mode="multiple"
                    optionFilterProp="children"
                    showSearch={false}
                    allowClear={true}
                    showArrow
                    tagRender={tagRender}
                    onChange={(value) => {
                      handleChangeMultiSelect(value)
                    }}>
                      {rooms.map((r, i) => {
                        return <Select.Option key={i} value={r}>{r}</Select.Option>
                      })}
                  </Select>
                  case FormItemType.textInput:
                    return <Input disabled={isAutoAuthDisabled} placeholder={placeholder} /> 
                  default:
                    return null
                }
              })()}
            </Form.Item>
          ) : undefined
      )}
    </Form>
  )
}
