import { Space } from 'antd'
import React from 'react'
import { ReactComponent as ErrorMessage } from 'assets/icons/error-message-icon.svg'

export const FourZeroFourPage = () => {
  return (
    <div className="four-zero-four-page container d-flex flex-column align-items-center">
      <Space direction="vertical" align="center">
        <p className="error-message">
          Oops, we can’t find the page you are searching for.
        </p>
        <ErrorMessage
          className="error-image"
          style={{ height: 'auto', width: 200 }}
        />
      </Space>
      <ul className="fix-steps">
        <li>
          {/* <div>1</div> */}
          <p>Close all existing browsers and try scanning the QR code again.</p>
        </li>
      </ul>
    </div>
  )
}
