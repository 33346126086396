/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useEffect } from 'react'
import { Row, Col, Typography } from 'antd'
//import * as firebaseui from 'firebaseui'
//import firebase from './../../../libs/firebase'
//import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'

// images
// import background from '../../../assets/images/login_background.png'

// style
import './style.css'

// components
// import { GovEntryLogo } from '../../../components/GovEntryLogo'
import SgIdAuth from '../../../components/SgIdAuth'
import { AuthRequestType } from '../../APPages/AuthCallbackPage'
import { SwiftEntryLogo } from '../../../components/SwiftEntryLogo'
import DynamicBackground from '../../../components/DynamicBackground'



const { Title, Text } = Typography

function Login() {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  //const currentUser = firebase?.app()?.auth()?.currentUser ?? {}

  // useEffect(() => {
  //   firebase.auth().languageCode = 'en'
  // }, [currentUser])

  // const uiConfig: firebaseui.auth.Config = {
  //   // Popup signin flow rather than redirect flow.
  //   signInFlow: 'popup',
  //   // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  //   signInSuccessUrl: '/admin',
  //   // We will display Google and Facebook as auth providers.
  //   signInOptions: [
  //     {
  //       provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
  //       defaultCountry: 'SG',
  //     },
  //     // firebase.auth.EmailAuthProvider.PROVIDER_ID,
  //     firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  //   ],
  //   siteName: 'GovEntry Admin Site',
  // }

  return (
    <DynamicBackground>
      <SwiftEntryLogo color="default" />
      <Title
        level={3}
        style={{ padding: '0 24px 0 24px', marginBottom: '30px', fontSize: '16px', fontWeight: '700', textAlign: "center" }}
      >
        Admin
      </Title>
      <SgIdAuth requestType={AuthRequestType.adminLogin.toString()} />
    </DynamicBackground>
  )
}

export default Login
