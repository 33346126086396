const apiURL = process.env.REACT_APP_API_URL;
const url = `${apiURL}/api/v2/getRoomsByUser`;

export const getRoomsByUserAPI = {
  async list( 
    token: string,
    userId: string,
    entranceId: number
    ): Promise<any> {
    const resp = await fetch(`${url}`,
      {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify({
            "userIdentity": userId,
            "entranceId": entranceId,
        }),
        headers: {
                  'Content-Type': 'application/json',
                  'Content-Length': '0',
                  'Authorization': token,
        },
      });
    const response = await resp.json();
    return response;
  },
};
