import React, { useEffect, useState, useRef } from "react";
import { Layout, Tabs, Checkbox, Button, Select, Modal } from 'antd'
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { CaretDownFilled } from "@ant-design/icons";
import {cache} from 'swr'
// custom components
import { RoomObject, Visitor, VisitorInfoCard } from '../../../components/VisitorInfoCard';
import { AlertLabel, AlertStatus } from "../../../components/AlertLabel";
import { NotificationBar } from "../../../components/NotificationBar";
import { FloatingButton, FloatingButtonPosition, FloatingButtonType } from "../../../components/FloatingButton";

// apis
import { getEntrancesByUserAPI } from '../../../api/AP/Entrances'
import { getRoomsByUserAPI } from '../../../api/AP/RoomsByUser'
import { getPurposesAPI } from '../../../api/AP/Purposes'
import { getVisitorsByEntranceAPI } from '../../../api/AP/VisitorsByEntrance'
import { checkoutVisitorAPI } from '../../../api/AP/CheckoutVisitor'
import { updateVisitorRoomsAPI } from '../../../api/AP/UpdateVisitorRooms'
import { updatePurposeAPI } from '../../../api/AP/UpdatePurpose'

// styles
import './style.css'

// icons
import logoWhite from '../../../assets/images/logo_swiftentry_white.png'
import usersSilhoutteIcon from '../../../assets/icons/i-users-silhoutte.png'
import checkoutIcon from '../../../assets/icons/i-checkout.png'
import checkoutIconDisabled from '../../../assets/icons/i-checkout-disabled.png'
import { useHistory } from "react-router-dom";

export enum ApRequestType {
    getEntrances,
    getRooms,
    getPurposes,
    getVisitors,
    updateRooms,
    updatePurpose,
    checkoutVisitor,
    unknown
}

const { Content, Footer } = Layout;
const { TabPane } = Tabs;
const { Option } = Select;

function VisitorList(props: any) {

    const isMounted = useRef(false);
    const { authDispatch, userData, children } = props


    // State variables
    const [userToken, setUserToken] = useState("")
    const [loggedInUser, setLoggedInUser] = useState("");
    const [entranceList, setEntranceList] = useState([]);
    const [selectedEntrance, setSelectedEntrance] = useState(-1);
    const [roomList, setRoomList] = useState(Array<RoomObject>());
    const [purposes, setPurposes] = useState([]);
    const [isCheckedInToEntrance, setIsCheckedInToEntrance] = useState(false);
    const [showAlertLabel, setShowAlertLabel] = useState(false);
    const [alertLabelMessage, setAlertLabelMessage] = useState("");
    const [alertLabelStatus, setAlertLabelStatus] = useState(AlertStatus.success);
    const [activeTabKey, setActiveTabKey] = useState('1');
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [cardSelectionChecked, setCardSelectionChecked] = useState(false);
    const [checkedInUsers, setCheckedInUsers] = useState([]);
    const [checkedOutUsers, setCheckedOutUsers] = useState([]);
    const [showCheckoutModal, setShowCheckoutModal] = useState(false);
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState("");
    const [notificationStatus, setNotificationStatus] = useState(AlertStatus.success);
    const history = useHistory()

    // Event handlers

    const onSelectEntrance = (value: any) => {
        let entrance = entranceList.find((e) => e.entranceName === value).entranceId;
        setSelectedEntrance(entrance);
        getVisitorsByEntrance(entrance, 'check-in');
        setActiveTabKey('1');

    }

    const updateAlertLabel = () => {
        if (entranceList.length === 0) {
            setShowAlertLabel(true);
            setAlertLabelStatus(AlertStatus.info);
            setAlertLabelMessage("Refresh this page if you have already checked in at your assigned entrance(s).");
        } else if (!isCheckedInToEntrance) {
            setShowAlertLabel(false);
            setAlertLabelStatus(AlertStatus.info);
            setAlertLabelMessage("");
        } else if (activeTabKey === '2') {
            setShowAlertLabel(false);
            setAlertLabelStatus(AlertStatus.info);
            setAlertLabelMessage("");
        } else {
            setShowAlertLabel(true);
            setAlertLabelStatus(AlertStatus.info);
            setAlertLabelMessage("Do ensure that everyone checks out upon leaving the premise.");
        }
    }

    const onChangeTab = async (key: any) => {
        setActiveTabKey(key);
        if (selectedEntrance !== -1) {
            switch (key) {
                case "1":
                    getVisitorsByEntrance(selectedEntrance, 'check-in');
                    break;
                case "2":
                    getVisitorsByEntrance(selectedEntrance, 'check-out');
                    break;
            }
        }
    };

    const onSelectInfoCard = (v: Visitor, selected: boolean) => {
        if (v.status === "check-in") {
            if (!selected) {
                setSelectAllChecked(false)
            }

            checkedInUsers.filter((item) => item.visitorInfo === v)[0].checked = selected;
            setCheckedInUsers(checkedInUsers)

            const selectedCards = checkedInUsers.filter((v) => v.checked)
            setCardSelectionChecked(selectedCards.length > 0)

            const isAllChecked = selectedCards.length === checkedInUsers.length;
            setSelectAllChecked(isAllChecked);
        }
    }

    const onUpdateRooms = (entryLogId: string, rooms: Array<RoomObject>) => {
        updateVisitorRooms(entryLogId, rooms.map((r) => {
            return { roomId: r.roomId }
        }))
    }

    const onUpdatePurpose = (entryLogId: string, purpose: string) => {
        updateVisitorPurpose(entryLogId, purpose)
    }

    const onChangeSelectAll = (e: CheckboxChangeEvent) => {
        setSelectAllChecked(e.target.checked);
        checkedInUsers.map((item) => item.checked = e.target.checked);
        setCheckedInUsers(checkedInUsers);

        if (!e.target.checked) {
            setCardSelectionChecked(false)
        }
    };

    const onClickLogout = () => {
        setShowLogoutModal(true)
    }

    const handleLogout = () => {
        fetch('/logout', { credentials: 'include' })
            .then(() => {
                localStorage.clear()
                cache.clear()
                // history.push('/ap/login')
                window.location.replace("/ap/login");
            })
        // localStorage.setItem("apAuthenticated", "");
        // window.location.replace("/ap/login");
    }

    const onClickCheckOut = () => {
        setShowCheckoutModal(true);
    }

    const handleCheckoutUsers = () => {
        setShowCheckoutModal(false);
        setSelectAllChecked(false);

        const checkedVisitors = checkedInUsers.filter((v) => (v.checked));
        checkedVisitors.forEach(
            function (v) {
                checkoutVisitor(v.visitorInfo.entryLogId);
            }
        )
    }

    const handleConfirmModal = () => {
        if (showCheckoutModal) {
            handleCheckoutUsers()
        } else if (showLogoutModal) {
            handleLogout()
        }
    }

    const handleCancelModal = () => {
        if (showCheckoutModal) {
            setShowCheckoutModal(false);
        } else if (showLogoutModal) {
            setShowLogoutModal(false)
        }
    }

    const updateNotificationBar = (status: AlertStatus, type: ApRequestType) => {
        let message = ""
        switch (type) {
            case ApRequestType.updateRooms:
                message = ((status === AlertStatus.success) ? "You have edited ‘Room(s) Selected’ successfully." : "You are not able to edit ‘Rooms Selected’. Refresh this page and try again.")
                break
            case ApRequestType.updatePurpose:
                message = ((status === AlertStatus.success) ? "You have edited ‘Purpose of Visit’ successfully." : "You are not able to edit ‘Purpose of Visit’. Refresh this page and try again.")
                break
            case ApRequestType.checkoutVisitor:
                message = ((status === AlertStatus.success) ? "You have checked out your visitor(s) successfully." : "You are not able to check out the selected visitor(s). Refresh this page and try again.")
                break
            default:
                message = "There seems to be an issue at this moment. Try again."
                break
        }
        setNotificationMessage(message)
        setNotificationStatus(status)
        setShowNotification(true)
    }

    const onCloseNotification = () => {
        setShowNotification(false)
    }

    const onClickRefresh = () => {
        getEntrances(loggedInUser)
    }

    // API Requests
    const getEntrances = (userId: string) => {
        if (userToken && userId && userId !== "" && userToken !== "") {
            getEntrancesByUserAPI.list(userToken, userId)
                .then((response: any) => {
                    if (response && response.status) {
                        const { data } = response;
                        if (data.length !== 0) {
                            setEntranceList(data.map(item => {
                                return { entranceId: item.entranceId, entranceName: item.entranceName }
                            }));
                            const entranceSel = (selectedEntrance === -1 ? data[0].entranceId : selectedEntrance);
                            setSelectedEntrance(entranceSel);
                            getVisitorsByEntrance(entranceSel, "check-in", userId);
                        } else {
                            let newList: any[] = [];
                            setEntranceList(newList);
                        }
                    } else {
                        getEntrances(loggedInUser)
                    }
                })
                .catch((err) => {
                    console.log(`getEntrances request failed ${err.message}`)
                    updateNotificationBar(AlertStatus.error, ApRequestType.unknown)
                })
        }
    }

    const getRooms = (userId: string, entranceId: number) => {
        getRoomsByUserAPI.list(userToken, userId, entranceId)
            .then((response: any) => {
                if (response && response.status) {
                    const { data } = response;
                    if (data.length !== 0) {
                        setRoomList(data.rooms);
                    } else {
                        setRoomList([]);
                    }
                } else {
                    setRoomList([]);
                }
            })
            .catch((err) => {
                console.log(`request failed ${err.message}`)
                setRoomList([]);
            })
    }

    const getPurposes = () => {
        getPurposesAPI.list()
            .then((response: any) => {
                if (response && response.status) {
                    const { reasons } = response;
                    if (reasons != null) {
                        setPurposes(reasons);
                    }
                }
            })
            .catch((err) => {
                console.log(`request failed ${err.message}`)
            })
    }

    const getVisitorsByEntrance = (id: number, type: string, userId: string | undefined = loggedInUser) => {
        getVisitorsByEntranceAPI.list(userToken, id, type)
            .then((response: any) => {
                if (response && response.status) {
                    const { data } = response;
                    if (data.length === 0) {
                        let newList: any[] = [];
                        if (type === "check-in") {
                            setCheckedInUsers(newList);
                        } else {
                            setCheckedOutUsers(newList)
                        }
                    } else {
                        let newList: any[] = [];
                        newList = data.map(item => {
                            return { index: data.length, checked: false, visitorInfo: item }
                        })

                        if (type === "check-in") {
                            if (newList) {
                                const loggedInUsers = newList.filter((v) => v.visitorInfo.phone === userId)
                                const loggedInUserIndex = newList.indexOf(loggedInUsers[0]) ?? 0
                                newList.unshift(newList.splice(loggedInUserIndex, 1)[0])
                            }
                            setCheckedInUsers(newList);
                        } else {
                            setCheckedOutUsers(newList);
                        }
                        setCardSelectionChecked(false)
                    }
                } else {
                    let newList: any[] = [];
                    if (type === "check-in") {
                        setCheckedInUsers(newList);
                    } else {
                        setCheckedOutUsers(newList)
                    }
                }
            })
            .catch((err) => {
                updateNotificationBar(AlertStatus.error, ApRequestType.unknown)
                console.log(`request failed ${err.message}`)
            })
    }

    const checkoutVisitor = (id: Number) => {
        checkoutVisitorAPI.list(id)
            .then((response: any) => {
                if (response) {
                    if (response.status) {
                        updateNotificationBar(AlertStatus.success, ApRequestType.checkoutVisitor);
                        setActiveTabKey('2');
                        getVisitorsByEntrance(selectedEntrance, "check-out");
                    } else {
                        let message = response.message ?? ""
                        if (!(message.includes("already checked out"))) {
                            updateNotificationBar(AlertStatus.error, ApRequestType.checkoutVisitor);
                        }
                        getVisitorsByEntrance(selectedEntrance, "check-in");
                    }
                } else {
                    updateNotificationBar(AlertStatus.error, ApRequestType.unknown)
                }
            })
            .catch((err) => {
                updateNotificationBar(AlertStatus.error, ApRequestType.unknown)
                console.log(`request failed ${err.message}`)
            })
    }

    const updateVisitorRooms = (entryLogId: string, rooms: any[]) => {
        updateVisitorRoomsAPI.list(userToken, entryLogId, rooms)
            .then((response: any) => {
                if (response) {
                    if (response.status) {
                        updateNotificationBar(AlertStatus.success, ApRequestType.updateRooms);
                        getVisitorsByEntrance(selectedEntrance, "check-in");
                    } else {
                        updateNotificationBar(AlertStatus.error, ApRequestType.updateRooms)
                    }
                } else {
                    updateNotificationBar(AlertStatus.error, ApRequestType.unknown)
                }
            })
            .catch((err) => {
                updateNotificationBar(AlertStatus.error, ApRequestType.unknown)
                console.log(`update visitor rooms request failed ${err.message}`)
            })
    }

    const updateVisitorPurpose = (entryLogId: string, purpose: string) => {
        updatePurposeAPI.list(userToken, entryLogId, purpose)
            .then((response: any) => {
                if (response) {
                    if (response.status) {
                        updateNotificationBar(AlertStatus.success, ApRequestType.updatePurpose);
                        getVisitorsByEntrance(selectedEntrance, "check-in");
                    } else {
                        updateNotificationBar(AlertStatus.error, ApRequestType.updatePurpose)
                    }
                } else {
                    updateNotificationBar(AlertStatus.error, ApRequestType.unknown)
                }
            })
            .catch((err) => {
                updateNotificationBar(AlertStatus.error, ApRequestType.unknown)
                console.log(`update visitor purpose request failed ${err.message}`)
            })
    }

    // Update rendering

    useEffect(() => {
        updateAlertLabel();
        if (entranceList.length === 0) {
            setCheckedInUsers([])
            setCheckedOutUsers([])
        }
    }, [activeTabKey, entranceList, isCheckedInToEntrance])

    useEffect(() => {
        if (selectedEntrance !== -1) {
            getRooms(loggedInUser, selectedEntrance);
        }
        getPurposes();
    }, [selectedEntrance])

    useEffect(() => {
        if (isMounted.current) {
            let loggedInVisitor = (checkedInUsers.find((el) => el.visitorInfo.phone === loggedInUser));
            setIsCheckedInToEntrance(loggedInVisitor != null);
        } else {
            isMounted.current = true;
        }
    }, [checkedInUsers, checkedOutUsers])

    useEffect(() => {
        const user = localStorage.getItem("apAuthenticated");
        if (user && user !== "") {
            setLoggedInUser(user);
            getEntrances(user);
        } else {
            window.location.replace("/ap/login");
        }
    }, [userToken])

    useEffect(() => {
        if (userData && userData.accessToken) {
            setUserToken(userData.accessToken)
        }
    }, [userData])

    return (
        <Layout className="visitor-list-page-layout">

            <Content style={{ height: '100vh', overflow: 'hidden', bottom: 0, backgroundColor: '#F5F5F5' }}>
                {(showNotification) && <> <NotificationBar status={notificationStatus} message={notificationMessage} onClose={onCloseNotification} /></>}
                <div className="top-bar" >
                    <img src={logoWhite} alt="logo" />
                    <p onClick={onClickLogout}>Logout</p>
                </div>
                <div style={{ height: '100%' }} >
                    {(entranceList.length > 0) && <> <div className="title-box">
                        <div>Entrance: </div>
                        {entranceList.length === 0
                            ? <p>No entrances found</p>
                            : entranceList.length === 1 ? <div className="title-font">{entranceList[0].entranceName}</div>
                                : <Select suffixIcon={<CaretDownFilled style={{ color: '#2C415C' }} />} defaultValue={entranceList[0].entranceName} onChange={onSelectEntrance}>
                                    {entranceList.map(entrance =>
                                        <Option key={entrance.entranceId} value={entrance.entranceName}>{entrance.entranceName}</Option>
                                    )}
                                </Select>
                        }
                    </div></>}
                    <div className={'label-box'}>
                        {(showAlertLabel) && <> <AlertLabel message={alertLabelMessage} status={alertLabelStatus}></AlertLabel> </>}
                    </div>
                    <Modal title="Confirm check out?" visible={showCheckoutModal} okText='Confirm' onOk={handleConfirmModal} onCancel={handleCancelModal}>
                        <p>Are you sure you want to check-out {((checkedInUsers.filter((v) => v.checked)).length) > 1 ? `these ${(checkedInUsers.filter((v) => v.checked)).length} users` : "this user"}? You cannot undo this.</p>
                    </Modal>
                    <Modal title="Confirm logout?" visible={showLogoutModal} okText='Confirm' onOk={handleConfirmModal} onCancel={handleCancelModal}>
                        <p>Are you sure you want to logout? You would need to login with your credentials again.</p>
                    </Modal>
                    <div className="list-container">
                        <div className="tabs-container">
                            <Tabs
                                defaultActiveKey={activeTabKey}
                                activeKey={activeTabKey}
                                onChange={onChangeTab}
                                tabBarStyle={{ borderBottom: '1px solid #BCC6D7', paddingBottom: -5 }}
                                centered >
                                <TabPane className="list-scrollable" tab="Checked-In" key="1" style={{ height: (entranceList.length === 0 ? 'calc(100vh - 207px)' : (checkedInUsers.length === 0) ? 'calc(100vh - 207px)' : (!isCheckedInToEntrance) ? 'calc(100vh - 299px)' : 'calc(100vh - 373px)') }} >
                                    {checkedInUsers.length === 0
                                        ? <div className="no-data-box">
                                            <img src={usersSilhoutteIcon} alt="no users" />
                                            <p>There are no checked-in visitor(s) at the moment.</p>
                                        </div>
                                        : <div>
                                            {checkedInUsers.map((visitor, index) =>
                                                <VisitorInfoCard
                                                    key={index}
                                                    index={index}
                                                    editable={entranceList.length > 0}
                                                    checked={visitor.checked}
                                                    isSelf={visitor.visitorInfo.phone === loggedInUser}
                                                    roomOptions={roomList}
                                                    purposeOptions={purposes}
                                                    visitorInfo={visitor.visitorInfo}
                                                    onSelect={onSelectInfoCard}
                                                    onUpdateRooms={onUpdateRooms}
                                                    onUpdatePurpose={onUpdatePurpose}
                                                />
                                            )}
                                            {(checkedInUsers.length !== 0) && <><FloatingButton position={FloatingButtonPosition.stickyElement} type={FloatingButtonType.refresh} text="Refresh" onClick={onClickRefresh} /></>}
                                            <Footer hidden={entranceList.length === 0}>
                                                <Checkbox checked={selectAllChecked} onChange={onChangeSelectAll}>Select All</Checkbox>
                                                <Button className="primary-button" disabled={!(selectAllChecked || cardSelectionChecked)} type="primary" onClick={onClickCheckOut} >Check-Out<img src={(selectAllChecked || cardSelectionChecked) ? checkoutIcon : checkoutIconDisabled} alt="checkout" /></Button>
                                            </Footer>
                                        </div>
                                    }
                                    {(checkedInUsers.length === 0) && <><FloatingButton position={FloatingButtonPosition.absoluteBottom} type={FloatingButtonType.refresh} text="Refresh" onClick={onClickRefresh} /></>}
                                </TabPane>
                                <TabPane className="list-scrollable" disabled={entranceList.length === 0} tab="Checked-Out" key="2" style={{ height: 'calc(100vh - 199px)' }} >
                                    {checkedOutUsers.length === 0
                                        ? <div className="no-data-box">
                                            <img src={usersSilhoutteIcon} alt="no users" />
                                            <p>There are no checked-out visitor(s) at the moment.</p>
                                        </div>
                                        : <div>
                                            {checkedOutUsers.map((visitor, index) =>
                                                <VisitorInfoCard
                                                    key={index}
                                                    index={index}
                                                    editable={false}
                                                    checked={false}
                                                    isSelf={false}
                                                    roomOptions={roomList}
                                                    purposeOptions={purposes}
                                                    visitorInfo={visitor.visitorInfo}
                                                    onSelect={onSelectInfoCard}
                                                    onUpdateRooms={onUpdateRooms}
                                                    onUpdatePurpose={onUpdatePurpose}
                                                />
                                            )}
                                        </div>
                                    }
                                </TabPane>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </Content>

        </Layout>
    )
}

export default VisitorList
