import { Modal, Form, Input, message, Button, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { Entrance, EntranceAPI } from 'api/Entrance'
import { Template } from 'api/Template'

const { Option } = Select

export type EditEntranceModalProps = {
  entrance: Entrance
  visible: boolean
  roleName: string
  onOk: () => void
  onCancel: () => void
  accessToken: string
  templates: Template[]
}
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

export const EditEntranceModal = ({
  visible,
  roleName,
  onOk,
  onCancel,
  entrance,
  accessToken,
  templates,
}: EditEntranceModalProps) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (entrance !== null) {
      form.setFieldsValue({
        name: entrance.name,
        template_id: entrance.template_id,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entrance])

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const editedEntrance = form.getFieldsValue() as Entrance
      const hasError = await form
        .validateFields()
        .then(() => true)
        .catch(() => false)
      if (hasError === false) throw new Error('')

      await EntranceAPI.update(
        accessToken,
        entrance.id,
        editedEntrance
      ).then((resp) => {
        if (resp && resp.status) {
          const text = (roleName === 'ge-super-admin') ? `Your entrance ${entrance.name} has been edited successfully.` : `Your request to update ${entrance.name} has been submitted for approval.`
          message.success(text)
      } else {
          message.error("There seems to be an issue at this moment. Try again.")
      }
      })
      onOk()
      form.resetFields()
      setLoading(false)
    } catch (error) {
      if (error.message) {
        message.error(error.message)
      }
      setLoading(false)
    }
  }
  return (
    <Modal
      title="Edit Entrance"
      className='location-modal'
      centered
      visible={visible}
      onOk={handleSubmit}
      onCancel={onCancel}
      confirmLoading={loading}
      maskClosable={false}
      cancelText={'Close'}
      footer={[
        <Button key="back" className="btn-default" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          loading={loading}
          key="submit"
          className="btn-create"
          onClick={handleSubmit}
        >
          Save
        </Button>,
      ]}
    >
      <Form form={form} {...layout} onFinish={handleSubmit}>
        <Form.Item
          name="name"
          label="Entrance Name"
          labelCol={{ span: 24 }}
          rules={[{ required: true, message: 'Name is required' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="template_id" label="Template" labelCol={{ span: 24 }}>
          <Select placeholder="Select a template">
            {templates?.map((e) => (
              <Option key={e.id} value={e.id}>{e.name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}
