import React, { useEffect, useState } from 'react'
import {
    Card,
    CardContent,
    Checkbox,
    Grid,
    ThemeProvider,
    Typography,
    createTheme
} from '@mui/material'
import { EditLocationDialog } from './EditLocationDialog'
import { EditPurposeDialog } from './EditPurposeDialog'

export type RoomObject = {
    roomId: number;
    roomName: string;
}

export type Visitor = {
    entryLogId: string;
    name: string;
    nric: string;
    userId: string;
    phone: string;
    checkedIn: string;
    checkedOut: string;
    purpose: string;
    rooms: Array<RoomObject>;
    status: string;
}

export type VisitorUpdate = {
    visitorInfo: Visitor;
    index: number;
    checked: boolean;
}

type Props = {
    index: number;
    checked: boolean,
    isSelf: boolean,
    children?: React.ReactNode,
    visitorInfo: Visitor,
    roomOptions: Array<RoomObject>,
    purposeOptions: Array<string>,
    editable?: boolean,
    onSelect: (visitor: Visitor, selected: boolean) => void,
    onUpdateRooms: (entryLogId: string, rooms: Array<RoomObject>) => void
    onUpdatePurpose: (entryLogId: string, purpose: string) => void
}

export const VisitorInfoCard = ({ children, index, checked, isSelf, visitorInfo, roomOptions, purposeOptions, editable = false, onSelect, onUpdateRooms, onUpdatePurpose }: Props) => {
    const dateOptions: Intl.DateTimeFormatOptions = { dateStyle: 'medium', timeStyle: 'short' };
    const {
        entryLogId,
        name,
        nric,
        phone,
        checkedIn,
        checkedOut,
        purpose,
        rooms,
        status
    } = visitorInfo
    const [visitorSelected, setVisitorSelected] = useState(checked);
    const [purposeSelected, setPurposeSelected] = useState(purpose);
    const [locationSelected, setLocationSelected] = useState(rooms);

    useEffect(() => {
        setVisitorSelected(checked)
    }, [checked])

const visitorInfoCardSelectedTheme = React.useMemo(
    () =>
        createTheme({
            palette: {
                primary: {
                  main: '#2C4B9C',
                },
            },
            typography: {
                fontFamily: "Lato",
                button: {
                    textTransform: "none",
                    fontWeight: 900,
                },
            },
            components: {
                // Name of the component
                MuiCard: {
                    styleOverrides: {
                        // Name of the slot
                        root: {
                            // Some CSS
                            maxWidth: 500,
                            backgroundColor: visitorSelected ? '#EFF7FE' : '#FFFFFF',
                            boxShadow: visitorSelected ? '0px 2px 4px rgba(44, 75, 156, 0.2)' : '0',
                            marginBottom: 20,
                            borderRadius: 8,
                        },
                    },
                },
            },
        }),
    [visitorSelected],
);

const handleLocationChange = (l: Array<RoomObject>) => {
    setLocationSelected(l)
    onUpdateRooms(entryLogId, l)
}
const handlePurposeChange = (p: string) => {
    setPurposeSelected(p)
    onUpdatePurpose(entryLogId, p)
}
const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    setVisitorSelected(e.currentTarget.checked)
    onSelect(visitorInfo, e.currentTarget.checked)
}

const getMaskedNric = () => {
    return nric.slice(0, 1) + nric.slice(1).replace(/.(?=....)/g, '*')
}

const getMaskedPhone = () => {
    if (phone.length === 8) {
        return phone.slice(0, 1) + phone.slice(1).replace(/.(?=....)/g, '*')
    } else if (phone[0] === '+' && phone.length === 11) {
        return phone.slice(0, 4) + phone.slice(1).replace(/.(?=....)/g, '*')
    } else {
        return phone.slice(0, 6) + phone.slice(1).replace(/.(?=....)/g, '*')
    }
}

return (
    <ThemeProvider theme={visitorInfoCardSelectedTheme}>
        <Card variant="outlined" >
            <CardContent>
                <Grid container spacing={0}>
                    <Grid item={true} xs={11} sx={{ marginBottom: 4 }}>
                        <Typography variant='h5' sx={{ fontWeight: 900 }}>{ isSelf ? name + " (Me)" : name}</Typography>
                        <Typography variant='body2'>{ getMaskedNric() } | { getMaskedPhone() }</Typography>
                    </Grid>
                    <Grid item={true} xs={1}>
                        {(status === "check-in" && editable) && <Checkbox checked={visitorSelected || checked} onChange={handleChange} />}
                    </Grid>
                    <Grid item={true} xs={12} sx={{ marginBottom: 2 }}>
                        <Typography variant='body1' sx={{ fontSize: 14 }}>{status === "check-in" ? "Checked in at" : "Checked out at"}</Typography>
                        <Typography variant='body1'>{new Intl.DateTimeFormat('en-GB', dateOptions).format(new Date(status === "check-in" ? checkedIn : checkedOut))}</Typography>
                    </Grid>
                    {(roomOptions.length > 0) && <><Grid item={true} xs={6}  sx={{ paddingRight: 0.5 }}>
                        <Typography variant='body1' sx={{ fontSize: 14 }}>Room Selected 
                            <EditLocationDialog
                                locationOptions={ roomOptions ?? [] }
                                value={ locationSelected ?? [] }
                                editable={editable && (roomOptions ?? []).length > 0}
                                onUpdate={handleLocationChange} />
                        </Typography>
                        <Typography variant='body1'>{(rooms && rooms.length > 0) ? rooms.map(r => r.roomName).join(", ") : 'No Room Selected'}</Typography>
                    </Grid></>}
                    <Grid item={true} xs={6} sx={{ paddingLeft: 0.5 }}>
                        <Typography variant='body1' sx={{ fontSize: 14 }}>Purpose of Visit 
                            <EditPurposeDialog
                                purposeOptions={ purposeOptions }
                                value={purposeSelected ?? ""}
                                editable={editable}
                                onUpdate={handlePurposeChange} />
                        </Typography>
                        <Typography variant='body1'>{purposeSelected}</Typography>
                        
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    </ThemeProvider>
)
}
