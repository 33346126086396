import useSWR from "swr";
import AuthAPI from "../api/Auth";

function useAuthRole(accessToken: string) {
  const { data, error } = useSWR(
    () => (accessToken ? ["auth-roles", accessToken] : null),
    (_, token) => AuthAPI.getRole(token),
    {
      // revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    });

  
  // @ts-ignore
  // if (data && data.status === "failure") {
  //   return [data];
  // }
  if (data && data.status === false) {
    return [data];
  }
  // if true, it has already returned rep.data from auth.getRole
  // @ts-ignore 
  return [data?.role ?? null, error];
}

export default useAuthRole;
