import React from 'react'
import classnames from 'classnames'

type Props = {
  children: React.ReactNode
  contentCentered?: boolean
  classNames?: string
  width?: number | string
  height?: number | string
}
export const CardDefault = ({
  children,
  classNames,
  contentCentered,
  width = '100%',
  height,
}: Props) => {
  return (
    <div
      className={classnames('card', classNames, {
        'd-flex justify-content-center align-items-center flex-column': contentCentered,
      })}
      style={{ width, height }}
    >
      {children}
    </div>
  )
}
