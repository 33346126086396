import React, { useEffect, useRef, useState } from 'react'
import { Button, Space, Input, Typography, Form } from 'antd'

import './style.less'
// import ReCAPTCHA from 'react-google-recaptcha'
import { CheckingAPI } from 'api/Main'
import { useRouter } from 'hooks/useRouter'
import { CardDefault } from 'components/cards/CardDefault'

const { Text, Title } = Typography
const {
  REACT_APP_ENV,
  REACT_APP_SWIFT_ID,
  // REACT_APP_GOOGLE_RECAPTCHA_SITEKEY,
} = process.env

const numericRegex = /^\d*$/
const pinCodeLength = 4

export const EnrolPage = (props: any) => {
  let router = useRouter()

  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)
  const [inputGroup, setInputGroup] = useState({})
  // const [reCaptchaToken, setReCaptchaToken] = useState<string>(null)

  const changeInputGroup = (key, value) => {
    setInputGroup((preState) => ({ ...preState, [key]: value }))
  }

  const inputRefs = useRef<any>([])
  inputRefs.current = []

  const addToRef = (e: any) => {
    if (e && !inputRefs.current.includes(e)) {
      inputRefs.current.push(e)
    }
  }

  function getNewNumberPIN(oldString, newString): any {
    if (numericRegex.test(newString)) {
      if (oldString !== newString[0]) {
        return newString[0]
      } else {
        return newString[1]
      }
    } else return oldString
  }

  useEffect(() => {
    inputRefs.current[0].focus()
  }, [])

  const submitHandler = async (e: any) => {
    const values = Object.values(inputGroup).join('')

    if (values.length !== pinCodeLength || values.match(/\D/)) {
      setErrorMsg(values ? 'Entry is not found!' : 'Entry is required!')
    } else {
      const response = await CheckingAPI.getEntrance(parseInt(values))
      if (response.constructor === Array) {
        const { id, location_id, location_name } = response[0]
        router.push(
          `/tenant/${REACT_APP_ENV}-${REACT_APP_SWIFT_ID}-${location_id}-${id}-${location_name
            .split(' ')
            .join('-')}`
        )
      } else {
        setErrorMsg('Entry is not found!')
      }
    }
  }

  // const reCaptchaHandler = (response: string) => {
  //   if (response) {
  //     setReCaptchaToken(response)
  //   }
  // }

  return (
    <div className="enrol-page container d-flex align-items-center flex-column">
      <CardDefault classNames="form-card" contentCentered>
        <Space
          className="building-name"
          align="center"
          direction="vertical"
          size={1}
        >
          <Title>Enter PIN</Title>
          <Form
            style={{ textAlign: 'center' }}
            autoComplete="off"
            onFinish={submitHandler}
          >
            <Space align="center" direction="vertical">
              <Space align="center" style={{ margin: '10px 0' }}>
                {[...new Array(pinCodeLength)].map((_, i) => {
                  return (
                    <Form.Item key={i}>
                      <Input
                        className={`numberic-input ${
                          errorMsg ? 'error' : ''
                        } number-input`}
                        ref={(node: any) => {
                          addToRef(node)
                        }}
                        value={inputGroup[i]}
                        type={'tel'}
                        // maxLength={1}
                        size="large"
                        placeholder="0"
                        onChange={(e) => {
                          const value = e.target.value || ''
                          let lengthInput = inputGroup[i]?.length ?? ''

                          if (
                            value.length > lengthInput &&
                            numericRegex.test(value)
                          ) {
                            let new_values = getNewNumberPIN(
                              inputGroup[i],
                              value
                            )
                            changeInputGroup(i, new_values)
                          } else {
                            // changeInputGroup(i, '')
                          }
                        }}
                        onKeyDown={(e: any) => {
                          if (e.keyCode === 8) {
                            e.target.oldValue = e.target.value
                          }
                        }}
                        onKeyUp={(e: any) => {
                          if (e.keyCode === 8) {
                            if (
                              e.target.oldValue === '' &&
                              e.target.value === '' &&
                              inputRefs.current[i - 1]
                            ) {
                              inputRefs.current[i - 1].focus()
                            }
                            changeInputGroup(i, '')
                          } else if (
                            e.target.value &&
                            inputRefs.current[i + 1]
                          ) {
                            inputRefs.current[i + 1].focus()
                          }

                          if (e.key === 'ArrowLeft' || e.key === 'Home') {
                            if (i !== 0) {
                              inputRefs.current[i - 1].focus()
                            } else {
                              inputRefs.current[0].focus()
                            }
                          }
                        }}
                        onFocus={() => {
                          setErrorMsg(undefined)
                        }}
                        onClick={(e: any) => {
                          // inputRefs.current[i].select()
                        }}
                      ></Input>
                    </Form.Item>
                  )
                })}
              </Space>
              <Text type="danger">{errorMsg}</Text>

              {/* <ReCAPTCHA
                sitekey={REACT_APP_GOOGLE_RECAPTCHA_SITEKEY}
                onChange={reCaptchaHandler}
              /> */}

              <Button
                className="btn-check-in"
                type="primary"
                size="large"
                htmlType="submit"
                // disabled={!reCaptchaToken}
                style={{ marginTop: '10px', width: '184px' }}
              >
                <span style={{ fontWeight: 700, fontSize: 14 }}>Submit</span>
              </Button>
            </Space>
          </Form>
        </Space>
      </CardDefault>
    </div>
  )
}
