/**
 * @author Tran Trung
 * @description
 */
import React from 'react'
import { cache } from 'swr'
import { Layout, Avatar, Dropdown, Menu, Space, Typography } from 'antd'
import { UserOutlined, LogoutOutlined, DownOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'

// libary
//import firebase from '../../../libs/firebase'

// style
import './style.less'

// Image
// import logoWhite from '../../../assets/images/logo_white.png'
import { SwiftEntryLogo } from '../../SwiftEntryLogo'

const { Header } = Layout
const { Text, Title } = Typography

export default function HeaderLayout(props: any) {
  const apiURL = process.env.REACT_APP_API_URL;
  const { user } = props
  const history = useHistory()

  const identity = user?.nric ?? user?.phone ?? null

  const logout = async () => {
    // e.preventDefault();
    // await firebase.auth().signOut()
    fetch(`${apiURL}/api/v2/ap/logout`, {credentials: 'include'})
    .then(()=>{
      localStorage.clear()
      cache.clear()
      props.authDispatch({loggedIn: false})
      // history.push('/admin/login')
      window.location.href = '/admin/login'
    })
  }

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Space
          direction="vertical"
          style={{
            textAlign: 'center',
            padding: '20px 5px',
          }}
        >
          <Avatar
            icon={<UserOutlined />}
            style={{ backgroundColor: '#2C4B9C' }}
          />
          <Title level={4}>{identity}</Title>
        </Space>
      </Menu.Item>
      <Menu.Item onClick={logout} key="2">
        <LogoutOutlined style={{ marginRight: '5px' }} />
        Logout
      </Menu.Item>
    </Menu>
  )

  return (
    <Header
      style={{
        height: '60px',
        padding: 0,
        backgroundColor: '#2C4B9C',
        color: '#fff',
        position: 'fixed',
        zIndex: 1,
        width: '100%',
      }}
    >
      <div style={{ display: 'inline-block' }}>
        <div className="header_logo">
          {/* <Space size={0}> */}
            {/* <img alt="nav-logo" src={logoWhite} width="35" height="35" />
            <Text
              style={{
                color: '#fff',
                fontSize: '18px',
                marginLeft: '10px',
              }}
            >
              <span style={{ position: 'absolute', paddingTop: '7px' }}>
                Gov<b>Entry</b>
              </span>
            </Text> */}
            <SwiftEntryLogo color="white"/>
          {/* </Space> */}
        </div>
      </div>
      <div
        style={{
          float: 'right',
          paddingRight: 20,
          color: '#fff',
          cursor: 'pointer',
        }}
        // onClick={logout}
      >
        {/* {identity ? (
          <>
            <Avatar
              icon={<UserOutlined />}
              style={{ backgroundColor: "rgb(70, 150, 124)" }}
            />{" "}
            Logout
          </>
        ) : (
          "Login"
        )} */}
        {identity ? (
          <Dropdown overlay={menu}>
            <Space>
              <Avatar icon={<UserOutlined />} />
              <DownOutlined />
            </Space>
          </Dropdown>
        ) : (
          'Login'
        )}
      </div>
    </Header>
  )
}
