import React from "react";

import './DynamicBackground.css'

function DynamicBackground(props) {
  const { x, y } = useMousePosition()

  return (
    <div className='swift-dynamicbg-container'>
      <div className='swift-dynamicbg-bg' style={{
        padding: '1rem',
        backgroundPositionX: x ? -x / 10 : 0,
        backgroundPositionY: y ? -y / 10 : 0
      }}>
        {x} {y}

      </div>
      <div className='swift-dynamicbg-login-components'>
        {props.children}
      </div>
    </div>

  )
}

const useMousePosition = () => {
  const [
    mousePosition,
    setMousePosition
  ] = React.useState({ x: null, y: null });
  React.useEffect(() => {
    const updateMousePosition = ev => {
      setMousePosition({ x: ev.clientX, y: ev.clientY });
    };
    window.addEventListener('mousemove', updateMousePosition);
    return () => {
      window.removeEventListener('mousemove', updateMousePosition);
    };
  }, []);
  return mousePosition;
};

export default DynamicBackground