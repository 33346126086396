import { v4 as uuidV4} from 'uuid';
import { Modal, Form, Input, message, Button, Select, Table, Popconfirm, Tag} from 'antd'
import React, { useState, useEffect, useReducer } from 'react'
import {
PlusOutlined
} from '@ant-design/icons';
import { ColumnType } from 'antd/es/table'
import { GroupsAPI } from 'api/AP/RoomAccessManagement'
import { RoomsAPI, IRoomAPIV2 } from 'api/AP/Rooms'
import { LocationAPI, ILocationAPIV2 } from 'api/Location'
import { EntranceAPI, IEntranceV2 } from 'api/Entrance'

import '../style.less'

// TO DO:
  /*
    Bugs:
    1) Filter away locations that have been selected by locations row before the current row
    2) Paginate will remove selection values
    3) After closing or submitting create, rows will be unresponsive, can try to clear state programmatically in handlesubmit
  */
  
// interface declarations
// interface to transform api data to format usable by the Select component
interface ILocationSelectOption {
  value: number
  label: string
  recordid?: string,
}

interface IEntranceSelectOption extends ILocationSelectOption {
}

interface IRoomSelectOption extends ILocationSelectOption {

}

interface IRoomSelectDataState {
  value: number [],
  label: string,
  recordid?: string
}

// 1 row data for Select elements 
type AddLocationTableData = {
  locations: ILocationSelectOption[],
  entrances?: IEntranceSelectOption[],
  rooms?: IRoomSelectOption[],
  key: string
}

// component props
export type CreateGroupModalProps = {
visible: boolean
onOk: () => void
onCancel: () => void
accessToken: string
}

type TablePaginationType = {
  current: number
  pageSize: number
  total: number
}

const layout = {
labelCol: { span: 6 },
wrapperCol: { offset: 0 },
}

const initPagination: TablePaginationType = {
  pageSize: 3,
  current: 1,
  total: 0,
}

// reducer for populating options for add location table's Selects
const locationTableSelectDataReducer = (state: any, action: {type: string, payload: any}) => {
  switch (action.type) {
    // add entrances options to select 
  case "ADD-ENTRANCES":
    return state.map((AddLocationTableData: AddLocationTableData) => {
      if (AddLocationTableData.key === action.payload.key) {
        return { ...AddLocationTableData, entrances: action.payload.entrances };
      } else {
        return AddLocationTableData;
      }
    });
  case "REMOVE-ENTRANCES":
    // remove entrances options to select
    return state.map((AddLocationTableData: AddLocationTableData) => {
      if (AddLocationTableData.key === action.payload.key) {
        return { ...AddLocationTableData, entrances: null};
      } else {
        return AddLocationTableData;
      }
    });
    case "ADD-ROOMS":
      // add rooms options to select 
      return state.map((AddLocationTableData: AddLocationTableData) => {
        if (AddLocationTableData.key === action.payload.key) {
          return { ...AddLocationTableData, rooms: action.payload.rooms };
        } else {
          return AddLocationTableData;
        }
      });
    case "REMOVE-ROOMS":
        // remove rooms options to select 
      return state.map((AddLocationTableData: AddLocationTableData) => {
        if (AddLocationTableData.key === action.payload.key) {
          return { ...AddLocationTableData, rooms: null };
        } else {
          return AddLocationTableData;
        }
      });
    case "INIT-RECORD":
      // init a new record with unique recordid and list of locations
      if(state.findIndex((AddLocationTableData: AddLocationTableData)=> AddLocationTableData.key === action.payload.key) === -1){
        return [...state, action.payload]
      }
    case "DELETE-RECORD":
      // delete record with reference to its unique recordid
      let indexOfRecord = state.findIndex((AddLocationTableData: AddLocationTableData)=> AddLocationTableData.key === action.payload.key)
      return state.toSpliced(indexOfRecord, 1);
    default:
    return state;
  }
};

// reducer to track value of location selects
const locationSelectValueReducer = (state, action) => {
  let indexOfRecord 
  switch (action.type) {
  case "SET-LOCATION":
    indexOfRecord = state.findIndex((LocationSelected: Omit<ILocationSelectOption,'label'>)=> LocationSelected.recordid === action.payload.key)
    if( indexOfRecord === -1){
      // if no entrance record found
      return [...state, {recordid: action.payload.key, value: action.payload.locationId}]
    }else{
      return state.map((LocationSelected: Omit<ILocationSelectOption,'label'>) => {
        if (LocationSelected.recordid === action.payload.key) {
          return { ...LocationSelected, value: action.payload.locationId };
        } else {
          return LocationSelected;
        }
      });
    }
  case "RESET-LOCATIONS":
    return state.map((LocationSelected: Omit<ILocationSelectOption,'label'>) => {
      if (LocationSelected.recordid === action.payload.key) {
        return { ...LocationSelected, value: undefined };
      } else {
        return LocationSelected;
      }
    });
  case "DELETE-LOCATION":
      indexOfRecord = state.findIndex((LocationSelected: Omit<ILocationSelectOption,'label'>)=> LocationSelected.recordid === action.payload.key)
      return state.toSpliced(indexOfRecord,1)
    default:
    return state;
  }
};

// reducer to track value of entrance selects
const entranceSelectValueReducer = (state, action) => {
  let indexOfRecord 
  switch (action.type) {
  case "SET-ENTRANCE":
    indexOfRecord = state.findIndex((EntranceSelected: Omit<IEntranceSelectOption,'label'>)=> EntranceSelected.recordid === action.payload.key)
    if( indexOfRecord === -1){
      // if no entrance record found
      return [...state, {recordid: action.payload.key, value: action.payload.entranceId}]
    }else{
      return state.map((EntranceSelected: Omit<IEntranceSelectOption,'label'>) => {
        if (EntranceSelected.recordid === action.payload.key) {
          return { ...EntranceSelected, value: action.payload.entranceId };
        } else {
          return EntranceSelected;
        }
      });
    }
  case "RESET-ENTRANCES":
    return state.map((EntranceSelected: Omit<IEntranceSelectOption,'label'>) => {
      if (EntranceSelected.recordid === action.payload.key) {
        return { ...EntranceSelected, value: undefined };
      } else {
        return EntranceSelected;
      }
    });
  case "DELETE-ENTRANCES":
      indexOfRecord = state.findIndex((EntranceSelected: Omit<IEntranceSelectOption,'label'>)=> EntranceSelected.recordid === action.payload.key)
      return state.toSpliced(indexOfRecord,1)
    default:
    return state;
  }
};

// reducer to track values of room select
const roomSelectValueReducer = (state, action) => {
  let indexOfRecord 
  switch (action.type) {
  case "SET-ROOMS":
    indexOfRecord = state.findIndex((RoomsSelected: Omit<IRoomSelectDataState,'label'>)=> RoomsSelected.recordid === action.payload.key)
    if( indexOfRecord === -1){
      // if no room record was found
      return [...state, {recordid: action.payload.key, value: [action.payload.roomId]}]
    }else{
      return state.map((RoomsSelected: Omit<IRoomSelectDataState,'label'>) => {
        if (RoomsSelected.recordid === action.payload.key) {
          return { ...RoomsSelected, value: [...RoomsSelected.value, action.payload.roomId]};
        } else {
          return RoomsSelected;
        }
      });
    }
    case "DESET-ROOMS":
      // when user deselect rooms
      return state.map((RoomsSelected: Omit<IRoomSelectDataState,'label'>) => {
        if (RoomsSelected.recordid === action.payload.key) {
          let roomIndexToRemove = RoomsSelected.value.findIndex((singleValue)=> singleValue === action.payload.roomId)
          let copy =[...RoomsSelected.value]
          copy.splice(roomIndexToRemove, 1)
          return { ...RoomsSelected, value: copy}
        } else {
          return RoomsSelected;
        }
    });
    case "RESET-ROOMS":
      indexOfRecord = state.findIndex((RoomsSelected: Omit<IRoomSelectDataState,'label'>)=> RoomsSelected.recordid === action.payload.key)
      if( indexOfRecord === -1){
        // if no room record was found
        return [...state, {recordid: action.payload.key, value: []}]
      }
      return state.map((RoomsSelected: Omit<IRoomSelectOption,'label'>) => {
        if (RoomsSelected.recordid === action.payload.key) {
          return { ...RoomsSelected, value: [] };
        } else {
          return RoomsSelected;
        }
      });
    case "DELETE-ROOMS":
      indexOfRecord = state.findIndex((RoomsSelected: Omit<IRoomSelectDataState,'label'>)=> RoomsSelected.recordid === action.payload.key)
      return state.toSpliced(indexOfRecord,1)
    default:
      return state;
  }
};

export const CreateGroupModal = ({
visible,
onOk,
onCancel,
accessToken,
}: CreateGroupModalProps) => {
const [form] = Form.useForm()
const [loading, setLoading] = useState(false)
const [tableRowCount, setTableRowCount] = useState<number>(1);
const [locations, setLocations] = useState<ILocationSelectOption[]>([]);
// const [constLocations, setconstLocations] = useState<ILocationSelectOption[]>([]);
const [locationSelectValue, setLocationSelectValue] = useReducer<(state: Omit<ILocationSelectOption,'label'>[], action: any)=>any >(locationSelectValueReducer, []);
const [entranceSelectValue, setEntranceSelectValue] = useReducer<(state: Omit<IEntranceSelectOption,'label'>[], action: any)=>any >(entranceSelectValueReducer, []);
const [roomSelectValue, setRoomSelectValue] = useReducer<(state: Omit<IRoomSelectDataState,'label'>[], action: any)=>any >(roomSelectValueReducer, []);
const [combinedDataSources, dispatch] = useReducer(locationTableSelectDataReducer, [])
const [pagination, setPagination] = useState<TablePaginationType>(initPagination)

const handleLocationRecordDeletion = (key: string)=>{
  dispatch({type: "DELETE-RECORD", payload: {key} })
  setEntranceSelectValue({type: 'DELETE-ENTRANCES', payload: {key}})
  setRoomSelectValue({type: 'DELETE-ROOMS', payload: {key}})
}

const handleLocationSelect = (locationId: number, key: string)=>{
  // key passed in from location option
  fetchEntrances(accessToken, locationId, key);
  // reset both entrance and rooms selects values when user changed location
  setEntranceSelectValue({type: 'RESET-ENTRANCES', payload: {key}})
  // remove all the rooms select options
  dispatch({type: 'REMOVE-ROOMS', payload: {key}})
  setLocationSelectValue({type: 'SET-LOCATION', payload: {key, locationId: locationId}})
  // reset rooms selected values in roomsSelected
  // once user has selected a location, we should remove this location from state locations, do not change constLocations
  // let indexOfLocationToRemove = constLocations.findIndex((location)=> location.value === locationId)
  // console.log('removing ', indexOfLocationToRemove, locationId)
  // let copyLocations = [...constLocations];
  // copyLocations.splice(indexOfLocationToRemove,1);
  // setLocations(copyLocations);
}

const handleEntranceSelect = (entranceId: number, key: string)=>{
  // key passed in from entrance option
  console.log('selecting entrance ', entranceId, key)
  fetchRooms(accessToken, entranceId, key);
  setEntranceSelectValue({type: 'SET-ENTRANCE', payload: {key, entranceId}})
  // reset rooms values when user changed entrance
  setRoomSelectValue({type: 'RESET-ROOMS', payload: {key}})
  dispatch({type: 'REMOVE-ROOMS', payload: {key}})
}

const handleRoomsSelect= (value: number, key: string)=>{
  // key passed in from room option
  console.log('selecting room ', value, key)
  setRoomSelectValue({type: 'SET-ROOMS', payload: {key, roomId: value}})
}

const handleRoomsDeSelect= (value: number, key:string)=>{
  console.log("deselecting room ", value, key, roomSelectValue)
  setRoomSelectValue({type: 'DESET-ROOMS', payload: {key, roomId: value}})
}
  
const handleAdd = () => {
  // create a new uuid when user added a new location
  const recordUUID = uuidV4();
  let newLocation = locations.map((location)=> ({...location, recordid: recordUUID}))
  const newRecord:AddLocationTableData = {locations: newLocation, key: recordUUID}
  dispatch({type: 'INIT-RECORD', payload: newRecord})
  // init a new entrance record 
  setEntranceSelectValue({type: 'SET-ENTRANCE', payload: {key: recordUUID, entranceId: undefined}})
  setTableRowCount(count => count+=1);
  console.log('table row count is ', tableRowCount)
  setPagination({...pagination, total: tableRowCount+1})
};

function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}

const handleSubmit = async () => {
  setLoading(true)
  console.log('final combined state is ', combinedDataSources)
  console.log('final edit location state is ', locationSelectValue);
  console.log('final entrance state is ', entranceSelectValue)
  console.log('final room state is ', roomSelectValue)
  try {
    const {group_name, grp_description} = form.getFieldsValue();
    let payload = {name: group_name, description: grp_description, rooms: []};
    const hasError = await form
    .validateFields()
    .then(() => true)
    .catch(() => false)

    if (hasError === false) throw new Error('')
    let roomsSelectedArray = roomSelectValue.reduce((accumulator, currentValue) => {
      accumulator.push(...currentValue.value);
      return accumulator
    }, []);
    let uniqueEntries = roomsSelectedArray.filter(onlyUnique)
    console.log("it is ", uniqueEntries)

    let roomsPayload = uniqueEntries.map((entry)=> ({roomId: entry}))
    payload.rooms = roomsPayload;
    await GroupsAPI.create(accessToken, payload)
    message.success('Your group has been created successfully');
    //form.resetFields();
    onOk();
    setLoading(false)
  } catch (error) {
    if (error.message) {
      message.error(error.message)
    }
    setLoading(false)
  }
}

const tagRender = (props: any) => {
  const { label, onClose,  } = props;
    return (
      <Tag
      color={'#2C4B9C'}
      closable={true}
      onClose={onClose}
      style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );  
};

const columns: ColumnType<AddLocationTableData>[] = [
{
    width: '30%',
    title: 'Location',
    align: 'left',
    key: 'locations',
    dataIndex: 'locations',
    ellipsis: { showTitle: true },
    render: (_, {locations}) => {
        let selectValue = undefined;
        if(locations && locations[0].recordid){
          locationSelectValue.forEach((item)=> { 
            if(item.recordid === locations[0].recordid){
              selectValue = item.value;
            }
          })
        }
        return(
          // Select takes data in the form of value and label
          <Select
            showSearch
            placeholder="Select a location"  
            value={selectValue}
            optionFilterProp="label"
            onSelect={(_,record)=> handleLocationSelect(record.value, record.recordid)}
            filterOption={(input, option) =>
              (option?.label as string ?? "").toLowerCase().includes(input.toLowerCase())
          } 
          options={locations}>
          </Select>
        )
        // how about no location? 
    }
    },
    {
    width: '30%',
    title: 'Entrance',
    align: 'left',
    key: 'entrances',
    dataIndex: 'entrances',
    ellipsis: { showTitle: true },
    render: (_, {entrances}) => {
        // this function will get called everytime the underlying option data changes or state changes 
        let selectValue = undefined;
        if(entrances && entrances[0].recordid){
          entranceSelectValue.forEach((item)=> { 
            if(item.recordid === entrances[0].recordid){
              selectValue = item.value;
            }
          })
        }
        return (
          <Select 
            showSearch
            placeholder="Select an entrance"  
            optionFilterProp="label"  
            value={selectValue}
            showArrow={true}
            onSelect={(_,record)=> handleEntranceSelect(record.value, record.recordid)}
            filterOption={(input, option) =>
              (option?.label as string ?? "").toLowerCase().includes(input.toLowerCase())
            } 
            options={entrances}>
          </Select>
        )}
    },
    {
      width: '30%',
      title: 'Room(s)',
      align: 'left',
      key: 'rooms',
      dataIndex: 'rooms',
      ellipsis: true,
      render: (_, {rooms}) => {
          let selectValue = undefined;
          if(rooms && rooms[0].recordid){
            roomSelectValue.forEach((item)=> { 
              if(item.recordid === rooms[0].recordid){  
                selectValue = item.value;
              }
            })
          }
          return(
            <Select
              mode="multiple"
              tagRender={tagRender}
              placeholder="Select rooms" 
              showArrow={true}
              onSelect={(_,record)=> handleRoomsSelect(record.value, record.recordid)}
              onDeselect={(_,record)=> handleRoomsDeSelect(record.value, record.recordid)}
              value={selectValue}
              options={rooms}>
            </Select>
        )
      }
    },
    {
    width: '10%',
    title: 'Action',
    key: 'action',
    align: 'center',
    render: (_, record) => 
    (
      <Popconfirm okText='Yes'cancelText="No" title="Sure to delete?" onConfirm={() => handleLocationRecordDeletion(record.key)}>
        <a className='danger-color'>Delete</a>
      </Popconfirm>
    ),
    }
]

const fetchLocations = (token: string = accessToken) => {
  LocationAPI.listV2(token)
  .then((items: ILocationAPIV2[]) => {
    const recordUUID = uuidV4();
    // every record should have a recordid
    let locationItems = items.map((item: ILocationAPIV2)=> ({
      value: item.locationId,
      label: item.locationName,
      recordid: recordUUID,
    }))
    // every row should have a key which is recordid
    const newRecord:AddLocationTableData = {locations: locationItems, key: recordUUID}
    console.log("new record is ", newRecord)
    // set locations since locations should always be the same 
    setLocations(locationItems);
    // do not mutate this
    // setconstLocations(locationItems); 
    // init a new record for a new row 
    dispatch({type: 'INIT-RECORD', payload: newRecord})
    // init a new entrance record for a new row
    setEntranceSelectValue({type: 'SET-ENTRANCE', payload: {key: recordUUID, entranceId: undefined}})
})
.catch((error: any) => {
  message.error(error.message)
})
}

const fetchEntrances = (token: string = accessToken, locationId: number, recordid: string) => {
  EntranceAPI.getEntrancesByLocationId(token, locationId)
  .then((items: IEntranceV2[]) => {
    let entranceItems = items.map((item: IEntranceV2)=> ({
      value: item.entranceId,
      label: item.entranceName,
      recordid,
    }))
    // every row should have a key which is recordid, add new entrance select options for this record
    dispatch({type: 'ADD-ENTRANCES', payload: {entrances: entranceItems, key: recordid}})
    // init a new record for rooms if needed
    setRoomSelectValue({type: 'RESET-ROOMS', payload: {key: recordid }})
  })
  .catch((error: any) => {
    setRoomSelectValue({type: 'RESET-ROOMS', payload: {key: recordid }})
    dispatch({type: 'REMOVE-ROOMS', payload: {key: recordid}})
    dispatch({type: 'REMOVE-ENTRANCES', payload: {key: recordid}})
    message.error(error.message)
  })
}

const fetchRooms = (token: string = accessToken, entranceId: number, recordid: string) => {
  RoomsAPI.getRoomsByEntrance(token, entranceId)
  .then((items: []) => {
    let roomItems = items.map((item: IRoomAPIV2)=> ({
      value: item.roomId,
      label: item.roomName,
      recordid,
    }))
    // set options for Select for rooms of a particular row
    dispatch({type: 'ADD-ROOMS', payload: {rooms: roomItems, key: recordid }})
  })
  .catch((error: any) => {
    message.error(error.message)
  })
}


const handleChangePage = (pagination: any) => {
  setPagination({ ...pagination })
}

useEffect(() => {
  // fetch data for initial load, only fetch location once
  fetchLocations(accessToken)
}, [])

return (
  <Modal
    width = '60%'
    title={'Create A Room Group'}
    centered
    visible={visible}
    onOk={handleSubmit}
    onCancel={onCancel}
    maskClosable={false}
    confirmLoading={true}
    footer={[
      <Button key="back" className="btn-cancel" onClick={onCancel}>
        Cancel
      </Button>
      ,
      <Button
        loading={loading}
        key="submit"
        className="btn-save"
        onClick={handleSubmit}
      >
        Save
      </Button>
    ]}
  > 
  <p> You can only add users after creating your room group.</p>
  <Form form={form} {...layout} onFinish={handleSubmit}>
    <Form.Item
      name="group_name"
      label="Group Name"
      labelAlign='left'
      rules={[{ required: true, message: 'Name is required' }]}
    >
      <Input placeholder="Your Group Name" />
    </Form.Item>
    <Form.Item
      name="grp_description"
      label="Description"
      labelAlign='left'
      rules={[{ required: true, message: 'Description is required' }]}
    >
      <Input.TextArea placeholder="Describe what this group is about." />
    </Form.Item>
    <Form.Item
      name="add_location_button"
    >
    <Button size='small' onClick={handleAdd}> <PlusOutlined/> Add Location</Button>
    </Form.Item>
    <Form.Item name="add_location_table">
      <Table 
        className="groups-table"
        columns={columns}
        dataSource={combinedDataSources}
        pagination={pagination}
        onChange={handleChangePage}
      />
    </Form.Item> 
  </Form>

  </Modal>
)
}
