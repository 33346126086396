import React, { Dispatch, SetStateAction } from 'react'
import './style.less'

import {
  Checkbox,
  Col,
  Input,
  Radio,
  Row,
  Form,
  InputNumber,
  Select,
} from 'antd'
import { EnterOutlined } from '@ant-design/icons'
import { FormInstance } from 'antd/lib/form'
import { AnswerType, CheckListQuestion } from 'api/Main'
import { Declaration } from 'pages/PublicPages/DeclarationPage'

function isIOsDevice() {
  const ua = navigator.userAgent.toLowerCase()
  // if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
  //   return "tablet";
  // }
  // if (
  //   /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
  //     ua
  //   )
  // ) {
  //   return "mobile";
  // }
  // return "desktop";
  return /ip(hone|od)/.test(ua)
}

export const IsDangerousAnswer = (value: any, negativeConditions: any[]) => {
  try {
    // console.log(value, negativeConditions)
    const isNegative = negativeConditions?.map((condition) => {
      // eslint-disable-next-line no-new-func
      const excutioner = new Function(
        `return (${value} ${condition.operator} ${condition.value});`
      )
      return excutioner()
    })

    return !!isNegative?.filter((e) => !!e).length
  } catch (err) {
    return false
  }
}

export type DeclarationCheckListProps = {
  checklist: CheckListQuestion[]
  onChange: (changed: any) => void
  declarations: {
    [key: number]: Declaration
  }
  form?: FormInstance<any>
  isDangerous: boolean
  setDangerous: Dispatch<SetStateAction<boolean>>
  handelOutFocus: () => void
}

export const DeclarationCheckList: React.FC<DeclarationCheckListProps> = ({
  form,
  checklist,
  onChange,
  setDangerous,
  handelOutFocus,
}) => {
  return (
    <div className="declaration-check-list">
      {/* <ul className="check-list d-flex flex-column"> */}
      <Form
        form={form}
        layout="vertical"
        colon={false}
        requiredMark={false}
        className="check-list d-flex flex-column"
      >
        {checklist.map((item, index) => {
          const order = index + 1
          const handleChangeField = (value: any, hasError?: boolean) => {
            onChange({
              [item.question_id]: {
                title: item.title,
                answer: value,
                isValid: hasError ? false : true,
                answer_type: item.answer_type,
                negative_conditions: item.negative_conditions,
              },
            })
          }

          switch (item.answer_type.toLowerCase()) {
            case AnswerType.Boolean: {
              return (
                <Form.Item
                  label={
                    <div>
                      <b>
                        {order}. {item.title}
                      </b>
                      {item.description && (
                        <ul className="description-list">
                          {item.description
                            .split('\\n')
                            .map((e: string, index: number) => (
                              <li key={index}>{e}</li>
                            ))}
                        </ul>
                      )}
                    </div>
                  }
                  rules={[
                    { required: true, message: 'This answer is required!' },
                  ]}
                  name={`[${item.question_id}] ${item.title}`}
                  key={item.question_id}
                >
                  <Radio.Group
                    onChange={(e) => {
                      handleChangeField(e.target.value)
                      setDangerous(
                        IsDangerousAnswer(
                          e.target.value,
                          item.negative_conditions
                        )
                      )
                    }}
                    className="btn-group d-flex"
                  >
                    <Radio.Button
                      className="btn-group__item d-flex"
                      value={false}
                    >
                      NO
                    </Radio.Button>
                    <Radio.Button
                      className="btn-group__item d-flex"
                      value={true}
                    >
                      YES
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
              )
            }
            case AnswerType.Decimal: {
              return (
                <Form.Item
                  label={
                    <div>
                      <b>
                        {order}. {item.title}
                      </b>
                      {item.description && (
                        <small className="description-list">
                          {item.description
                            .split('\\n')
                            .map((e: string, index: number) => (
                              <li key={index}>{e}</li>
                            ))}
                        </small>
                      )}
                    </div>
                  }
                  rules={[
                    { type: 'number', message: 'Please valid number!' },
                    { required: true, message: 'This answer is required!' },
                  ]}
                  name={`[${item.question_id}] ${item.title}`}
                  key={item.question_id}
                >
                  <InputNumber
                    type={!isIOsDevice ? 'tel' : ''}
                    onChange={(value) => {
                      handleChangeField(value)
                      if (IsDangerousAnswer(value, item.negative_conditions)) {
                        setDangerous(true)
                      }
                    }}
                  />
                </Form.Item>
              )
            }
            case AnswerType.CheckBox: {
              return (
                <Form.Item
                  label={
                    <div>
                      <b>
                        {order}. {item.title}
                      </b>
                      {item.description && (
                        <ul className="description-list">
                          {item.description
                            .split('\\n')
                            .map((e: string, index: number) => (
                              <li key={index}>{e}</li>
                            ))}
                        </ul>
                      )}
                    </div>
                  }
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value.length) {
                          return Promise.resolve()
                        }
                        return Promise.reject('This answer is required!')
                      },
                    },
                  ]}
                  name={`[${item.question_id}] ${item.title}`}
                  key={item.question_id}
                  initialValue={[]}
                >
                  <Checkbox.Group
                    className="checkbox-list"
                    style={{ width: '100%' }}
                    onChange={(e) =>
                      handleChangeField(e.join('__'), e.length === 0)
                    }
                  >
                    <Row>
                      {item.answers &&
                        item.answers.map((el: any, index: number) => (
                          <Col span={24} key={index}>
                            <Checkbox
                              className="checkbox-list__item"
                              value={el.answer}
                            >
                              {el.answer}
                            </Checkbox>
                          </Col>
                        ))}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              )
            }
            case AnswerType.SelectBox: {
              return (
                <Form.Item
                  label={
                    <div>
                      <b>
                        {order}. {item.title}
                      </b>
                      {item.description && (
                        <ul className="description-list">
                          {item.description
                            .split('\\n')
                            .map((e: string, index: number) => (
                              <li key={index}>{e}</li>
                            ))}
                        </ul>
                      )}
                    </div>
                  }
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value.length) {
                          return Promise.resolve()
                        }
                        return Promise.reject('This answer is required!')
                      },
                    },
                  ]}
                  name={`[${item.question_id}] ${item.title}`}
                  key={item.question_id}
                  initialValue={''}
                >
                  <Select
                    className="checkbox-list"
                    style={{ width: '100%' }}
                    placeholder={'Select a answer'}
                    onChange={(e) => handleChangeField(e)}
                    dropdownRender={(menu) => {
                      const handleOtherOption = (value: any) => {
                        handleChangeField(value)
                        form.setFieldsValue({
                          [`[${item.question_id}] ${item.title}`]: value,
                        })

                        // Cheating *LOL
                        handelOutFocus()
                      }
                      return (
                        <>
                          {menu}
                          <div
                            className="others-option"
                            style={{
                              display: 'flex',
                              flexWrap: 'nowrap',
                              padding: 8,
                            }}
                          >
                            <Input.Search
                              style={{ flex: 'auto', borderRadius: 0 }}
                              placeholder={item.others_text}
                              enterButton={<EnterOutlined />}
                              onSearch={handleOtherOption}
                            />
                          </div>
                        </>
                      )
                    }}
                  >
                    {item.answers &&
                      item.answers.map((el: any, index: number) => (
                        <Select.Option
                          // className="checkbox-list__item"
                          key={index}
                          value={el.answer}
                        >
                          {el.answer}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              )
            }
            default: {
              return (
                <Form.Item
                  label={
                    <div>
                      <b>
                        {order}. {item.title}
                      </b>
                      {item.description && (
                        <ul className="description-list">
                          {item.description
                            .split('\\n')
                            .map((e: string, index: number) => (
                              <li key={index}>{e}</li>
                            ))}
                        </ul>
                      )}
                    </div>
                  }
                  rules={[
                    { required: true, message: 'This answer is required!' },
                  ]}
                  name={`[${item.question_id}] ${item.title}`}
                  key={item.question_id}
                >
                  <Input
                    onChange={(e) => {
                      handleChangeField(e.target.value)
                    }}
                  />
                </Form.Item>
              )
            }
          }
        })}
      </Form>
    </div>
  )
}
