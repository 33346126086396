const apiURL = process.env.REACT_APP_API_URL;
const urlPrefix = `${apiURL}/api/v2`;

export interface IRoom {
    id: number
    name: string
    entrance_id: number
}

export interface IRoomAPIV2 {
  roomId: number,
  roomName: string
  entranceId?: string,
  locationId?: string
}

export const RoomsAPI = {
   async list(url: string, entrance?:string): Promise<any> {
    const resp = await fetch(`${urlPrefix + url}`,
      {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
      });
    const response = await resp.json();
    return response;
  },
  async getRoomsByEntrance(token:string, entranceId?:number): Promise<any> {
    const res = await fetch(`${urlPrefix}/getRoomsByEntrance`, {
      credentials: 'include',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({entranceId})
    })
    const { status, data, message } = await res.json()
    if (status === false) throw Error(message || 'Get rooms by entrance failed')
    return data.rooms
  },
};

