import { PaginationQueryType } from "./Location";

const apiURL = process.env.REACT_APP_API_URL;
const url = `${apiURL}/api/v1/admins`;
const urlV2 = `${apiURL}/api/v2/getAdmins`;

export const AdminAPI = {
  async list(token: string): Promise<any> {
    const res = await fetch(`${url}`,
      {
        credentials: 'include',
        headers: {
          "Authorization": token
        }
      });
    const response = await res.json();
    if (response && response.status) {
      const { data } = response;
      return data;
    }
    return response;
  },

  async listV2(token: string, params: any): Promise<any> {
    const resp = await fetch(urlV2, {
      credentials: 'include',
      method: `POST`,
      headers: {
        "Authorization": token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    })
    const response = await resp.json()
    return response
  },
};
