import React, { useContext, useEffect, useReducer } from 'react'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'

import { LocationParamsProvider } from '../contexts/LocationParams'
// import { withLocation } from '../HOCs/withLocation'
import { withEvent } from '../HOCs/withEvent'
import { Header } from '../components/layouts/Header'

// Layouts
import AuthWrap from '../layouts/Auth'
import IdleTimerWrap from '../layouts/IdleTimer'

// Components
import Dashboard from '../components/Dashboard'

// Hooks
import useAuthUser from '../hooks/useAuthUser'
import useAuthRole from '../hooks/useAuthRole'
import useNavigation, { RoutePaths } from '../hooks/useNavigation'

// Public pages
import { MainPage } from '../pages/PublicPages/MainPage'
import { CompletePage } from '../pages/PublicPages/CompletePage'
import { EventCompletePage } from '../pages/PublicPages/EventCompletePage'
import { EnrolPage } from '../pages/PublicPages/EnrolPage'
import { DeclarationPage } from '../pages/PublicPages/DeclarationPage'
import { TermOfUsePage } from '../pages/PublicPages/TermsOfUsePage'
import { FourZeroFourPage } from '../pages/PublicPages/FourZeroFourPage'
import { EventDeclarationPage } from '../pages/PublicPages/EventDeclarationPage'

// Admin pages
import LoginPage from '../pages/AdminPages/LoginPage'
import SessionExpire from '../pages/AdminPages/SessionExpire'

//AP pages
import VisitorListPage from '../pages/APPages/VisitorListPage'
import APLoginPage from '../pages/APPages/LoginPage'
import APAuthCallbackPage from '../pages/APPages/AuthCallbackPage'
import { Skeleton } from 'antd'

// config
import { ROLES } from '../config';

export function Routes() {
  const [user, loggedIn, dispatch] = useAuthUser()
  const [role] = useAuthRole(user?.accessToken)
  const navigation = useNavigation(role)
  const firstRoute = navigation.find((item) => item.permitted)
  const isOneRole = navigation.filter((item) => item.permitted).length === 1

  return (
    <Router>
      <Switch>
        {/* Admin pages */}
        <Route path={RoutePaths.ADMIN}>
          <Switch>
            <Route path={RoutePaths.ADMIN_LOGIN}>
              {loggedIn ?
                (
                  <Redirect
                    to={(isOneRole && firstRoute?.key) || RoutePaths.ADMIN}
                  />
                ) :
                <LoginPage />
              }
            </Route>
            <Route path={RoutePaths.ADMIN_SESSION_EXPIRE} component={SessionExpire}></Route>
            <Route path={"*"}>
              {(loggedIn && (role === null || undefined)) && <Skeleton active />}
              {/* {role === null ? <Redirect to="/error" /> : <Redirect to="/" />} */}
                <AuthWrap dispatch={dispatch} role={role} >
                  {loggedIn && (role && (role.name === ROLES.GE_ADMIN.name || role.name === ROLES.GE_SUPER_ADMIN.name)) && (
                    <Dashboard authDispatch={dispatch} user={user} role={role} navigation={navigation}>
                      <IdleTimerWrap authDispatch={dispatch} />
                      <Switch>
                        {navigation.map(({ page: Page, ...nav }: any) => (
                          <Route
                            exact={nav.exact ?? false}
                            path={nav.key}
                            key={nav.key}
                          >
                            {nav.permitted ? (
                              <Page />
                            ) : (
                              <Redirect
                                to={(isOneRole && firstRoute?.key) || '/'}
                              />
                            )}
                          </Route>
                        ))}
                      </Switch>
                    </Dashboard>
                  )}
                </AuthWrap>
            </Route>
          </Switch>
        </Route>

        {/* AP pages */}
        <Route path={RoutePaths.AP_LOGIN}>
          <APLoginPage />
        </Route>
        <Route path={RoutePaths.AP_AUTH_CALLBACK}>
          <APAuthCallbackPage authDispatch={dispatch} />
        </Route>
        <Route path={RoutePaths.AP_VISITORS}>
          <VisitorListPage authDispatch={dispatch} userData={user} />
        </Route>
        <Route path={RoutePaths.AP_LANDING}>
          <Redirect to={RoutePaths.AP_LOGIN} />
        </Route>

        {/* Public pages */}
        <Route path={'/'}>
          <LocationParamsProvider>
            <Header title="A Republic Of Singapore Air Force Website" />
            <Switch>
              <Route
                path={RoutePaths.INDEX}
                exact
                render={() => (
                  <Redirect to={RoutePaths.ENROL} />
                )}
              />
              <Route
                path={RoutePaths.TERMS_OF_USER}
                component={TermOfUsePage}
              />
              <Route path={RoutePaths.MAIN} exact component={MainPage} />
              <Route path={RoutePaths.ENROL} component={EnrolPage} />
              <Route
                path={RoutePaths.DECLARATION}
                component={(DeclarationPage)}
              />
              <Route
                path={RoutePaths.COMPLETE}
                component={(CompletePage)}
              />
              <Route
                path={RoutePaths.EVENT}
                component={withEvent(EventDeclarationPage)}
              />
              <Route
                path={RoutePaths.EVENT_COMPLETE}
                component={EventCompletePage}
              />
              <Route path="*" component={FourZeroFourPage} />
              {/* <Route
                path={RoutePaths.COMPLETE}
                component={withLocation(CompletePage)}
              /> */}
            </Switch>
          </LocationParamsProvider>
        </Route>
      </Switch>
    </Router>
  )
}
