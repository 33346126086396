const apiURL = process.env.REACT_APP_API_URL;
const url = `${apiURL}/api/v2/getpurposes`;

export const getPurposesAPI = {
  async list(): Promise<any> {
    const resp = await fetch(`${url}`,
      {
        method: 'GET',
        headers: {
                  'Content-Type': 'application/json',
                  'Content-Length': '0',
        },
      });
    const response = await resp.json();
    return response;
  },
};
