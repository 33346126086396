import React, { useEffect, useState } from 'react'

import {
  Table,
  Row,
  Card,
  Col,
  Space,
  Typography,
  Button,
  Modal,
  message,
  Tooltip,
  Input,
} from 'antd'
import { UploadOutlined } from '@ant-design/icons'; 

import { ColumnType } from 'antd/es/table'
import './style.less'

import { User, UserAPI } from 'api/User'
import { AdminAPI } from 'api/AdminGe'

import { ROLES } from 'config'

import useAuthUser from 'hooks/useAuthUser'
import useAuthRole from 'hooks/useAuthRole'
import CreateUserModal from './Modal/CreateUserModal'
import UploadUsersCsvModal from './Modal/UploadUsersCsvModal';


const { Title } = Typography

enum MODAL_TAG {
  CREATE_USER = 'CREATE_USER',
  EDIT_USER = 'EDIT_USER',
  UPLOAD_CSV = 'UPLOAD_CSV'
}

type TablePaginationType = {
  current: number
  pageSize: number
  total: number
}

const initPagination: TablePaginationType = {
  pageSize: 8,
  current: 1,
  total: 0,
}

export const UserManagement = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [users, setUsers] = useState<User[]>(null!)
  const [loading, setLoading] = useState(false)
  const [user] = useAuthUser()
  const [role] = useAuthRole(user?.accessToken)
  const [pagination, setPagination] = useState<TablePaginationType>(initPagination)
  const [searchTerm, setSearchTerm] = useState("")

  const { Search } = Input

  const fetchListAdmin = async (token: string, pagination: TablePaginationType, searchTerm: string = "") => {
    setLoading(true)
    AdminAPI.listV2(token, {
      itemsPerPage: pagination.pageSize,
      page: pagination.current,
      search: searchTerm,
      roles: ["ge-super-admin", "ge-admin", "ge-user"],
    })
      .then((resp) => {
        if (resp && resp.status) {
          const { data: items, total = 0 } = resp
          setLoading(false)
          setUsers(items)
          setPagination({ ...pagination, total })
        } else {
          message.error("There seems to be an issue at this moment. Try again.")
        }
      })
      .catch((error: any) => {
        setLoading(false)
        message.error(error.message)
      })
  }

  const getUserNameDisplay = (user: User) => {
      if (user !== (null || undefined) && user.nric !== (null || undefined) && user.phone !== (null || undefined)) {
        return user.name + ` (${user.nric.slice(0, 1) + user.nric.slice(1).replace(/.(?=....)/g, '*')}, ${user.phone.slice(0, 1) + user.phone.slice(1).replace(/.(?=....)/g, '*')})`
      } else {
        if (user.phone[0] === '+') {
          return user.phone.slice(0, 4) + user.phone.slice(3).replace(/.(?=.....)/g, '*')
        } else if (user.phone[0] >= '0' && user.phone[0] <= '9') {
          return user.phone.slice(0, 1) + user.phone.slice(1).replace(/.(?=.....)/g, '*')
        } else {
          return user.phone
        }
      }
  }

  useEffect(() => {
    if (user) {
      fetchListAdmin(user?.accessToken, initPagination)
    }
  }, [user, role])

  const handleChangePage = (pagination: any) => {
    fetchListAdmin(user?.accessToken, pagination)
  }

  const handleSearch = (keyword) => {
    fetchListAdmin(user?.accessToken, initPagination, keyword)
    setSearchTerm(keyword)
  }

  // handle edit user
  // const [selectedUser, setSelectedUser] = useState<User>(null!)

  // handle modal
  const [visibleModal, setVisibleModal] = useState<MODAL_TAG | null>(null)

  const openModal = (type: MODAL_TAG | null) => {
    setVisibleModal(type)
  }

  const closeModal = () => {
    setVisibleModal(null)
  }

  const handleOK = (value: any) => {
    setLoading(true)
    UserAPI.create(user?.accessToken, value)
      .catch((error: any) => {
        message.error(error.message)
      })
      .finally(() => {
        setLoading(false)
        closeModal()
        fetchListAdmin(user?.accessToken, initPagination)
      })
  }

  const handleUploadSuccess = function(){
    closeModal();
    messageApi.open({
      type: 'success',
      content: 'User csv upload successful.',
      duration: 2,
    });
    fetchListAdmin(user?.accessToken, pagination);
  }
  const handleUploadError = function(fileName){
    closeModal();
    messageApi.open({
      type: 'error',
      content: `${fileName} has not been imported successfully. Check if data is in the required format.`,
      duration: 3,
    });
  }
  // handle action
  const handleDeleteUser = (record: User) => {
    Modal.confirm({
      title: 'Delete user',
      content: `Are you sure you want to delete "${getUserNameDisplay(record)}"? You cannot undo this.`,
      icon: null,
      okText: 'Confirm',
      okType: 'default',
      okButtonProps: { className: 'btn-create' },
      cancelText: 'No',
      cancelButtonProps: { className: 'btn-default' },
      centered: true,
      maskClosable: false,
      async onOk() {
        // handle change confirm status
        try {
          const messageRes = await UserAPI.delete(user?.accessToken, record.id, record.phone)
          message.success(messageRes)
          fetchListAdmin(user?.accessToken, initPagination)
        } catch (error) {
          message.error(error.message)
        }
      },
      onCancel() {},
    })
  }

  const columns: ColumnType<User>[] = [
    {
      width: '15%',
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      ellipsis: { showTitle: true },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      width: '40%',
      title: 'Name (NRIC, Phone No.)',
      key: 'identity',
      dataIndex: 'identity',
      ellipsis: { showTitle: true },
      render: (value = 'N/A', record) => (
        <Tooltip placement="topLeft" title={getUserNameDisplay(record)}>
          {getUserNameDisplay(record)}
        </Tooltip>
      ),
    },
    {
      width: '20%',
      title: 'Role',
      key: 'roleId',
      dataIndex: 'roleId',
      render: (value) => {
        return (
          Object.values(ROLES).find((e: any) => e.id === value)?.label ??
          'Unknown'
        )
      },
    },
    {
      width: '15%',
      title: 'Action',
      key: 'action',
      align: 'center',
      render: (_, record) => {
        return (
          <Space>
            {/* <Button
              className="btn-edit"
              shape="round"
              onClick={() => handleOpenModal(MODAL_TAG.EDIT_USER)}
            >
              Edit
            </Button> */}
            <Button
              type="link"
              className='delete-button-plain'
              onClick={() => handleDeleteUser(record)}
            >
              Delete
            </Button>
          </Space>
        )
      },
    },
  ]

  return (
    <>
    {contextHolder}
    <Row className="">
      <Col span={24}>
        <Card
          loading={loading}
          title={
            <Title style={{ color: '#6b6b6b' }} level={2}>
              User Roles
            </Title>
          }
          extra={
            <Space>
              <Button
                type="default"
                icon={<UploadOutlined />}
                size="middle"
                onClick={() => openModal(MODAL_TAG.UPLOAD_CSV)}
              >
                Import CSV
              </Button>
              <Button
                className="btn-create"
                shape="round"
                type="primary"
                onClick={() => openModal(MODAL_TAG.CREATE_USER)}
              >
                Create
              </Button>

            </Space>
          }
        >
          <Row>
          View, manage and search for users.
          </Row>
          <Row>
          <Search className='title-search-bar' placeholder="Search user's name" onSearch={handleSearch} allowClear defaultValue={searchTerm}/>
          </Row>
          <Table
            className="user-table"
            pagination={pagination}
            columns={columns}
            dataSource={users}
            onChange={handleChangePage}
            rowKey={(record) => record.id}
          />
        </Card>
      </Col>
      <CreateUserModal
        // accessToken={user?.accessToken}
        visible={visibleModal === MODAL_TAG.CREATE_USER}
        onOk={handleOK}
        onCancel={closeModal}
      />
      {visibleModal === MODAL_TAG.UPLOAD_CSV && <UploadUsersCsvModal
        accessToken={user?.accessToken}
        visible={visibleModal === MODAL_TAG.UPLOAD_CSV}
        onUploadSuccess={handleUploadSuccess}
        onUploadError={handleUploadError}
        onCancel={closeModal}
      />}
      {/* <EditModal
        accessToken={user?.accessToken}
        visible={visibleModal === MODAL_TAG.EDIT_USER}
        onOk={handleOK}
        onCancel={closeModal}
        user={userSelected}
      /> */}
    </Row>
    </>
  )
}

export default UserManagement
