import { Modal, Form, Input, message, Button, Select } from 'antd'
import React, { useState } from 'react'
import { Entrance, EntranceAPI } from 'api/Entrance'
import { Location } from 'api/Location'
import { Template } from 'api/Template'

const { Option } = Select

export type CreateEntranceModalProps = {
  visible: boolean
  roleName: string
  onOk: () => void
  onCancel: () => void
  accessToken: string
  location: Location | null
  templates: Template[]
}

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

export const CreateEntranceModal = ({
  visible,
  roleName,
  onOk,
  onCancel,
  accessToken,
  location,
  templates,
}: CreateEntranceModalProps) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const handleSubmit = async () => {
    setLoading(true)
    try {
      if (!location) throw new Error('Location not found')

      const { ...entrance } = form.getFieldsValue() as Entrance

      const hasError = await form
        .validateFields()
        .then(() => true)
        .catch(() => false)

      if (hasError === false) throw new Error('')

      await EntranceAPI.create(accessToken, {
        ...entrance,
        location_id: location.id,
      }).then((resp) => {
        if (resp && resp.status) {
          const text = (roleName === 'ge-super-admin') ? `Your entrance ${entrance.name} has been created successfully.` : `Your request to create ${entrance.name} has been submitted for approval.`
          message.success(text)
        } else {
          message.error("There seems to be an issue at this moment. Try again.")
        }
      })

      onOk()
      form.resetFields()
      setLoading(false)
    } catch (error) {
      if (error.message) {
        message.error("There seems to be an issue at this moment. Try again.")
      }
      setLoading(false)
    }
  }

  return (
    <Modal
      title={`Create Entrance For ${location?.name}`}
      centered
      className='location-modal'
      visible={visible}
      onOk={handleSubmit}
      onCancel={onCancel}
      maskClosable={false}
      confirmLoading={loading}
      footer={[
        <Button key="back" className="btn-default" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          loading={loading}
          key="submit"
          className="btn-create"
          onClick={handleSubmit}
        >
          Save
        </Button>,
      ]}
    >
      <Form form={form} {...layout} onFinish={handleSubmit}>
        <Form.Item
          name="name"
          label="Entrance Name"
          labelCol={{ span: 24 }}
          rules={[{ required: true, message: 'Name is required' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="template_id" label="Template" labelCol={{ span: 24 }}>
          <Select placeholder="Select a template">
            {templates?.map((e) => (
              <Option key={e.id} value={e.id}>{e.name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}
