import { useMemo } from 'react';
import { useParams, useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import queryString from 'query-string';

// Hook
export function useRouter() {
  const params = useParams<{ id: string }>();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  const state: any = location.state

  return useMemo(
    () => {
      return {
        push: history.push,
        replace: history.replace,
        pathname: location.pathname,
        state,
        query: { ...queryString.parse(location.search) },
        params,
        match,
        location,
        history
      };
    },
    [history, location, state, params, match]
  );
}