import React, { useEffect, useState } from 'react';
import Fab from '@mui/material/Fab';
import { ThemeProvider, createTheme } from '@mui/material';

import RefreshIcon from '../../assets/icons/i-refresh.png'
export enum FloatingButtonPosition {
    absoluteBottom, stickyElement
}

export enum FloatingButtonType {
    refresh
}

type Props = {
    position: FloatingButtonPosition,
    text: string,
    type: FloatingButtonType,
    onClick: () => void
}

export const FloatingButton = ({ position, text, type, onClick }: Props) => {
    const [style, setStyle] = useState({})
    const [iconBtn, setIconBtn] = useState('')

    const floatingButtonTheme = React.useMemo(() =>
    createTheme({
        components: {
            MuiFab: {
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                        backgroundColor: '#2C4B9C',
                        fontFamily: 'Lato',
                        fontSize: 12,
                        fontWeight: 700,
                        '&:hover': {
                            backgroundColor: '#2C4B9C'
                        }
                    },
                    extended: {
                        padding: '8px 12px',
                    }
                }
            }
        }
    }), []
    )

    const onClickBtn = () => {
        onClick()
    }

    const getIcon = () => {
        switch (type){
            case FloatingButtonType.refresh:
                setIconBtn(RefreshIcon)
                break
        }
    }

    const getStyles = () => {
        if (position === FloatingButtonPosition.absoluteBottom) {
            setStyle({
                position: 'absolute', bottom: 20, right: 20
            })
        } else if (position === FloatingButtonPosition.stickyElement) {
            setStyle({
                position: 'sticky', bottom: 20, float: 'right'
            })
        }
    }

    useEffect(() => {
        getStyles()
        getIcon()
    }, []);
  
  return (
    <ThemeProvider theme={floatingButtonTheme}>
    <div style={ style } >
            {(type === FloatingButtonType.refresh) && <>
            <Fab variant="extended" size="small" color="primary" aria-label={text} onClick={onClickBtn}><img style={{height: 14, marginRight: 6}} src={iconBtn} alt='' />{text}</Fab>
            </>}
    </div>
    </ThemeProvider>
  )
}
