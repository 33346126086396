/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-nocheck
import React, { useRef } from 'react'
import IdleTimer from 'react-idle-timer'
import { useHistory } from 'react-router-dom'
import { Modal, Button } from 'antd'
import { cache } from 'swr'

import { RoutePaths } from 'hooks/useNavigation'
// import firebase from '../libs/firebase'

function IdleTimerWrap(props: any) {
  let idleTimer = useRef()
  let timer = {}
  let timeOut = {}
  const history = useHistory()
  const { authDispatch, children } = props

  const logout = async () => {
    // await firebase.auth().signOut()
    cache.clear()
    localStorage.clear()
    authDispatch({ loggedIn: false })
    history.push(RoutePaths.ADMIN_SESSION_EXPIRE)
  }

  const onIdle = (e) => {
    countDown()
  }

  const countDown = () => {
    idleTimer.pause()
    let secondsToGo = 300
    const modal = Modal.confirm({
      title: 'Session Timeout',
      content: (
        <>
          <p>Your seccion will expire in</p>
          <h2>{` 05 min 00 secs`}</h2>
          <span>Would you like to continue?</span>
        </>
      ),
      onOk() {
        clearTimeout(timeOut)
        if (idleTimer) {
          idleTimer.reset()
        }
      },
      onCancel() {
        clearTimeout(timeOut)
        clearInterval(timer)
        logout()
      },
      okText: 'Continue',
      cancelText: 'Logout',
      cancelButtonProps: { className: 'btn-default' },
    })

    const timer = setInterval(() => {
      secondsToGo -= 1
      const minutes = Math.floor((secondsToGo / 60) % 60)
      const seconds = Math.floor(secondsToGo % 60)
      modal.update({
        content: (
          <>
            <p>Your session will expire in</p>
            <h2>{` ${minutes} min ${seconds} secs`}</h2>
            <span>Would you like to continue?</span>
          </>
        ),
      })
    }, 1000)

    timeOut = setTimeout(() => {
      clearInterval(timer)
      modal.destroy()
      logout()
    }, secondsToGo * 1000)
  }

  return (
    <>
      <IdleTimer
        ref={(ref) => {
          idleTimer = ref
        }}
        element={document}
        // onActive={onActive}
        onIdle={onIdle}
        debounce={250}
        timeout={120000}
      />
      {children}
    </>
  )
}

export default IdleTimerWrap
