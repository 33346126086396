/**
 * @author Trung Tran
 */
import React from 'react' //   useCallback, // useState,
import { Layout, Menu } from 'antd'
// import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { useHistory, Link } from 'react-router-dom'
import styled from 'styled-components'

// style
import './style.less'

const { Sider } = Layout

const StyledMenu = styled(Menu)`
  .ant-menu-inline {
    border: none;
  }
  .ant-menu-item {
    font-size: 14px;
    color: #434343 !important;
  }
  .ant-menu-item-selected {
    background-color: #fff !important;
    color: #000 !important;
  }

  .ant-menu-item-active {
    color: #000 !important;
  }
  .ant-menu-inline .ant-menu-item::after {
    border-right: none !important;
  }

  .ant-menu-item-selected::after {
    border-right: none;
    width: 5px;
    background-color: #2C4B9C;
    left: 0;
  }

  .ant-menu-item-selected::before {
  }
`

// const MenuItem = styled(Menu.Item)`
//   .ant-menu-item-selected::after {
//     border-left: 3px solid #51d1c4 !important;
//   }
// `;

function SiderLayout(props: any) {
  const { role, navigation } = props
  const history = useHistory()

  //   const onCollapse = useCallback(() => {
  //     setCollapsed(!collapsed);
  //   }, [collapsed]);

  // const onSelect = useCallback(
  //   (e) => {
  //     history.push(e.key);
  //   },
  //   [history]
  // );
  return (
    <Sider
      theme="light"
      //   collapsed={collapsed}
      //   onCollapse={onCollapse}
      style={{ background: '#fff', padding: '15px 0' }}
      className="layout-sider"
      width={260}
    >
      <StyledMenu
        // onSelect={onSelect}
        theme="light"
        defaultSelectedKeys={[history.location.pathname]}
        mode="inline"
      >
        {navigation.map(({ icon: Icon, ...item }: any) => {
          return !item.roles || item.roles.indexOf(role?.name) > -1 ? (
            <Menu.Item key={item.key}>
              <Link to={item.key} style={{ color: '#000' }}>
                <div className="menu-item-cus">
                  <Icon style={{ width: '25px' }} />
                  <span>{item.text}</span>
                </div>
              </Link>
            </Menu.Item>
          ) : null
        }
        )}
      </StyledMenu>

      <div
        // onClick={onCollapse}
        style={{
          padding: 16,
          backgroundColor: '#fff',
          cursor: 'pointer',
          position: 'relative',
          width: '100%',
          bottom: 0,
        }}
      >
        {/* {collapsed ? (
          <RightOutlined style={{ color: "#fff" }} />
        ) : (
          <LeftOutlined style={{ color: "#fff" }} />
        )} */}
      </div>
    </Sider>
  )
}

export default SiderLayout
