import React, { useState, useEffect } from 'react'

import { Table, Button, Space, message, Modal } from 'antd'
import { CaretRightFilled } from '@ant-design/icons'
import { ColumnType } from 'antd/es/table'
import moment from 'moment'

import useAuthUser from 'hooks/useAuthUser'
import { YesNo } from 'config'

import { AnswerType } from 'api/Main'
import { QuestionAPI, Question, Answer } from '../../../../api/Question'

import { CreateQuestionModal } from './CreateQuestionModal'
import { EditQuestionModal } from './EditQuestionModal'
import { CreateAnswerModal } from './CreateAnswerModal'
import { EditAnswerModal } from './EditAnswerModal'

enum MODAL_TAG {
  CREATE_QUESTION = 'CREATE_QUESTION',
  EDIT_QUESTION = 'EDIT_QUESTION',
  ADD_ANSWER = 'ADD_ANSWER',
  EDIT_ANSWER = 'EDIT_ANSWER',
}

enum ALLOW_ADD_ANSWER {
  CheckBox = 'checkbox',
  SelectBox = 'selectbox',
}

type TablePaginationType = {
  current: number
  pageSize: number
}

const initPagination: TablePaginationType = {
  pageSize: 10,
  current: 1,
}

export const defaultQuestionColumns: ColumnType<Question>[] = [
  {
    title: 'ID',
    key: 'id',
    dataIndex: 'id',
  },
  {
    title: 'Question',
    key: 'title',
    dataIndex: 'title',
    // render: (value) => (
    //   <Tooltip placement="top" title={value}>
    //     <Paragraph ellipsis={{ rows: 2 }} style={{ margin: 0 }}>
    //       {value}
    //     </Paragraph>
    //   </Tooltip>
    // ),
  },
  {
    title: 'Description',
    key: 'description',
    dataIndex: 'description',
    // render: (value) => (
    //   <Tooltip placement="top" title={value}>
    //     <Paragraph ellipsis={{ rows: 2 }} style={{ margin: 0 }}>
    //       {value}
    //     </Paragraph>
    //   </Tooltip>
    // ),
  },
]

export const defaultAnswerColumns: ColumnType<Answer>[] = [
  {
    title: 'ID',
    key: 'id',
    dataIndex: 'id',
  },
  {
    title: 'Answer',
    key: 'answer',
    dataIndex: 'answer',
  },
  {
    title: 'Created',
    key: 'created_by',
    dataIndex: 'created_by',
    render: (value: string, record: any) => (
      <div>
        {value}
        <br />
        <small>
          <CaretRightFilled />{' '}
          {moment(record.created_at).format('DD/MM/YYYY HH:mm:ss')}
        </small>
      </div>
    ),
  },
  {
    title: 'Updated',
    key: 'updated_by',
    dataIndex: 'updated_by',
    render: (value: string, record: any) => (
      <div>
        {value}
        <br />
        <small>
          <CaretRightFilled />{' '}
          {moment(record.updated_at).format('DD/MM/YYYY HH:mm:ss')}
        </small>
      </div>
    ),
  },
]

export function QuestionsTab() {
  const questionColumns: ColumnType<Question>[] = [
    ...defaultQuestionColumns,
    {
      title: 'Answer Type',
      key: 'answer_type',
      dataIndex: 'answer_type',
      render: (value) => value[0].toUpperCase() + value.slice(1).toLowerCase(),
    },
    {
      title: 'Negative Conditions',
      key: 'negative_conditions',
      dataIndex: 'negative_conditions',
      render: (value, record) => {
        if (value && value.length) {
          switch (record.answer_type) {
            case AnswerType.Boolean: {
              if (value[0].value !== null)
                return value[0].value ? YesNo.YES : YesNo.NO
              break
            }
            default: {
              return `${value.length} conditions`
            }
          }
        }
      },
    },
    {
      title: 'Action',
      align: 'center',
      width: 'fit-content',
      render: (_, record) => (
        <div style={{ float: 'right' }}>
          <Space>
            {Object.values(ALLOW_ADD_ANSWER).includes(
              record.answer_type.toLowerCase() as any
            ) && (
              <Button
                className="btn-create"
                onClick={() => {
                  setSelectedQuestion(record)
                  openModal(MODAL_TAG.ADD_ANSWER)
                }}
              >
                Add answer
              </Button>
            )}
            <Button
              className="btn-edit"
              onClick={() => {
                setSelectedQuestion(record)
                openModal(MODAL_TAG.EDIT_QUESTION)
              }}
            >
              Edit
            </Button>
            <Button
              className="btn-delete"
              onClick={() => {
                Modal.confirm({
                  title: 'Confirm',
                  content: `Are you want to delete question #${record.id}?`,
                  okType: 'default',
                  okButtonProps: { className: 'btn-delete' },
                  cancelButtonProps: { className: 'btn-default' },
                  onOk: () => {
                    deleteQuestion(record.id)
                  },
                })
              }}
            >
              Delete
            </Button>
          </Space>
        </div>
      ),
    },
  ]

  const answerColumns: ColumnType<Answer>[] = [
    ...defaultAnswerColumns,
    {
      title: 'Action',
      align: 'center',
      width: 'fit-content',
      render: (record) => (
        <Space>
          <Button
            className="btn-edit"
            onClick={() => {
              setSelectedAnswer(record)
              openModal(MODAL_TAG.EDIT_ANSWER)
            }}
          >
            Edit
          </Button>
          <Button
            className="btn-delete"
            onClick={() => {
              Modal.confirm({
                title: 'Confirm',
                content: `Are you want to delete answer #${record.id}?`,
                okType: 'default',
                okButtonProps: { className: 'btn-delete' },
                cancelButtonProps: { className: 'btn-default' },
                onOk: () => deleteAnswer(record.id),
              })
            }}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ]

  const [user] = useAuthUser()
  const [visibleModal, setVisibleModal] = useState<MODAL_TAG>()
  const [questions, setQuestions] = useState<Question[]>()
  const [selectedQuestion, setSelectedQuestion] = useState<Question>()
  const [totalQuestions, setTotalQuestions] = useState<number>(0)
  const [selectedAnswer, setSelectedAnswer] = useState<Answer>()
  const [pagination, setPagination] =
    useState<TablePaginationType>(initPagination)

  const fetchQuestions = (token: string, pagination?: TablePaginationType) => {
    QuestionAPI.list(token, {
      items_per_page: pagination.pageSize,
      page: pagination.current,
    })
      .then(({ items, total }) => {
        setQuestions(items)
        setTotalQuestions(total)
      })
      .catch((err: Error) => {
        message.error(err.message)
      })
  }

  useEffect(() => {
    if (user) {
      fetchQuestions(user.accessToken, pagination)
    }
  }, [user, pagination])

  const openModal = (type: MODAL_TAG | null) => {
    setVisibleModal(type)
  }

  const closeModal = () => {
    setVisibleModal(null)
  }

  const createQuestion = async (payload: Pick<Question, 'name'>) => {
    await QuestionAPI.create(user?.accessToken, payload)
      .then((result: string) => {
        if (result) {
          message.success(result)
        }
        fetchQuestions(user.accessToken, pagination)
      })
      .catch((err: Error) => {
        message.error(err.message)
      })
  }

  const updateQuestion = async (id: number, payload: Question) => {
    await QuestionAPI.update(user?.accessToken, id, payload)
      .then((result: string) => {
        if (result) {
          message.success(result)
        }
        fetchQuestions(user.accessToken, pagination)
      })
      .catch((err: Error) => {
        message.error(err.message)
      })
  }

  const deleteQuestion = async (id: string) => {
    await QuestionAPI.delete(user?.accessToken, id)
      .then((result: string) => {
        if (result) {
          message.success(result)
        }
        fetchQuestions(user.accessToken, pagination)
      })
      .catch((err: Error) => {
        message.error(err.message)
      })
  }

  const createAnswer = async (payload: Pick<Answer, 'answer'>) => {
    await QuestionAPI.createAnswer(
      user?.accessToken,
      selectedQuestion.id,
      payload
    )
      .then((result: string) => {
        if (result) {
          message.success(result)
        }
        fetchQuestions(user.accessToken, pagination)
      })
      .catch((err: Error) => {
        message.error(err.message)
      })
  }

  const updateAnswer = async (id: number, payload: Answer) => {
    await QuestionAPI.updateAnswer(user?.accessToken, id, payload)
      .then((result: string) => {
        if (result) {
          message.success(result)
        }
        fetchQuestions(user.accessToken, pagination)
      })
      .catch((err: Error) => {
        message.error(err.message)
      })
  }

  const deleteAnswer = async (answerId: number) => {
    await QuestionAPI.deleteAnswer(user?.accessToken, answerId)
      .then((result: string) => {
        if (result) {
          message.success(result)
        }
        fetchQuestions(user.accessToken, pagination)
      })
      .catch((err: Error) => {
        message.error(err.message)
      })
  }

  return (
    <div>
      <Table
        title={() => (
          <Space direction="vertical" align="end" style={{ width: '100%' }}>
            <Button
              className="btn-create"
              onClick={() => {
                openModal(MODAL_TAG.CREATE_QUESTION)
              }}
            >
              Create Question
            </Button>
          </Space>
        )}
        rowKey="id"
        columns={questionColumns}
        dataSource={questions}
        scroll={{
          x: 500,
        }}
        pagination={{
          total: totalQuestions,
          onChange: (page, pageSize) => {
            setPagination({
              current: page,
              pageSize,
            })
          },
        }}
        expandable={{
          expandedRowRender: (record) => (
            <Table
              columns={answerColumns}
              dataSource={record.answers}
              pagination={false}
            />
          ),
          rowExpandable: (record) =>
            !!(
              record.answers &&
              record.answers.length &&
              Object.values(ALLOW_ADD_ANSWER).includes(
                record.answer_type.toLowerCase() as any
              )
            ),
        }}
      />
      <CreateQuestionModal
        visible={visibleModal === MODAL_TAG.CREATE_QUESTION}
        onOk={createQuestion}
        onCancel={closeModal}
      />
      <EditQuestionModal
        visible={visibleModal === MODAL_TAG.EDIT_QUESTION}
        onOk={updateQuestion}
        question={selectedQuestion}
        onCancel={closeModal}
      />
      <CreateAnswerModal
        visible={visibleModal === MODAL_TAG.ADD_ANSWER}
        onOk={createAnswer}
        onCancel={closeModal}
      />
      <EditAnswerModal
        visible={visibleModal === MODAL_TAG.EDIT_ANSWER}
        onOk={updateAnswer}
        answer={selectedAnswer}
        onCancel={closeModal}
      />
    </div>
  )
}
