import { Icon } from "leaflet";
import React, { useRef } from "react";
import { Marker } from "react-leaflet";
import { LatLngLiteral } from "leaflet";

import markerIcon from "./marker.png";
import markerShadowIcon from "./markerShadow.png";

function DraggableMarker({ lat, lng }: LatLngLiteral) {
  const markerRef = useRef(null);

  return (
    <Marker
      draggable={false}
      icon={
        new Icon({
          iconUrl: markerIcon,
          shadowUrl: markerShadowIcon,
          iconSize: [25, 40.455], // size of the icon
          shadowSize: [25, 30], // size of the shadow
          iconAnchor: [10, 40], // point of the icon which will correspond to marker's location
          shadowAnchor: [-0.5, 28], // the same for the shadow
          //   popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
        })
      }
      //   eventHandlers={eventHandlers}
      position={{ lat, lng }}
      ref={markerRef}
      // drag={(e) => console.log(e)}
    ></Marker>
  );
}

export default DraggableMarker;
