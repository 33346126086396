const apiURL = process.env.REACT_APP_API_URL;
const url = `${apiURL}/api/v2/getentrancesbyuser`;

export const getEntrancesByUserAPI = {
  async list( 
    token: string,
    id: String
    ): Promise<any> {
    const resp = await fetch(`${url}`,
      {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify({
            "userIdentity": id,
        }),
        headers: {
                  'Content-Type': 'application/json',
                  'Content-Length': '0',
                  'Authorization': token,
        },
      });
    const response = await resp.json();
    return response;
  },
};
