import React, { useEffect, useState } from 'react'
import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';

type RoomObject = {
    roomId: number;
    roomName: string;
}

type Props = {
    // children: React.ReactNode
    locationOptions: Array<RoomObject>
    value: Array<RoomObject>;
    editable?: boolean;
    onUpdate: (locations: Array<RoomObject>)=>void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 300,
        },
    },
};

export const EditLocationDialog = ({ value, locationOptions = [], editable = false, onUpdate }: Props) => {

    const [selectedLocations, setSelectedLocations] = useState(value)
    const [open, setOpen] = useState(false)

    const handleChange = (event: SelectChangeEvent<string[]>) => {
        const {
            target: { value },
        } = event;

        setSelectedLocations(
            // On autofill we get a stringified value.

            locationOptions.filter((l) => value.includes(l.roomName))

            // typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleClose = () => {
        setOpen(false)
    }

    const handleConfirm = () => {
        // Rm 1, Rm 2
        // merge with
        // { roomId: 1, roomName: "Rm 1"}...
        onUpdate(selectedLocations)
        setOpen(false)
    }

    return (
        <>
            {editable && <>
                <IconButton sx={{ display: "inline-block", color: '#2C4B9C', padding: 0 }} aria-label="Edit selected rooms" color="primary" onClick={() => setOpen(!open)}>
                    <EditIcon sx={{ fontSize: 16, margin: 0.5 }} />
                </IconButton>
                <Dialog onClose={handleClose} open={open}>
                    <DialogTitle>{'Edit "Room(s) Selected"'}</DialogTitle>
                    <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="demo-multiple-chip-label">Rooms Selected:</InputLabel>
                        <Select
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            multiple
                            value={selectedLocations ? (selectedLocations.map((v) => v.roomName)) : []}
                            onChange={handleChange}
                            input={<OutlinedInput id="select-multiple-chip" label="Rooms Selected:" />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip color="primary" key={value} label={value} />
                                    ))}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                        >
                            {locationOptions.map((l) => (
                                <MenuItem
                                    key={l.roomId}
                                    value={l.roomName}
                                // style={getStyles(name, personName, theme)}
                                >
                                    {l.roomName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={handleConfirm} autoFocus>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
                </>
            }
        </>
    )
}
