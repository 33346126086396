import React, { useEffect, useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import SingpassLoginButton from './SingpassLoginButton';
import { authorizationUrl } from '../../api/AP/getAuthUrl';

type Props = {
  requestType: string;
};

function SgIdAuth(props: Props) {
  const [singpassUrl, setSingpassUrl] = useState<string | null>(null);

  const handleButtonClick = () => {
    localStorage.setItem('authRequestType', props.requestType);
  };

  useEffect(() => {
    let isMounted = true;

    // Fetch sgid auth url
    authorizationUrl
      .get()
      .then(({ url }) => {
        if (isMounted) {
          setSingpassUrl(url);
        }
      })
      .catch((error) => {
        console.error('Error fetching Singpass URL:', error);
      });

    return () => {
      isMounted = false;
    };
  }, []);

  return singpassUrl ? (
    <SingpassLoginButton href={singpassUrl} onClick={handleButtonClick} {...props} />
  ) : (
    <Skeleton variant="rounded" width={176} height={39} />
  );
}

export default SgIdAuth;
