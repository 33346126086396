import { Location } from './Location'

const apiURL = process.env.REACT_APP_API_URL
const url = `${apiURL}/api/v1/entrances`
const urlV2 = `${apiURL}/api/v2`
const qrURL = `${apiURL}/api/v1/qr-code`

export interface Entrance {
  id: number
  name: string
  location_id: number
  created_at?: string
  updated_at?: string
  is_confidential: boolean
  location?: Location
  rooms?: string[]
}

export interface IEntranceV2 {
  entranceId: number
  entranceName: string
}

export const EntranceAPI = {
  async list(
    token: string,
    locationId: number,
    search?: string
  ): Promise<{status: boolean; data: Entrance[]}> {
    const listUrl = new URL(url)
    if(locationId && locationId !== null && typeof locationId !== "undefined"){
      listUrl.searchParams.set("location_id", locationId.toString())
    }
    if(search && search !== null && typeof search !== "undefined"){
      listUrl.searchParams.set("search", search)
    }
      const res = await fetch(listUrl.href,
        {
          credentials: 'include',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token,
          },
          method: 'get',
        }
      )
      const response = await res.json()
      return response
  },

  async create(token: string, payload: Entrance): Promise<string> {
    const res = await fetch(`${url}`, {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
      method: 'post',
      body: JSON.stringify(payload),
    })
    const response = await res.json()
    return response
  },

  async update(token: string, id: number, payload: Entrance): Promise<string> {
    const res = await fetch(`${url}/${id}`, {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
      method: 'put',
      body: JSON.stringify(payload),
    })
    const response = await res.json()
    return response
  },

  async delete(token: string, id: number): Promise<string> {
    const res = await fetch(`${url}/${id}`, {
      credentials: 'include',
      headers: {
        Authorization: token,
      },
      method: 'delete',
    })
    const response = await res.json()
    return response
  },

  async getQRCode(token: string, entranceID: number): Promise<string> {
    const res = await fetch(`${qrURL}/${entranceID}`, {
      credentials: 'include',
      headers: {
        Authorization: token,
      },
    })
    const { status, data } = await res.json()
    if (status === false) throw Error('Get QR code failed')
    return data
  },

  async getPosterQRCode(token: string, entranceID: number, fileName: string) {
    const res = await fetch(`${qrURL}/poster/${entranceID}`, {
      credentials: 'include',
      headers: {
        Authorization: token,
      },
    })
    const data = await res.blob()
    const blob = new Blob([data], { type: 'application/pdf' })

    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = fileName
    link.click()
    link.remove()
  },

  //v2 api calls
  async getEntrancesByLocationId(token: string, locationId: number): Promise<IEntranceV2[]> {
    const res = await fetch(`${urlV2}/getEntrancesByLocation`, {
      credentials: 'include',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({locationId: locationId})
    })
    const { status, data, message } = await res.json()
    if (status === false) throw Error(message || 'Get entrance by location failed')
    return data
  },
}
