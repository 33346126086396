const baseURL = process.env.REACT_APP_API_URL;
const path = '/api/v2/ap/token';

type accessTokenParams = {
  code: string,
}

export const accessToken = {
  async post({code}: accessTokenParams): Promise<any> {
    const tokenUrl = new URL(path, baseURL)
    if(!code){
      console.error("code parameter not found!")
    }
    tokenUrl.searchParams.append("code",code);

    const res = await fetch(tokenUrl.href, {
        method: 'POST',
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
        body: JSON.stringify({
          code
        })
    });
    const response = await res.json();
    if (response && response.status) {
      const { data } = response;
      return data;
    }
    return response;
  },
};
