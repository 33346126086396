/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useEffect, useState } from 'react'

// components
import { Box } from '@mui/material'

import { SwiftEntryLogo } from '../../../components/SwiftEntryLogo'

import SgIdAuth from '../../../components/SgIdAuth';

import { AuthRequestType } from '../AuthCallbackPage';
import Title from 'antd/lib/typography/Title';
import DynamicBackground from '../../../components/DynamicBackground'

function Login() {

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
            flexDirection: 'column'
        }}>
            <DynamicBackground>
                <SwiftEntryLogo color="default" />
                <Title
                    level={3}
                    style={{ padding: '0 24px 0 24px', marginBottom: '30px', fontSize: '16px', fontWeight: '700', textAlign: "center" }}
                >
                    Accompanying Personnel
                </Title>
                <SgIdAuth requestType={AuthRequestType.apLogin.toString()}
                />
            </DynamicBackground>

        </Box >
    )
}

export default Login
