// import { Location } from "./Location";
// import { Entrance } from "./Entrance";

const apiURL = process.env.REACT_APP_API_URL;
const url = `${apiURL}/api/v1/approvals`;

export interface Pending {
  id: number;
  area_type: string;
  area_data: any;
  user_id: string;
  type: string;
  created_at?: string;
  updated_at?: string;
}

export type PaginationQueryType = {
  items_per_page: number;
  page: number;
  query?: any;
};
export interface ApprovalIdType {
  approval_id: number;
}

export const PendingApprovalAPI = {
  async list(
    token: string,
    pagination: PaginationQueryType
  ): Promise<{ items: Location[]; total: number }> {
    const res = await fetch(
      `${url}?items_per_page=${pagination.items_per_page
      }&page=${pagination.page}&${pagination.query ? new URLSearchParams(pagination.query) : ""
      }`,
      {
        credentials: 'include',
        headers: {
          "Authorization": token
        }
      }
    );
    const response = await res.json();
    return response;
  },

  async approve(token: string, id: number): Promise<string> {
    const res = await fetch(`${url}`, {
      credentials: 'include',
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": token
      },
      method: "post",
      body: JSON.stringify({
        approval_id: id,
      }),
    });
    const response = await res.json()
    return response
  },

  async reject(token: string, id: number): Promise<string> {
    const res = await fetch(`${url}`, {
      credentials: 'include',
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": token
      },
      method: "delete",
      body: JSON.stringify({
        approval_id: id,
      }),
    });
    const response = await res.json()
    return response
  },
};
