import { Button, message, Space, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { ReactComponent as LocationIcon } from 'assets/icons/location-sign.svg'
import { CardDefault } from 'components/cards/CardDefault'
import { useRouter } from 'hooks/useRouter'
import { RoutePaths } from 'hooks/useNavigation'
import { useLocationParams } from 'contexts/LocationParams'
import { getLocationFromStr } from 'utils/location'
import { CheckingAPI } from 'api/Main'
import { MESSAGES } from 'config'
import SgIdAuth from '../../../components/SgIdAuth'
import { AuthRequestType } from '../../APPages/AuthCallbackPage'
import { LocationParamsStorage } from '../../../contexts/LocationParams'
import { AuthType } from '../DeclarationPage'

export const MainPage = () => {
  const locationParams = useLocationParams()
  const router = useRouter()
  const locationInfo = getLocationFromStr(router.params.id || '')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    // had location info
    if (locationParams?.data !== null) return
    // no location
    if (Object.values(locationInfo).every((item) => !item)) {
      message.error(MESSAGES.invalidQRCode)
      return router.push(RoutePaths.INDEX)
    }
    // check swiftId
    if (locationInfo.swiftId !== process.env.REACT_APP_SWIFT_ID) {
      message.error('This QR code is not a GovEntry QR Code')
      return router.push(RoutePaths.INDEX)
    }
    // have location
    if (locationParams !== null) {
      setLoading(true)
      CheckingAPI.checkLocation(locationInfo.id, locationInfo.entranceID)
        .then((entrance: any) => {
          setLoading(false)
          locationParams.setLocation({
            locationName: entrance.location_name.toUpperCase(),
            locationID: locationInfo.id,
            entranceID: locationInfo.entranceID,
            entranceName: entrance.name,
            templateName: entrance.template_name,
          })
          const paramsToStore: LocationParamsStorage = {
            locationName: entrance.location_name.toUpperCase(),
            locationID: locationInfo.id,
            entranceID: locationInfo.entranceID,
            entranceName: entrance.name,
            templateName: entrance.template_name,
          }
          localStorage.setItem("locationParams", JSON.stringify(paramsToStore))
        })
        .catch((error) => {
          message.error(error.message)
          return router.push(RoutePaths.INDEX)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const goFormCheckPage = (authType: AuthType) => {
    router.push(RoutePaths.DECLARATION, { canGoBack: true, authType })
  }

  return (
    <div className="main-page container d-flex align-items-center flex-column">
      {loading ? (
        <Spin className="loading-effect" size="large" />
      ) : (
        <>
          <CardDefault classNames="location-card" contentCentered>
            <Space
              className="location"
              size={5}
              direction="vertical"
              align="center"
            >
              <LocationIcon className="location__icon" />
              <span>You are visiting</span>
              <div className="location__status d-flex align-items-end" />
              <Space direction="vertical" align="center" size={1}>
                <b className="location__name">
                  {locationParams?.data?.locationName}
                </b>
                <span className="d-inline-block text-center">
                  {locationParams?.data?.entranceName}
                </span>
              </Space>
            </Space>
          </CardDefault>
          <Space
            className="actions"
            align="center"
            direction="vertical"
            size={5}
          >
            <SgIdAuth requestType={AuthRequestType.visitorLogin.toString()}/>
            <div>
              <span style={{fontWeight: 700}}>or</span>
            </div>
            <Button
              onClick={() => goFormCheckPage(AuthType.manualAuth)}
              style={{ width: 200, borderRadius: 4, borderWidth: 2 }}
            >
              <span style={{ fontWeight: 700, fontSize: 16, alignContent: 'center' }} >Fill in Details Manually</span>
            </Button>
          </Space>
        </>
      )}
    </div>
  )
}
