import React, { useMemo, useState } from "react";
import { Modal, Button, message, Upload, UploadProps } from "antd";
import { InboxOutlined } from '@ant-design/icons';
import csvtemplatefile from '../../../../assets/files/csv_template.csv';
import { CsvAPI } from '../../../../api/AP/CsvOperations';
const { Dragger } = Upload; 

interface Props {
  visible: boolean;
  onCancel: () => void;
  onUploadSuccess: () => void;
  onUploadError: (errorMessage) => void;
  accessToken: string;
}

function UploadUsersCsvModal(props: Props) {
  const {visible, accessToken} = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [fileUploaded, setFileUploaded] = useState<boolean>(false);
  const [fileImported, setFileImported] = useState();
  const [fileName, setFileName] = useState('users.csv');
  const validateCSVFile = async function(options:any){
    const { onSuccess, onError, file } = options;
    const fmData = new FormData();
    fmData.append("UserCSV", file);
    setFileImported(file);
    try{
      // const apiResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/v2/ap/usercsvupload/validate`, {
      //   headers: {
      //     Authorization: accessToken,
      //     // do not need to put content type so boundary can be generated automatically
      //     // "content-type": "multipart/form-data"
      //   },
      //   method: 'POST',
      //   body: fmData,
      // })

      const apiResponse = await CsvAPI.validate(accessToken, fmData)
     
      const {status, data, message} = apiResponse;

      if(status && data === 'upload successful'){
        onSuccess();
      }
      console.log(status, data, message)
      if (status === false || !data?.length)
       throw Error(message)
    
    }catch(e){
      onError(e.message);
    }
  }

  //accept is not a validation of filetype
  //@ts-ignore
  const uploadProps:UploadProps = useMemo(()=> {
    return {
      name: "UserCSV",
      // cannot use default action as xhr seem to be blocked by cors. Weird?
      // action: `${process.env.REACT_APP_API_URL}/api/v2/getUsers`,
      // headers: {
      //   Authorization: accessToken,
      //   Method: "GET"
      // },
      multiple: false,
      accept: ".csv",
      maxCount: 1,
      customRequest: validateCSVFile,
      onDrop(e) {
        //console.log('Dropped files', e.dataTransfer.files);
      },
      onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
          console.log(info.file);
        }
        if (status === 'removed') {
          setFileUploaded(false);
        }
        if (status === 'done') {
          setFileUploaded(true);
          setFileName(info.file.name);
          message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
          setFileUploaded(false)
          message.error(`${info.file.name} file upload failed. ${info.file.error}`);
        }
      },
    }}, [accessToken]);

  const onSubmit = async ()=>{
    setLoading(true);
    const fmData = new FormData();
    if(fileImported){
      fmData.append("UserCSV", fileImported);
    }
    try{
      const apiResponse = await CsvAPI.upload(accessToken, fmData, fileUploaded);
      const {status, data, message : errorMessage} = apiResponse;
      if(status){
        setLoading(false);
        props.onUploadSuccess();
      }
      if (status === false || !data?.length){
       throw new Error(errorMessage)
      }
    }catch(e){
      setLoading(false);
        props.onUploadError(fileName)
    }
  }

  const onCancel = ()=>{
    props.onCancel();
  }
  
  //@ts-ignore
  return (
    <Modal
      title="Import CSV"
      visible={visible}
      // this prop will not work with custom buttons for footer
      // okButtonProps={{loading}}
      maskClosable={false}
      destroyOnClose
      // onCancel for escape key press to work
      onCancel = {onCancel}
      onOk = {onSubmit}
      getContainer={false}
      footer={[
        <Button key="back" className="btn-default" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" className="btn-default" loading={loading} disabled={!fileUploaded} onClick={onSubmit}>
          Import
        </Button>,
      ]}
    > 
      <p>Download <a style={{textDecoration: 'underline'}} href={csvtemplatefile} download="csv_template"> CSV template </a> to input data via the required format. File must not contain existing user details (NRIC and phone number). Phone numbers must be 8 digts without country code.</p>
      <Dragger {...uploadProps}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">
          This only supports single upload.
        </p>
      </Dragger>
    </Modal>
  );
}

export default UploadUsersCsvModal;