import React, { useState, useEffect } from 'react'
import { Modal, Form, Input, message, Button } from 'antd'

export type EditTemplateModalProps = {
  visible: boolean
  onOk: (id: number, payload: any) => void
  onCancel: () => void
  template: any
}

export const EditTemplateModal = ({
  visible,
  onOk: updateTemplate,
  onCancel,
  template,
}: EditTemplateModalProps) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (template !== null) {
      form.setFieldsValue({...template})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template])

  const handleSubmit = async () => {
    try {
      setLoading(true)
      await form.validateFields()
      const payload = form.getFieldsValue()
      updateTemplate(template.id, {
        ...payload,
        name: payload.name.trim(),
      })
      onCancel()
    } catch (error) {
      if (error.message) {
        message.error(error.message)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      title="Edit Template"
      centered
      visible={visible}
      onOk={handleSubmit}
      onCancel={onCancel}
      confirmLoading={loading}
      maskClosable={false}
      cancelText={'Close'}
      destroyOnClose
      footer={[
        <Button key="back" className="btn-default" onClick={onCancel}>
          Close
        </Button>,
        <Button
          loading={loading}
          key="submit"
          className="btn-edit"
          onClick={handleSubmit}
        >
          Ok
        </Button>,
      ]}
    >
      <Form form={form} onFinish={handleSubmit} initialValues={template}>
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Name is required' }]}
        >
          <Input className="border-round" />
        </Form.Item>
      </Form>
    </Modal>
  )
}
