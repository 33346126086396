import React, { useState } from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';

type Props = {
    value: string;
    purposeOptions: Array<string>;
    editable?: boolean;
    onUpdate: (purpose: string) => void;
}

export const EditPurposeDialog = ({ value, purposeOptions = [], editable = false, onUpdate }: Props) => {

    const [selectedPurpose, setSelectedPurpose] = useState<string>(value)
    const [open, setOpen] = useState(false)

    const handleChange = (event: SelectChangeEvent<string>) => {
        const {
            target: { value },
        } = event;

        setSelectedPurpose(value);
    };

    const handleClose = () => {
        setOpen(false)
    }

    const handleConfirm = () => {
        onUpdate(selectedPurpose)
        setOpen(false)
    }

    return (<>{editable && <>
        <IconButton sx={{ display: "inline-block", color: '#2C4B9C', padding: 0 }} aria-label="Edit selected rooms" color="primary" onClick={() => setOpen(!open)}>
            <EditIcon sx={{ fontSize: 16, margin: 0.5 }} />
        </IconButton>
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>{'Edit: "Purpose of Visit"'}</DialogTitle>
                <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="demo-simple-select-label">Purpose of visit:</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedPurpose}
                label="Purpose of visit:"
                onChange={handleChange}
            >
                {purposeOptions.map((name) => (
                    <MenuItem
                        key={name}
                        value={name}
                        selected={name === value}
                    >
                        {name}
                    </MenuItem>
                ))}
            </Select>
            </FormControl>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleConfirm} autoFocus>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    </>}
    </>
    )
}
