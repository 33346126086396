import React, { useEffect, useMemo, useState, useRef } from 'react'
import {
  Button,
  Divider,
  message,
  Space,
  Switch,
  Modal,
  Typography,
  Form,
} from 'antd'

import { CardDefault } from 'components/cards/CardDefault'
import {
  DeclarationForm,
  DeclarationFormField,
} from 'components/DeclarationForm'
import { Policy } from 'components/Policy'

import { useRouter } from 'hooks/useRouter'

import { ReactComponent as CheckOutIcon } from 'assets/icons/checkout-enabled-icon.svg'
import { ReactComponent as CheckInIcon } from 'assets/icons/checkin-icon.svg'
import { ReactComponent as DangerousIcon } from 'assets/icons/dangerous-icon.svg'

import { RoutePaths } from 'hooks/useNavigation'
import { LOCAL_STORAGE_KEY, MESSAGES } from 'config'
import { LocalStorage } from 'utils/local-storage'
import { Validator } from 'utils/validator'
import { State as LocationState } from 'contexts/LocationParams'
import { LocationParamsStorage } from '../../../contexts/LocationParams'

import {
  AnswerType,
  CheckingAPI,
  CheckingInfo,
  CheckListQuestion,
} from 'api/Main'

import { getPurposesAPI } from '../../../api/AP/Purposes'
import { getRoomsByEntranceAPI } from '../../../api/RoomsByEntrance'
import { addRoomsToEntryLogAPI } from '../../../api/AddRoomsToEntryLog'
import { RoomObject } from '../../../components/VisitorInfoCard'

import { DeclarationCheckList } from 'components/DeclarationCheckList'
import { IsDangerousAnswer } from '../../../components/DeclarationCheckList'
import { FormItemType } from '../../../components/DeclarationForm'
import { useLocation } from 'react-router-dom'

const { Text } = Typography

type Props = {
  // location?: LocationState
  // questions: CheckListQuestion[]
  authType: AuthType
}

export enum AuthType {
  sgIdAuth, manualAuth
}

export type Declaration = {
  title: string
  answer: any
  isValid: boolean
  answer_type: string
  negative_conditions: any[]
}

const initResidentInfo = {
  phone: '',
  ic: '',
  name: '',
  reason: '',
  rooms: Array<RoomObject>(),
}

const initFormErrors = {
  phone: true,
  ic: true,
  name: true,
  reason: true,
  rooms: true,
}

export type ResidentInfo = typeof initResidentInfo & { reason?: string }

export const DeclarationPage = (props: Props) => {
  // export const DeclarationPage = ({ location, questions, authType }: Props) => {

  const router = useRouter()
  const [form] = Form.useForm()
  const [checklist] = Form.useForm()

  const outFocusRef = useRef(null)

  const [residentInfo, setResidentInfo] = useState<ResidentInfo>(() => {
    return initResidentInfo
  })

  const [declarations, setDeclarations] = useState<{
    [key: number]: Declaration
  }>({})

  const [initial, setInitial] = useState(true)
  const [isDangerous, setDangerous] = useState<boolean>(false)
  // const [loading, setLoading] = useState(false)
  const [question, setQuestion] = useState([])
  const [purposes, setPurposes] = useState([])
  const [roomList, setRoomList] = useState(Array<RoomObject>());
  const [roomsSelected, setRoomsSelected] = useState(Array<RoomObject>());
  const [location, setLocation] = useState({} as LocationParamsStorage)

  const setResidentObj = () => {
    const info = JSON.parse(localStorage.getItem("residentInfo") ?? "{}")
    if (info !== null) {
      let residentObj: ResidentInfo = { name: '', phone: '', ic: '', reason: '', rooms: [] }
      residentObj.name = info["name"]
      residentObj.phone = info["phone"]
      residentObj.ic = info["nric"]

      setResidentInfo(residentObj)
    }
  }

  useEffect(() => {
    switch (router.state.authType) {
      case AuthType.sgIdAuth:
        setResidentObj()
        break;
      case AuthType.manualAuth.toString():
      default:
        setResidentInfo(initResidentInfo)
        break;
    }

    const locationStored = JSON.parse(localStorage.getItem("locationParams") ?? "{}")
        const locationObj: LocationParamsStorage = {
          locationName: locationStored["locationName"],
          locationID: locationStored["locationID"],
          entranceID: locationStored["entranceID"],
          entranceName: locationStored["entranceName"],
          templateName: locationStored["templateName"],
        }
        setLocation(locationObj)
  }, [])

  useEffect(() => {
    if ((location.entranceID !== (null || undefined)) && (location.locationID !== (null || undefined))) {
      getDeclarations()
      getRooms()
      getPurposes()
      if (router.state.authType === AuthType.sgIdAuth && residentInfo.name === ('' || null || undefined)) {
        setResidentObj()
      }
    }
  }, [location])

  useEffect(() => {
    if (isDangerous) {
      showDangerousPopup()
    }
  }, [isDangerous])

  const getDeclarations = () => {
    CheckingAPI.getQuestion(location.entranceID)
      .then((data) => {
        setQuestion(data)
      })
      .catch((error) => {
        message.error(error.message)
      })

    setDeclarations(
      question.reduce((acc: any, curr) => {
        const currentAnswer =
          curr.answer_type === AnswerType.Boolean ? false : ''
        const isValid = curr.answer_type === AnswerType.Boolean ? true : false
        acc[curr.question_id] = {
          title: curr.title,
          answer: currentAnswer,
          isValid,
        }

        return acc
      }, {})
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  const getPurposes = () => {
    getPurposesAPI.list()
      .then((response: any) => {
        if (response && response.status) {
          const { reasons } = response;
          if (reasons != null) {
            setPurposes(reasons);
          }
        }
      })
      .catch((err) => {
        console.log(`request failed ${err.message}`)
      })
  }

  const getRooms = () => {
    getRoomsByEntranceAPI.list(location.entranceID)
      .then((response: any) => {
        if (response && response.status) {
          const { data } = response;
          if (data.length !== 0) {
            setRoomList(data.rooms);
          } else {
            setRoomList([]);
          }
        } else {
          setRoomList([]);
        }
      })
      .catch((err) => {
        console.log(`request failed ${err.message}`)
        setRoomList([]);
      })
  }

  const showDangerousPopup = (callback?: Function) => {
    Modal.error({
      icon: null,
      centered: true,
      content: (
        <Space align="center" direction={'vertical'}>
          <DangerousIcon
            width={60}
            height={60}
            style={{ marginRight: '15px' }}
          />
          <Text type="danger" style={{ fontSize: '15px' }}>
            Please seek assistance from our medical staff after checking-in.
          </Text>
        </Space>
      ),
      className: 'dangerous-popup',
      okText: 'Yes, I will',
      okButtonProps: {
        shape: 'round',
        className: 'btn-delete',
        style: {
          padding: '0 40px',
          margin: '0 auto',
        },
      },
      onOk: () => {
        if (callback) {
          callback()
        }
      },
    })
  }

  const handleChangeDeclares = (values: any) => {
    setDeclarations((preState: any) => ({ ...preState, ...values }))
  }

  const changeResidentInfo = (changed: object) => {
    if (initial) {
      setInitial(false)
    }
    if (changed["rooms"] !== (null || undefined)) {


      const changedRooms = roomList.filter((n) => {

        if (changed["rooms"].includes(n.roomName)) {
          return n
        }
      })
      setRoomsSelected(changedRooms)
    }
    setResidentInfo({ ...residentInfo, ...changed })
  }

  const [formHasError, setFormHasError] = useState(initFormErrors)
  const noError = useMemo(() => {
    // const {phone, ic, passport} = formError
    const newError = { ...formHasError }
    if (router.state?.type === RoutePaths.CHECK_IN) {
      const validDeclaration = Object.values(declarations).every(
        (item) => item.isValid === true
      )
      if (!validDeclaration) return false
    }

    if (!question?.find((e) => e.title.toLowerCase() === 'purpose of visit')) {
      delete newError.reason
    }

    // validate declaration
    const result = Object.values(newError).every((status) => status === false)
    return result
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formHasError, router.state, declarations])

  // validate
  useEffect(() => {
    setFormHasError((errors) => {
      let { phone, ic, name, reason, rooms } = errors
      phone = Validator.isPhoneNumber(residentInfo.phone) === false
      ic = Validator.isNRIC(residentInfo.ic) === false
      rooms = (roomList.length !== 0 && residentInfo.rooms.length === 0)
      if (router.state?.type === RoutePaths.CHECK_IN) {
        name = !residentInfo.name
        reason = !residentInfo.reason
      } else {
        name = false
        reason = false
      }
      return { ...errors, phone, ic, name, reason, rooms }
    })
  }, [residentInfo, router.state])

  const residentFormField: DeclarationFormField = [
    {
      name: 'name',
      placeholder: 'Full Name',
      label: 'Name',
      formItemType: FormItemType.textInput,
      isAutoAuthDisabled: router.state.authType === AuthType.sgIdAuth,
      value: residentInfo.name,
      validateStatus:
        formHasError.name && initial === false ? 'error' : undefined,
      help:
        formHasError.name && initial === false
          ? 'Name is incorrect'
          : undefined,
      isShow: true,
    },
    {
      name: 'ic',
      placeholder: 'NRIC/FIN',
      label: 'NRIC',
      formItemType: FormItemType.textInput,
      isAutoAuthDisabled: router.state.authType === AuthType.sgIdAuth,
      value: residentInfo.ic,
      validateStatus:
        formHasError.ic && initial === false ? 'error' : undefined,
      help:
        formHasError.ic && initial === false ? 'NRIC is incorrect' : undefined,
      isShow: true,
    },
    {
      name: 'phone',
      placeholder: 'Mobile number',
      label: 'Mobile number',
      formItemType: FormItemType.textInput,
      isAutoAuthDisabled: router.state.authType === AuthType.sgIdAuth,
      value: residentInfo.phone,
      validateStatus:
        formHasError.phone && initial === false ? 'error' : undefined,
      help:
        formHasError.phone && initial === false
          ? 'Mobile number is incorrect'
          : undefined,
      isShow: true,
    },
    {
      name: 'purpose',
      placeholder: 'Purpose of Visit',
      label: 'Purpose of Visit',
      formItemType: FormItemType.dropdownSelect,
      isAutoAuthDisabled: false,
      value: residentInfo.reason,
      validateStatus:
        formHasError.reason && initial === false ? 'error' : undefined,
      help:
        formHasError.reason && initial === false
          ? 'Reason is incorrect'
          : undefined,
      isShow: true,
    },
    {
      name: 'rooms',
      placeholder: 'Select Visiting Room(s)',
      label: 'Visiting Rooms',
      formItemType: FormItemType.dropdownMultiSelect,
      isAutoAuthDisabled: false,
      value: residentInfo.rooms,
      validateStatus:
        formHasError.rooms && initial === false ? 'error' : undefined,
      help:
        formHasError.rooms && initial === false
          ? 'Rooms selection is incorrect'
          : undefined,
      isShow: roomList.length !== 0,
    },
  ]

  // check-in, check-out action
  // go to complete page
  const goToComplete = async (type: string) => {
    try {
      // setLoading(true)
      await checklist.validateFields()
      const declarationForm = await checklist.getFieldsValue()

      if (!location) throw Error(MESSAGES.checkError(type))
      const questionRegex = /\[(\d+)\](.+$)/
      const declarationAnswers = Object.entries(declarationForm).map(
        (item, index) => {
          return {
            title: item[0].match(questionRegex)[2].trim(),
            answer: item[1],
          }
        }
      )

      let payload: CheckingInfo = {
        name: residentInfo.name,
        ic: residentInfo.ic,
        phone_number: residentInfo.phone,
        location_id: location.locationID,
        entrance_id: location.entranceID,
        reason: residentInfo.reason,
        type: type === RoutePaths.CHECK_IN ? 'check-in' : 'check-out',
        declarations: declarationAnswers,
      }

      if (type === RoutePaths.CHECK_IN) {
        payload = {
          ...payload,
        }
      }
      const checkingResult = await CheckingAPI.check(payload)
      if (checkingResult.data.entry_id !== (null || undefined)) {
        addRoomsToEntryLog(checkingResult.data.entry_id)
      }

      const isDangerous = !!Object.values(declarations).filter((e) => {
        return IsDangerousAnswer(e.answer, e.negative_conditions)
      }).length

      if (isDangerous) {
        showDangerousPopup(() =>
          router.push(RoutePaths.COMPLETE, {
            type,
            residentInfo,
            isDangerous,
            template: location.templateName,
            entryId: checkingResult.data.entry_id,
          })
        )
      } else {
        router.push(RoutePaths.COMPLETE, {
          type,
          residentInfo,
          template: location.templateName,
          entryId: checkingResult.data.entry_id,
        })
      }
    } catch (error) {
      if (error.message) {
        message.error(error.message)
      }
    } finally {
      // setLoading(false)
    }
  }

  const addRoomsToEntryLog = (entryLogId: string) => {
    addRoomsToEntryLogAPI.list(entryLogId, location.entranceID, roomsSelected)
      .then((response: any) => {
        if (response && response.status) {
          const { reasons } = response;
          if (reasons != null) {
            setPurposes(reasons);
          }
        }
      })
      .catch((err) => {
        console.log(`request failed ${err.message}`)
      })
  }

  const handelOutFocus = () => {
    outFocusRef.current.focus()
  }

  return (
    <div className="declaration-page container d-flex align-items-center flex-column">
      <CardDefault classNames="form-card" contentCentered>
        <Space
          className="building-name"
          align="center"
          direction="vertical"
          size={1}
        >
          <p>{location?.locationName}</p>
          <span className="d-inline-block text-center">
            {' '}
            {location?.entranceName}{' '}
          </span>
        </Space>
        <DeclarationForm
          onChange={changeResidentInfo}
          initialValues={residentInfo}
          fields={residentFormField}
          form={form}
          authType={router.state.authType}
          purposes={purposes}
          rooms={roomList.map(r => r.roomName)}
          extendsError={formHasError.reason && initial === false}
        />

        {(question.length !== 0) && (
          <>
            <Divider type="horizontal">Declaration</Divider>
            <DeclarationCheckList
              form={checklist}
              declarations={declarations}
              onChange={handleChangeDeclares}
              checklist={question?.filter(
                (e) => e.title.toLowerCase() !== 'purpose of visit'
              )}
              isDangerous={isDangerous}
              setDangerous={setDangerous}
              handelOutFocus={handelOutFocus}
            />
            <Policy />
          </>)}
      </CardDefault>
      <div className="bottom-panel">
        <div className="d-flex justify-content-center align-items-center z-10">
          <Button
            // loading={loading}
            style={{ borderRadius: 4 }}
            disabled={!noError}
            className="btn-check-in"
            onClick={() => goToComplete(RoutePaths.CHECK_IN)}
          >
            <span>Check-in</span>
            <CheckInIcon style={{ height: 'auto', width: 30 }} />
          </Button>
        </div>
        <div className="d-flex align-items-center z-9">
          <input ref={outFocusRef} />
        </div>
      </div>
    </div>
  )
}
