import { Question } from './Question'

const apiURL = process.env.REACT_APP_API_URL
const url = `${apiURL}/api/v1/templates`

export interface Template {
  id: string
  name: string
  questions: Question[]
  created_by?: string
  updated_by?: string
  created_at?: string
  updated_at?: string
}

export type PaginationQueryType = {
  items_per_page: number
  page: number
  search?: string
}

export const TemplateAPI = {
  async list(
    token: string,
    pagination?: PaginationQueryType
  ): Promise<{ items: Template[]; total: number }> {
    const params = pagination ?? {}
    const query = new URLSearchParams(params)

    const res = await fetch(`${url}?${query}`, {
      credentials: 'include',
      headers: {
        Authorization: token,
      },
    })
    const { status, data: items, message, total = 0 } = await res.json()
    if (status === false || !items?.length)
      throw Error(message || 'Data not found')
    return { items, total }
  },

  async create(
    token: string,
    payload: Pick<Template, 'name' | 'questions'>
  ): Promise<string> {
    const res = await fetch(`${url}`, {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
      method: 'post',
      body: JSON.stringify(payload),
    })
    const { status, message } = await res.json()
    if (status === false) throw Error(message || 'Create failed')
    return message
  },

  async update(
    token: string,
    id: string,
    payload: Pick<Template, 'name' | 'questions'>
  ): Promise<string> {
    const res = await fetch(`${url}/${id}`, {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
      method: 'put',
      body: JSON.stringify(payload),
    })
    const { status, message } = await res.json()
    if (status === false) throw Error(message || 'Update failed')
    return message
  },

  async delete(token: string, id: string): Promise<string> {
    const res = await fetch(`${url}/${id}?`, {
      credentials: 'include',
      method: 'delete',
      headers: {
        Authorization: token,
      },
    })
    const { status, message } = await res.json()
    if (status === false) throw Error(message || 'Delete failed')
    return message
  },
}
