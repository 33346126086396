import { message } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { CheckingAPI, CheckListQuestion } from 'api/Main'

export type Props = {
  children: React.ReactNode
}
export type State = {
  locationName: string
  locationID: string
  entranceID: string
  entranceName: string
}
export type Context = {
  data: State
  setLocation: (location: State) => void
  questions: CheckListQuestion[]
}

export type LocationParamsStorage = {
  locationName: string
  locationID: number
  entranceID: number
  entranceName: string
  templateName: string
}

export const LocationParamsContext = React.createContext<Context>(null!)

export const LocationParamsProvider = ({ children }: Props) => {
  const [data, setData] = useState<State>(null!)
  // const [data, setData] = useState<State>({
  //   locationName: '13 SWISS VIEW LA SUISSE',
  //   locationID: '34',
  //   entranceID: '29',
  //   entranceName: 'Entrance A',
  // })

  const [questions, setQuestions] = useState<CheckListQuestion[]>([])

  useEffect(() => {
    if (data) {
      CheckingAPI.getQuestion(data.entranceID)
        .then((data) => {
          setQuestions(data)
        })
        .catch((error) => {
          message.error(error.message)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setLocation = (location: State) => {
    setData({ ...data, ...location })
  }

  return (
    <LocationParamsContext.Provider value={{ data, setLocation, questions }}>
      {children}
    </LocationParamsContext.Provider>
  )
}

export const useLocationParams = () => {
  const context = useContext(LocationParamsContext)
  return context
}
