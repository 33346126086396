const baseURL = process.env.REACT_APP_API_URL;
const path = '/api/v2/ap/authorize';

export const authorizationUrl = {
  async get(): Promise<any> {
    const authUrl = new URL(path, baseURL);
    const res = await fetch(authUrl.href, {
      credentials: 'include',
    });
    const response = await res.json();
    if (response && response.status) {
      const { data } = response;
      return data;
    }
    return response;
  },
};
