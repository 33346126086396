import { Button, message, Space } from 'antd'
import React, { useState, useEffect } from 'react'
import moment from 'moment'
import classnames from 'classnames'
import { ReactComponent as CheckOutIcon } from 'assets/icons/checkout-icon.svg'
import { ReactComponent as UserIcon } from 'assets/icons/user.svg'
import { CardDefault } from 'components/cards/CardDefault'
import { useRouter } from 'hooks/useRouter'
import { RoutePaths } from 'hooks/useNavigation'
import { CheckingAPI } from 'api/Main'
import { MESSAGES } from 'config'
import { State as LocationState } from 'contexts/LocationParams'
import { LocationParamsStorage } from '../../../contexts/LocationParams'
import { AuthType } from '../DeclarationPage'

type Props = {
  location?: LocationState
}

enum ALLOW_SHOW_ENTRY_ID {
  ServerRoom = 'Server Room',
}

export const CompletePage = ({  }: Props) => {
// export const CompletePage = ({ location }: Props) => {
  const router = useRouter()
  const isCheckIn = router.state?.type === RoutePaths.CHECK_IN || !router.state?.type
  const typeToClassName = isCheckIn ? 'check-in' : 'check-out'
  // const [loading, setLoading] = useState(false)
  const [location, setLocation] = useState({} as LocationParamsStorage)

  useEffect(() => {
    const locationStored = JSON.parse(localStorage.getItem("locationParams") ?? "")
    const locationObj: LocationParamsStorage = {
      locationName: locationStored["locationName"],
      locationID: locationStored["locationID"],
      entranceID: locationStored["entranceID"],
      entranceName: locationStored["entranceName"],
      templateName: locationStored["templateName"],
    }
    setLocation(locationObj)
  }, [])

  // handle checkout
  const goToCheckOut = async () => {
    try {
      if (!location) throw Error('')
      const residentInfo = router.state?.residentInfo ? 
                                  router.state?.residentInfo : 
                                  JSON.parse(localStorage.getItem("residentInfo") ?? "{'status': false, 'message': 'failed to retrieve residentInfo'}")
      if (!residentInfo) throw Error(MESSAGES.residentInfoError)
      console.log({location, residentInfo})

      // setLoading(true)
      await CheckingAPI.check({
        ic: residentInfo.ic,
        name: residentInfo.name,
        phone_number: residentInfo.phone,
        location_id: location.locationID,
        entrance_id: location.entranceID,
        type: 'check-out',
      })
      // setLoading(false)
      router.push(RoutePaths.COMPLETE, {
        type: RoutePaths.CHECK_OUT,
        template: router.state?.template,
        entryId: router.state?.entryId,
      })
    } catch (error) {
      // setLoading(false)
      if (error.message) {
        message.error(error.message)
      }
      router.push(RoutePaths.INDEX)
    }
  }

  // checkin another visitor
  const goFormCheckPage = (authType: AuthType) => {
    router.push(RoutePaths.DECLARATION, { canGoBack: false, authType })
  }

  return (
    <div className="complete-page container d-flex align-items-center flex-column">
      <div
        className={classnames(
          'user-check d-flex align-items-center justify-content-center',
          typeToClassName
        )}
        style={
          (router.state?.isDangerous) ?? false
            ? {
                backgroundColor: '#ff4d4f',
              }
            : {}
        }
      >
        <UserIcon className="user-icon" />
      </div>
      <CardDefault classNames={classnames('result-card')} contentCentered>
        <div
          className={classnames(
            'result-card__top d-flex justify-content-center align-items-end',
            typeToClassName
          )}
          style={
            (router.state?.isDangerous) ?? false
              ? {
                  backgroundColor: '#ff4d4f',
                }
              : {}
          }
        >
          <span>{isCheckIn ? 'Check-in' : 'Check-out'} </span>
        </div>
        <Space
          className="check-time"
          direction="vertical"
          align="center"
          size={10}
        >
          <b> {moment().format('ll')} </b>
          <b> {moment().format('LT')} </b>
        </Space>
        <Space
          className="building-info"
          direction="vertical"
          align="center"
          size={1}
        >
          <p className="text-center">{location?.locationName}</p>
          <span className="d-inline-block text-center">
            {location?.entranceName}
          </span>
        </Space>
      </CardDefault>
      {isCheckIn && (
        <div><Button
          // loading={loading}
          onClick={goToCheckOut}
          style={{ borderRadius: 4 }}
          className="btn-check-out"
        >
          <span>Check-out</span>
          <CheckOutIcon style={{ height: 'auto', width: 30 }} />
        </Button>
        <Button type="link" style={{ borderColor: 'transparent', fontSize: 16, fontWeight: 700, textDecoration: 'underline' }} onClick={() => goFormCheckPage(AuthType.manualAuth)}  ><span>Check In Another Visitor</span></Button>
        </div>
      )}
      <div className="complete-page__bottom">
        {(router.state?.isDangerous) ?? false ? (
          <p style={{ fontSize: '26px', color: '#ff4d4f', fontWeight: 'bold' }}>
            Please seek assistance from our medical staff
          </p>
        ) : (
          <p>Thank you for keeping Singapore safe!</p>
        )}
        { (isCheckIn) ?
         <p>Screenshot this pass for record and don’t forget to check out when you
          leave.</p> : <p>To check in again,  
          <Button type="link" style={{ display:'inline', padding: 0, border: 0, width: 'auto', borderColor: 'transparent', textDecoration: 'underline' }} onClick={() => goFormCheckPage(AuthType.manualAuth)}>click here.</Button></p>
        }
        {Object.values(ALLOW_SHOW_ENTRY_ID).find(
          (e) =>
            e.toLowerCase().trim() ===
            router.state?.template?.toLowerCase().trim()
        ) && (
          <p style={{ fontSize: '18px' }}>
            Visitor Reference No.: {router.state.entryId}
          </p>
        )}
      </div>
    </div>
  )
}
